export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.dashboard', {
            url: '/dashboard',
            views: {
                'content@main': {
                    template: '<dashboard></dashboard>'
                }
            },
            authenticate: true,
            data: {
                title: 'Dashboard'
            }
        });
}

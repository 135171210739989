'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.events', {
            abstract: true,
            url: '/events',
            authenticate: true,
            data: {
                title: 'Events'
            }
        })
        .state('main.events.list', {
            url: '/',
            views: {
                'content@main': {
                    template: '<eventlist></eventlist>'
                }
            },
            authenticate: true,
            data: {
                title: 'List'
            }
        });
}

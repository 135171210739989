'use strict';
const angular = require('angular');

export class headerComponent {
  headerIcon;
  $window;
  Auth;
  currentAccount;
  setCurrentAccount;
  accounts;
  $rootScope;
  $state;
  accountDropdownOpen;


  /*@ngInject*/
  constructor($window, Auth, $rootScope, $location, $state, $http) {
      this.$window = $window;
      this.Auth = Auth;
      this.$rootScope = $rootScope;
      this.$location = $location;
      this.setCurrentAccount = Auth.setCurrentAccount;
      this.$state = $state;
      this.$http = $http;
  }

  $onInit() {
      let self = this;
      this.currentAccount = this.Auth.getCurrentAccountSync();
      this.accounts = this.Auth.getCurrentUserSync().accounts;
      if(!this.accounts || this.accounts.length == 0) {
     	this.Auth.getCurrentUser().then(user => {
              self.accounts = user.accounts;
              self.currentAccount = self.Auth.getCurrentAccountSync();
          });
      }
      this.setAccountIcon();

      this.$rootScope.$on('accountChanged', event => {
          self.currentAccount = self.Auth.getCurrentAccountSync();
          self.setAccountIcon();
      });
  }
  //TODO: Get this working && set a default icon if corresponding domain's icon is not found
  setAccountIcon() {
      this.headerIcon = `assets/images/accounticons/${this.currentAccount.name}.png`;
  }

  showSettings() {
      this.$state.go('main.settings.account');
  }

  stest(val) {
      if(this.$location.path() !== '/login') {
          this.setCurrentAccount(val.ref);
      }
  }
}

export default angular.module('directives.header', [])
    .component('smartheader', {
        template: require('./header.html'),
        controller: headerComponent,
        controllerAs: '$ctrl'
    })
    .name;

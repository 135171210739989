import tinyGradient from 'tinygradient';

export class DashboardModalComponent {
	Restangular;
	$uibModalInstance;
	settings;
	categories;
	appConfig;
	modalInstance;
	widget;
	toastr;

	/*@ngInject*/
	constructor(Restangular, Auth, appConfig, toastr) {
	    this.Auth = Auth;
	    this.currentUser = Auth.currentUser;
	    this.Restangular = Restangular;
	    this.availableWidgets = appConfig.availableWidgets;
	    this.chartType = '';
	    this.selectedWidget = {};
	    this.availableTypes = ['pie', 'bar'];
	    this.availableColourTypes = [{
	        type: 'range',
	        alias: 'Colour Range'
	    }, {
	        type: 'discrete',
	        alias: 'Discrete Colours'
	    }, {
	        type: 'match',
	        alias: 'Match Property'
	    }, {
	        type: 'default',
	        alias: 'Default Colours'
	    }];
	    this.Invalid = false;
	    this.availableWidgetsMetrics = [];
	    this.toastr = toastr;
	}

	$onInit() {
	    let self = this;
	    self.Auth.getCurrentUser().then(user => {
	        self.currentUser = user;
	        this.debouncedChangeColor = _.debounce(() => {
	            self.background1 = {
	                'background-color': self.color1
	            };
	            self.background2 = {
	                'background-color': self.color2
	            };
	            if(!self.color1) {
	                self.color1 = '#ffffff';
	            }
	            if(!self.color2) {
	                self.color2 = '#ffffff';
	            }
	            let grad = tinyGradient(self.color1, self.color2);
	            self.colourRanges = [];
	            self.colourRanges.push({
	                type: 'rgb',
	                colours: grad.rgb(10)
	            });
	            self.colourRanges.push({
	                type: 'hsv',
	                colours: grad.hsv(10)
	            });
	            self.colourRanges.push({
	                type: 'hsvi',
	                colours: grad.hsv(10, true)
	            });
	            self.colourRanges.forEach(range => {
	                let tempColours = [];
	                range.colours.forEach(colour => {
	                    tempColours.push({
	                        'background-color': `#${colour.toHex()}`
	                    });
	                });
	                range.colours = tempColours;
	            });
	        }, 500);
	        this.$uibModalInstance = this.modalInstance;
	        this.settings = this.resolve.settings;
	        if(this.settings.edit) {
	            this.categories = this.resolve.categories;
	            this.categoryColours = [];
	            this.propertyColours = [];
	            this.widget = this.resolve.widget;
	            this.name = this.widget.name;
	            this.selectedWidget = this.widget;
	            this.metric = this.widget.metric;
	            this.chartType = this.widget.type;
	            this.categories.values.forEach(value => {
	                if(self.widget.colours && self.widget.colours.type == 'discrete') {
	                    let tempColour = {
	                        colour: '#1f77b4'
	                    };
	                    if(self.categories.type == 'category') {
	                        let index = _.findIndex(self.widget.colours.colourValues, ['alias', value]);
	                        if(index > -1) {
	                            tempColour = self.widget.colours.colourValues[index];
	                        }
	                    } else if(self.categories.type == 'discrete') {
	                        let index = _.findIndex(self.widget.colours.colourValues, ['id', value]);
	                        if(index > -1) {
	                            tempColour = self.widget.colours.colourValues[index];
	                        }
	                    }
	                    self.categoryColours.push({
	                        name: value,
	                        colour: tempColour.colour,
	                        style: {
	                            'background-color': tempColour.colour
	                        }
	                    });
	                } else if(self.widget.colours && self.widget.colours.type == 'match') {
	                    self.propertyColours = self.widget.colours.colourValues;
	                } else {
	                    self.categoryColours.push({
	                        name: value,
	                        colour: '#1f77b4',
	                        style: {
	                            'background-color': '#1f77b4'
	                        }
	                    });
	                }
	            });
	            if(this.widget.colours && this.widget.colours.type == 'range') {
	                this.colourType = _.find(self.availableColourTypes, ['type', 'range']);
	                this.color1 = this.widget.colours.start || '#ffffff';
	                this.color2 = this.widget.colours.end || '#ffffff';

	                this.debouncedChangeColor();
	                this.gradient = this.widget.colours.gradient;
	            } else if(self.widget.colours && self.widget.colours.type == 'discrete') {
	                self.colourType = _.find(self.availableColourTypes, ['type', self.widget.colours.type]);
	            } else if(self.widget.colours && self.widget.colours.type == 'match') {
	                self.colourType = _.find(self.availableColourTypes, ['type', self.widget.colours.type]);
	            } else {
	                this.colourType = _.find(self.availableColourTypes, ['type', 'default']);
	                this.color1 = '#ffffff';
	                this.color2 = '#ffffff';
	            }
	        } else {
	            this.widget = {};
	            this.selectedWidget = {};
	        }
	        if(self.currentUser.role !== 'admin') {
	            _.remove(this.availableWidgets, e => e.chartId.includes('VMP'));
	        }
	        this.availableWidgetsChartId = _.uniqBy(this.availableWidgets, e => e.chartId);
	        let availableWidgetsMetrics = _.filter(this.availableWidgets, e => e.chartId == 'UnitsMetricsSum');
	        availableWidgetsMetrics.forEach(metric => {
	            if(metric.metric == 'Reboots' || metric.metric.includes('VMP')) {
	                if(self.currentUser.role == 'admin') {
	                    self.availableWidgetsMetrics.push({name: metric.metric, dataType: metric.dataType, sort: metric.sort || -1, timeDelta: metric.timeDelta});
	                }
	            } else {
	                self.availableWidgetsMetrics.push({name: metric.metric, dataType: metric.dataType, sort: metric.sort || -1, timeDelta: metric.timeDelta});
	            }
	        });
	    });
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	ok() {
	    let self = this;
	    this.finalWidget = this.selectedWidget;
	    this.Invalid = false;
	    if(this.name) {
	        this.finalWidget.name = this.name;
	        if(!this.metric && this.selectedWidget.chartId == 'UnitsMetricsSum') {
	            this.Invalid = true;
	        } else if(this.selectedWidget.chartId == 'UnitsMetricsSum') {
	            this.finalWidget.metric = this.metric.name;
	            this.finalWidget.dataType = this.metric.dataType;
	            this.finalWidget.sort = this.metric.sort || -1;
	            this.finalWidget.timeDelta = this.metric.timeDelta;
	        }
	    } else {
	        this.finalWidget.name = this.selectedWidget.chartId;
	        if(this.metric && this.selectedWidget.chartId == 'UnitsMetricsSum') {
	            this.finalWidget.name += ` of ${this.metric.name}`;
	            this.finalWidget.metric = this.metric.name;
	            this.finalWidget.dataType = this.metric.dataType;
	            this.finalWidget.sort = this.metric.sort;
	            this.finalWidget.timeDelta = this.metric.timeDelta;
	        } else if(this.selectedWidget.chartId == 'UnitsMetricsSum') {
	            this.Invalid = true;
	        }
	    }
	    if(this.chartType) {
	        this.finalWidget.type = this.chartType;
	    } else {
	        this.Invalid = true;
	    }

	    if(!this.Invalid) {
	        if(self.settings.create) {
	            this.finalWidget.col = 0;
	            this.finalWidget.row = 0;
	            this.finalWidget.sizeY = 2;
	            this.finalWidget.sizeX = 2;
	            this.$uibModalInstance.close(this.finalWidget);
	        } else {
	            if(this.colourType.type == 'range') {
	                this.finalWidget.colours = {
	                    type: 'range',
	                    start: self.color1,
	                    end: self.color2,
	                    gradient: self.gradient
	                };
	            } else if(this.colourType.type == 'discrete') {
	                this.finalWidget.colours = {
	                    type: 'discrete',
	                    colourValues: []
	                };
	                if(this.categories.type == 'category') {
	                    let count = 0;
	                    this.categoryColours.forEach(category => {
	                        self.finalWidget.colours.colourValues.push({id: count, alias: category.name, colour: category.colour});
	                        count++;
	                    });
	                } else if(this.categories.type == 'discrete') {
	                    this.categoryColours.forEach(category => {
	                        self.finalWidget.colours.colourValues.push({id: category.name, alias: category.name, colour: category.colour});
	                    });
	                }
	            } else if(this.colourType.type == 'match') {
	                this.finalWidget.colours = {
	                    type: 'match',
	                    colourValues: this.propertyColours
	                };
	            } else {
	                this.finalWidget.colours = undefined;
	            }
	            this.$uibModalInstance.close(this.finalWidget);
	        }
	    } else {
	        self.toastr.warning('The widget is not valid!', 'Invalid');
	    }
	}

	setType() {
	    this.chartType = this.selectedWidget.type;
	}

	colorChange() {
	    this.debouncedChangeColor();
	}
	setCategoryStyle(category) {
	    category.style = {
	        'background-color': category.colour
	    };
	}
}
export default angular.module('insideInfoApp.dashboard')
    .component('dashboardmodals', {
        template: require('./dashboard.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<',
            settings: '<'
        },
        controller: DashboardModalComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class AlertsClosedComponent {
  Restangular;
  socket;
  $stateParams;
  $scope;
  alerts = [];
  filterToOpened = false;
  filterFromOpened = false;
  baseAlerts;
  Auth

  /*@ngInject*/
  constructor(Restangular, socket, $stateParams, $scope, Auth) {
      this.Restangular = Restangular;
      this.socket = socket;
      this.$stateParams = $stateParams;
      this.$scope = $scope;
      this.Auth = Auth;
  }

  $onInit() {
      let self = this;
      this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref; //temporarily define an account
      this.baseAlerts = this.Restangular.all('alerts');
      this.baseAlerts.getList({inactive: true, accountID: this.$stateParams.accountID}).then(alerts => {
          self.alerts = alerts;
      });

      this.socket.joinRoom(`${this.$stateParams.accountID}:*:alerts`);

      this.$scope.$on('$destroy', function() {
          self.socket.leaveRoom(`${self.$stateParams.accountID}:*:alerts`);
      });

      this.socket.socket.on('alert:save', function(doc) {
          self.reloader();
      });

      this.filterToOpened = false;
      this.filterFromOpened = false;
  }

  reloader() {
      let self = this;
	  this.baseAlerts.getList({inactive: true, accountID: this.$stateParams.accountID}).then(function(alerts) {
          self.alerts = alerts;
      });
  }

  openTo($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.filterToOpened = true;
  }

  openFrom($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.filterFromOpened = true;
  }
}

export default angular.module('insideInfoApp.alerts')
    .component('alertsclosed', {
        template: require('./alerts.closed.html'),
        controller: AlertsClosedComponent,
        controllerAs: '$ctrl'
    })
    .name;

// @flow
//
import angular from "angular";

export class VerifyEmailComponent {
	$stateParams;
	User;
	token;
	uid;
	verified = false;
	verifying = false;
	sent = false;

	//constructor($stateParams, User) {
	/*@ngInject*/
	constructor($stateParams, $http) {
		this.$stateParams = $stateParams;
		this.$http = $http;
		this.verified = false;
		this.verifying = false;
		this.requested = false;

		this.token = this.$stateParams.token;
		this.uid = this.$stateParams.id;

		if (this.token && this.uid) {
			this.verifying = true;
			this.$http
				.get(`/api/users/${this.uid}/verify/${this.token}`)
				.then(() => {
					this.verified = true;
					this.verifying = false;
				})
				.catch((err) => console.error(err));
		} else if (this.uid) {
			this.request();
		}
	}

	request() {
		this.$http
			.get(`/api/users/${this.uid}/verify`)
			.then(() => {
				this.requested = true;
			})
			.catch((err) => console.error(err));
	}
}

export default angular.module("insideInfoApp.verify").component("verifyemail", {
	template: require("./verify.email.html"),
	controller: VerifyEmailComponent,
	controllerAs: "$ctrl",
}).name;

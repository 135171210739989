'use strict';

exports = module.exports = {
    // List of user roles
    userRoles: ['guest', 'user', 'accountManager', 'admin'],
    availableWidgets: [{
        chartId: 'UnitsOnline',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,

    }, {
        chartId: 'UnitsOnBattery',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,
    }, {
        chartId: 'UnitsOnVMP',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,
    }, {
        chartId: 'UnitsOnWAN',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,
    }, {
        chartId: 'UnitsOnSim',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,
    }, {
        chartId: 'UnitsOnLowBattery',
        limit: 10,
        metric: undefined,
        dataType: 'mV',
        type: 'bar',
        single: false,
    }, {
        chartId: 'UnitsRecentNoComms',
        limit: 10,
        metric: undefined,
        dataType: 'date',
        type: 'bar',
        single: false,
    }, {
        chartId: 'UnitsLongestNoComms',
        limit: 10,
        metric: undefined,
        dataType: 'date',
        type: 'bar',
        single: false,
    }, {
        chartId: 'EventsSummary',
        limit: 10,
        metric: undefined,
        dataType: 'count',
        type: 'bar',
        single: false,
        last: 'thisDay',
        stacked: true
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Reboots',
        dataType: 'count',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'SIM Swaps',
        dataType: 'count',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage In',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage Out',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 1',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 2',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 1 In',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 1 Out',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 2 In',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Delta Data Usage SIM 2 Out',
        dataType: 'bytes',
        type: 'bar',
        single: false,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Uptime',
        dataType: 'date',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'VMP Uptime',
        dataType: 'date',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'WAN Uptime',
        dataType: 'date',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Battery Uptime',
        dataType: 'date',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Mains Uptime',
        dataType: 'date',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisDay'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Total Reload',
        dataType: 'currency',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisMonth'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Total Airtime Reload',
        dataType: 'currency',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisMonth'
    }, {
        chartId: 'UnitsMetricsSum',
        limit: 10,
        metric: 'Total Data Reload',
        dataType: 'currency',
        type: 'bar',
        single: false,
        timeDelta: true,
        sort: 1,
        last: 'thisMonth'
    }, {
        chartId: 'UnitsFirmwareVersion',
        metric: undefined,
        dataType: 'count',
        type: 'pie',
        single: false,

    }],
    eventTypes: ['No-comms'],

    policyUpdated: {
        privacy: new Date('2021-06-28T00:00:00.000Z'),
        disclaimer: new Date('2021-06-28T00:00:00.000Z'),
        terms: new Date('2021-06-28T00:00:00.000Z'),
    }
};

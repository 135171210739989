// @flow

import angular from 'angular';

// type User = {
//   name: string;
//   email: string;
//   password: string;
// };

export class SignupComponent {
  user = {
      name: '',
      email: '',
      password: ''
  };
  errors = {};
  submitted = false;
  Auth;
  $state;

  /*@ngInject*/
  constructor(Auth, $state) {
      this.Auth = Auth;
      this.$state = $state;
  }

  register(form) {
      this.submitted = true;

    //   if(form.$valid) {
    //       return this.Auth.createUser({
    //           firstname: this.user.firstname,
    //           lastname: this.user.lastname,
    //           email: this.user.email,
    //           password: this.user.password
    //       })
    //           .then(() => {
    //               // Account created, redirect to home
    //               this.$state.go('main');
    //           })
    //           .catch(err => {
    //               err = err.data;
    //               this.errors = {};
    //               // Update validity of form fields that match the mongoose errors
    //               angular.forEach(err.errors, (error, field) => {
    //                   form[field].$setValidity('mongoose', false);
    //                   this.errors[field] = error.message;
    //               });
    //           });
    //   }
  }
}

export default angular.module('insideInfoApp.signup')
    .component('signup', {
        template: require('./signup.html'),
        controller: SignupComponent,
        controllerAs: '$ctrl'
    })
    .name;

'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.units', {
            abstract: true,
            url: '/units',
            authenticate: true,
            data: {
                title: 'Units'
            }
        })
        .state('main.units.list', {
            url: '/',
            views: {
                'content@main': {
                    template: '<unitlist></unitlist>'
                }
            },
            authenticate: true,
            data: {
                title: 'List'
            }
        })
        .state('main.units.dataManagement', {
            url: '/airtime',
            views: {
                'content@main': {
                    template: '<datamanagement></datamanagement>'
                }
            },
            authenticate: true,
            data: {
                title: 'Manage Airtime'
            }
        })

        .state('main.units.detail', {
            url: '/:unitID',
            views: {
                'content@main': {
                    template: '<unitdetail></unitdetail>'
                }
            },
            authenticate: true,
            abstract: true,
            data: {
                title: 'Detail'
            }
        })

        .state('main.units.detail.charts', {
            url: '?edate&sdate&gran',
            views: {
                tabView: {
                    template: '<unitdetailcharts></unitdetailcharts>'
                    //  selectedResolution="$ctrl.selectedResolution" selectedRange="$ctrl.selectedRange" customRange="$ctrl.customRange" selectedCharts ="$ctrl.selectedCharts"
                }
            },
            params: {
                sdate: null,
                edate: null,
                gran: null,
            },
            authenticate: true,
        })

        .state('main.units.detail.heartbeats', {
            url: '/heartbeats',
            views: {
                tabView: {
                    template: '<unitheartbeats></unitheartbeats>'
                }
            },
            params: {
                from: {
                    type: 'date',
                    dynamic: true,
                    value: null
                },
                to: {
                    type: 'date',
                    dynamic: true,
                    value: null
                }
            },
            authenticate: true
        })
        .state('main.units.detail.metricsum', {
            url: '/metricsum',
            views: {
                tabView: {
                    template: '<metricsum></metricsum>'
                }
            },
            params: {
                from: {
                    type: 'date',
                    dynamic: true,
                    value: null
                },
                to: {
                    type: 'date',
                    dynamic: true,
                    value: null
                },
                metricName: {
                    type: 'string',
                    dynamic: true,
                    value: null
                }

            },
            authenticate: true
        })

        .state('main.units.detail.clients', {
            url: '/clients',
            views: {
                tabView: {
                    template: '<unitclients></unitclients>'
                }
            },
            authenticate: true
        })

        .state('main.units.detail.manage', {
            url: '/manage',
            views: {
                tabView: {
                    template: '<unitmanage></unitmanage>'
                }
            },
            authenticate: true
        })
    ;
}

export class ReportsScheduledComponent {
	scheduledReports = [];


	/*@ngInject*/
	constructor() {
	}

	$onInit() {

	}


	openCreateModal() {

	}
}

export default angular.module('insideInfoApp.reports')
    .component('reportsscheduled', {
        template: require('./reports.scheduled.html'),
        controller: ReportsScheduledComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class ReportsAdhocComponent {
	Restangular;
	moment;
	Auth;
	$window;
	toastr;
	$sce;

	reports = [
	    {
	        name: 'No Communication Summary',
	        description: 'Gives a summary of no communication events for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Uptime Summary',
	        description:
				'Gives a summary of uptime percentage, uptime duration and the downtime duration for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'WAN Uptime Summary',
	        description:
				'Gives a summary of WAN uptime percentage, uptime duration and the downtime duration for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Data Usage Summary',
	        description: 'Gives a summary of data for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'SIM Swap Summary',
	        description: 'Gives a summary of SIM swaps for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Power Failure Summary',
	        description: 'Gives a summary of power failures for units',
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Unit Status Summary',
	        description: 'Gives a summary of the current status for units',
	        hideDate: true,
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Account Device List',
	        description: 'Gives details on units',
	        hideDate: true,
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	    {
	        name: 'Unit Heartbeat Summary',
	        description: 'Gives heartbeats of spesific unit',
	        selectUnit: true,
	        targetFilters: [],
	        multipleFilterOperator: 'and',
	    },
	];

	properties = ['Name', 'Type'];
	comparisonTypes = [
	    'is',
	    'is not',
	    'contains',
	    'does not contain',
	    'starts with',
	    'ends with',
	    'matches',
	];

	quickReportOptions = [
	    'Last 24 Hours',
	    'Last 7 Days',
	    'Last 31 Days',
	    'Today',
	    'This Week',
	    'This Month',
	];

	/*@ngInject*/
	constructor(
	    moment,
	    Restangular,
	    Auth,
	    $window,
	    $sce,
	    toastr,
	    taskService,
	    $scope,
	    $http
	) {
	    this.Restangular = Restangular;
	    this.moment = moment;
	    this.Auth = Auth;
	    this.$window = $window;
	    this.$sce = $sce;
	    this.toastr = toastr;
	    this.taskService = taskService;
	    this.$scope = $scope;
	    this.$http = $http;
	}

	$onInit() {
	    let self = this;
	    self.forms = {};
	    this.Units = this.Restangular.all('units');
	    this.refreshUnits();
	    self.taskService
	        .registerListener(self, (event, item, array) => {
	            if(event === 'deleted') {
	                self.onRemoveTask(item);
	            } else {
	                self.onReceiptOfTask(item);
	            }
	        })
	        .then(tasks => {
	            self.tasks = tasks;
	            if(self.$stateParams && self.$stateParams.task) {
	                let task = _.find(tasks, ['_id', self.$stateParams.task]);
	                if(task) {
	                    self.generateReportAccordion = true;
	                    if(!self.openedTask) {
	                        self.openedTask = {};
	                    }
	                    self.openedTask[task._id] = true;
	                }
	            }
	        });

	    self.filterProperties = [
	        {
	            name: 'Name',
	            id: 'name',
	        },
	        {
	            name: 'Groups',
	            id: 'groups',
	        },
	    ];
	    self.comparisonTypes = [
	        'is',
	        'is not',
	        'contains',
	        //'does not contain',
	        'starts with',
	        'ends with',
	        'matches',
	    ];
	    self.availableOperators = ['and', 'or'];
	}

	openDateTimePicker(index, picker, $event) {
	    $event.preventDefault();
	    $event.stopPropagation();
	    this.reports[index][picker] = !this.reports[index][picker];
	}

	quickPick(index, option) {
	    let self = this;
	    let to = this.moment();
	    let from = this.moment();
	    self.reports[index].filterTo = to.toDate();
	    switch (option) {
	    case 'Last 24 Hours':
	        self.reports[index].filterFrom = from
	            .subtract(24, 'hours')
	            .toDate();
	        break;
	    case 'Last 7 Days':
	        self.reports[index].filterFrom = from
	            .subtract(7, 'days')
	            .toDate();

	        break;
	    case 'Last 31 Days':
	        self.reports[index].filterFrom = from
	            .subtract(31, 'days')
	            .toDate();

	        break;
	    case 'Today':
	        self.reports[index].filterTo = to.endOf('day').toDate();
	        self.reports[index].filterFrom = from.startOf('day').toDate();
	        break;
	    case 'This Week':
	        self.reports[index].filterTo = to.endOf('week').toDate();
	        self.reports[index].filterFrom = from.startOf('week').toDate();
	        break;
	    case 'This Month':
	        self.reports[index].filterTo = to.endOf('month').toDate();
	        self.reports[index].filterFrom = from.startOf('month').toDate();

	        break;
	    default:
	    }
	}

	onConsiderChecked(index) {
	    let self = this;
	    if(
	        self.reports[index].considerOnly
			&& self.reports[index].targetFilters.length == 0
	    ) {
	        self.onFilterAdded(index);
	    }
	}

	onFilterAdded(index) {
	    this.reports[index].targetFilters.push({
	        property: this.filterProperties[0].id,
	        comparison: this.comparisonTypes[0],
	    });
	}

	onFilterRemoved(reportIx, index) {
	    let self = this;
	    this.reports[reportIx].targetFilters.splice(index, 1);
	    if(self.reports[reportIx].targetFilters.length == 0) {
	        self.reports[reportIx].considerOnly = false;
	    }
	}

	generateReport(index, format) {
	    let self = this;
	    let accountId = self.Auth.getCurrentAccountSync().ref;
	    let tempReport = _.cloneDeep(self.reports[index]);
	    tempReport = _.pick(tempReport, [
	        'filterFrom',
	        'filterTo',
	        'name',
	        'targetFilters',
	        'considerOnly',
	        'multipleFilterOperator',
	    ]);
	    if(!tempReport.considerOnly) delete tempReport.targetFilters;
	    if(tempReport.name == 'Unit Heartbeat Summary') {
	        if(self.selectedUnit) {
	            tempReport.unitId = self.selectedUnit._id;
	        } else {
	            self.toastr.warning(
	                'Please select a unit!',
	                'No Unit Selected'
	            );
	            return;
	        }
	    }
	    tempReport.format = format || 'pdf';
	    //delete tempReport.$$hashKey;
	    //delete tempReport.description;
	    //delete tempReport.accordionOpen;
	    tempReport.filterTo = self.moment(tempReport.filterTo).utc()
	        .valueOf();
	    tempReport.filterFrom = self
	        .moment(tempReport.filterFrom)
	        .utc()
	        .valueOf();
	    this.Restangular.all('reports')
	        .customPOST(tempReport, self.Auth.getCurrentAccountSync().ref)
	        .then(result => {
	            if(!self.openedTask) {
	                self.openedTask = {};
	            }
	            self.generateReportAccordion = true;
	            self.openedTask[result._id] = true;
	        })
	        .catch(err => {
	            console.error(err);
	            self.toastr.error('Report failed.');
	        });
	}

	refreshUnits(query) {
	    let self = this;
	    self.Units.getList({
	        filter: query ? query : undefined,
	        limit: 20,
	    })
	        .then(function(units) {
	            self.availableUnits = units;
	        })
	        .catch(err => {
	            console.error(
	                'Error caught when getting data for units: ',
	                err.data.err
	            );
	        });
	}

	onRemoveTask(task) {
	    let self = this;
	    let index = _.findIndex(self.tasks, ['_id', task._id]);
	    if(index >= 0) {
	        self.tasks.splice(index, 1);
	    }
	}

	onReceiptOfTask(task) {
	    let self = this;
	    let index = _.findIndex(self.tasks, ['_id', task._id]);
	    if(index >= 0) {
	        self.tasks.splice(index, 1, task);
	    } else {
	        self.tasks.push(task);
	    }
	}

	downloadReport(task) {
	    let self = this;
	    task.processes.push({ type: 'Download', progress: 0 });
	    let deregisterListener = self.$scope.$on(
	        'xhrProgress',
	        function(event, url, loadedPct) {
	            let taskProcess = _.find(task.processes, ['type', 'Download']);
	            if(taskProcess) {
	                taskProcess.progress = Math.round(loadedPct);
	            }
	        }
	    );
	    let options = {
	        method: 'GET',
	        url: '/api/reports/gets3Report',
	        params: {
	            url: task.taskResults.reportKey,
	            format: task.taskOptions.query.format,
	        },
	        headers: {
	            'Content-Type': 'application/pdf',
	        },
	        responseType: 'arraybuffer',
	    };
	    if(task.taskOptions.query.format === 'xlsx') {
	        options.headers['Content-Type']
				= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	    } else if(task.taskOptions.query.format === 'video') {
	        options.headers['Content-Type'] = 'video/x-msvideo';
	    }

	    self.$http(options)
	        .then(result => {
	            deregisterListener();
	            let taskProcessIndex = _.findIndex(task.processes, [
	                'type',
	                'Download',
	            ]);
	            if(taskProcessIndex >= 0) {
	                task.processes.splice(taskProcessIndex, 1);
	            }
	            let file = new Blob([result.data], {
	                type: options.headers['Content-Type'],
	            });

	            if(['xlsx', 'video'].includes(task.taskOptions.query.format)) {
	                var downloadLink = angular.element('<a></a>');
	                downloadLink.attr('href', URL.createObjectURL(file));
	                downloadLink.attr('target', '_self');
	                let fileName = `${task.alias}.xlsx`;
	                if(task.taskOptions.query.format === 'video') {
	                    fileName = `${task.alias}.mp4`;
	                }
	                downloadLink.attr('download', fileName);
	                downloadLink[0].click();
	            } else {
	                let fileURL = URL.createObjectURL(file);
	                let pdfFile = self.$sce.trustAsResourceUrl(fileURL);
	                self.$window.open(fileURL, '_blank');
	            }
	        })
	        .catch(err => {
	            console.error(err);
	        });
	    // let qeury = this.Restangular.one('reports');
	    // qeury.withHttpConfig({
	    // 	responseType: 'blob'
	    // });
	    // qeury.customGET('gets3Report',{url:task.taskResults.reportKey}).then((result) => {
	    // 	let file = new Blob([result], {
	    // 		type: 'application/pdf'
	    // 	});
	    // 	let fileURL = URL.createObjectURL(file);
	    // 	let pdfFile = self.$sce.trustAsResourceUrl(fileURL);
	    // 	self.$window.open(pdfFile, '_blank');
	    // 	// self.usSpinnerService.stop('spinner-1')
	    // }).catch((err) => {
	    // 	console.error(err);
	    // });
	}

	deleteTask(task) {
	    let self = this;
	    self.taskService.removeTask(task).then(result => {
	        self.toastr.info('Report task removed.');
	    });
	}

	doLog() {
	    console.debug(this);
	}
}

export default angular
    .module('insideInfoApp.reports')
    .component('reportsadhoc', {
        template: require('./reports.adhoc.html'),
        controller: ReportsAdhocComponent,
        controllerAs: '$ctrl',
    }).name;

'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import Restangular from 'restangular';
import routes from './dashboard.routes';


export default angular.module('insideInfoApp.dashboard', [Restangular])
    .config(routes)
    .name;

require('./dashboard.component');
require('./dashboard.modal.component');
require('./factories/task.service.js');

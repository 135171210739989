export class UnitsHeartbeatComponent {
    $stateParams;
    heartbeats;

    // refreshClients : Function;
    // onClientChanged : Function;


    /*@ngInject*/
    constructor($stateParams, Restangular, moment, $scope, $timeout) {
        this.Restangular = Restangular;
        this.$stateParams = $stateParams;
        this.moment = moment;
        this.$scope = $scope;
        this.$timeout = $timeout;
    }

    $onInit() {
        let self = this;
        // if(this.$stateParams.heartbeats !== null) {
        //     this.heartbeats = this.$stateParams.heartbeats;
        // }
        self.rangeOptions = ['Last 24 Hours', 'Last 7 Days', 'Last 31 Days', 'Today', 'This Week', 'This Month'];
        self.availableSizeOptions = [
            {
                value: 10
            }, {
                value: 20
            }, {
                value: 50
            }
        ];
        self.selectedPageSize = self.availableSizeOptions[1];
        if(self.$stateParams) {
            if(self.$stateParams.from) {
                self.from = self.$stateParams.from;
            } else {
                self.from = self.moment().subtract(1, 'day')
                    .toDate();
            }
            if(self.$stateParams.to) {
                self.to = self.$stateParams.to;
            } else {
                self.to = self.moment().toDate();
            }
        }
        self.page = 1;
        self.pageSize = 20;
        self.total = 0;
        self.reloadHeartbeats();
        self.debounceReload = _.debounce(self.reloadHeartbeats, 250);
        self.$scope.$watchGroup(['$ctrl.from', '$ctrl.to', '$ctrl.selectedPageSize'], () => {
            self.debounceReload();
        });
    }


    reloadHeartbeats(foo, page) {
        let self = this;
        self.$timeout(function() {
            if(!page) {
                page = self.page;
            }
            self.Restangular.all('heartbeats').getList({
                limit: self.selectedPageSize.value,
                skip: (page - 1) * self.selectedPageSize.value,
                from: self.moment(self.from).valueOf(),
                to: self.moment(self.to).valueOf(),
                unitID: self.$stateParams.unitID
            })
                .then(function(heartbeats) {
                    self.heartbeats = heartbeats;
                    self.total = heartbeats.total;
                });
        }, 0);
    }

    openDateTimePicker(index, picker, $event) {
        let self = this;
        $event.preventDefault();
        $event.stopPropagation();
        self[picker] = !self[picker];
    }
    quickPick(index, option) {
        let self = this;
        let to = this.moment();
        let from = this.moment();
        self.to = to.toDate();
        switch (option) {
        case 'Last 24 Hours':
            self.from = from.subtract(24, 'hours').toDate();
            break;
        case 'Last 7 Days':
            self.from = from.subtract(7, 'days').toDate();

            break;
        case 'Last 31 Days':
            self.from = from.subtract(31, 'days').toDate();

            break;
        case 'Today':
            self.to = to.endOf('day').toDate();
            self.from = from.startOf('day').toDate();
            break;
        case 'This Week':
            self.to = to.endOf('week').toDate();
            self.from = from.startOf('week').toDate();
            break;
        case 'This Month':
            self.to = to.endOf('month').toDate();
            self.from = from.startOf('month').toDate();

            break;
        default:
        }
    }
    setSizeOption(option) {
        let self = this;
        self.selectedPageSize = option;
    }

    formatHeartbeat(hb) {
        return _.omit(hb.plain(), 'accordionOpen');
    }
}


export default angular.module('insideInfoApp.units')
    .component('unitheartbeats', {
        template: require('./units.detail.heartbeats.html'),
        controller: UnitsHeartbeatComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class UnitsDetailChartsComponent {
	$stateParams;
	resolutions = [];
	selectedResolution = {};
	selectedCharts;
	selectedRange;
	customRange;
	ranges = [];
	fromOpened;
	toOpened;
	moment;

	/*@ngInject*/
	constructor($stateParams, $scope, moment, Restangular, $localStorage, $timeout, $state, Auth) {
	    this.moment = moment;
	    this.$stateParams = $stateParams;
	    this.$scope = $scope;
	    this.Restangular = Restangular;
	    this.$localStorage = $localStorage;
	    this.$timeout = $timeout;
	    this.$state = $state;
	    this.Auth = Auth;
	}

	$onInit() {
	    let self = this;
	    self.Auth.getCurrentUser().then(user => {
	        self.currentUser = user;


	        self.dataPointsLimit = 1440;
	        this.resolutions = [{
	            name: '1 Minute',
	            ranges: ['1 Hour', '6 Hours', '12 Hours', '24 Hours', '48 Hours', 'Custom']
	        },
	        {
	            name: '5 Minutes',
	            ranges: ['6 Hours', '12 Hours', '24 Hours', '48 Hours', 'Custom']
	        },
	        {
	            name: '1 Hour',
	            ranges: ['1 Day', '1 Week', '2 Weeks', '1 Month', '2 Months', 'Custom']
	        },
	        {
	            name: '1 Day',
	            ranges: ['1 Week', '2 Weeks', '1 Month', '6 Months', '1 Year', 'Custom']
	        },
	        ];

	        this.charts = [
	            { name: 'Uptime' },
	            { name: 'WAN Uptime' },
	            { name: 'Battery Uptime' },
	            { name: 'Mains Uptime' },
	            { name: 'SIM Swaps' },
	            { name: 'Mains' },
	            { name: 'Battery' },
	            { name: 'RSSI' },
	            { name: 'BER' },
	            { name: 'Delta Data Usage' },
	            { name: 'Delta Data Usage In' },
	            { name: 'Delta Data Usage Out' },
	            { name: 'Delta Data Usage SIM 1' },
	            { name: 'Delta Data Usage SIM 1 In' },
	            { name: 'Delta Data Usage SIM 1 Out' },
	            { name: 'Delta Data Usage SIM 2' },
	            { name: 'Delta Data Usage SIM 2 In' },
	            { name: 'Delta Data Usage SIM 2 Out' },
	            { name: 'Application Link Quality Sim 1' },
	            { name: 'Application Link Quality Sim 2' },
	            { name: 'Management Link Quality Sim 1' },
	            { name: 'Management Link Quality Sim 2' },

	            { name: 'Available Data SIM 1' },
	            { name: 'Available Data SIM 2' },
	            { name: 'Available Airtime SIM 1' },
	            { name: 'Available Airtime SIM 2' },
	            { name: 'Delta Network Data Usage SIM 1' },
	            { name: 'Delta Network Data Usage SIM 2' },
	            { name: 'Delta Network Airtime Usage SIM 1' },
	            { name: 'Delta Network Airtime Usage SIM 2' },
	            { name: 'Airtime Reload SIM 1' },
	            { name: 'Airtime Reload SIM 2' },
	            { name: 'Data Reload SIM 1' },
	            { name: 'Data Reload SIM 2' },
	        ];

	        self.accountCharts = false;
	        if(this.$stateParams.unitID == this.$stateParams.accountID) {
	            self.accountCharts = true;
	            this.charts = [
	                { name: 'SIM Swaps' },
	                { name: 'Delta Data Usage' },
	                { name: 'Delta Data Usage In' },
	                { name: 'Delta Data Usage Out' },
	                { name: 'Online Units' },
	                //{name: 'Units VMP'},
	                { name: 'Units NTP' },
	                { name: 'Units Mains' },
	                { name: 'Units Battery' },
	                { name: 'Units WAN' },
	                { name: 'Average SIM Swaps' },
	                { name: 'Average Delta Data Usage' },
	            ];
	        } else {
	            self.Restangular.one('units', self.$stateParams.unitID).get()
	                .then(unit => {
	                    self.unit = unit;
	                    self.charts.forEach(chart => {
	                        if(chart.name.match(/SIM( *)1/i) && unit.status.networkNameSIM1) {
	                            chart.title = chart.name.replace(/SIM( *)1/i, unit.status.networkNameSIM1);
	                        } else if(chart.name.match(/SIM( *)2/i) && unit.status.networkNameSIM2) {
	                            chart.title = chart.name.replace(/SIM( *)2/i, unit.status.networkNameSIM2);
	                        }
	                    });
	                });
	        }
	        if(self.currentUser.role == 'admin') {
	            self.charts.push({ name: 'Reboots' });

	            if(this.$stateParams.unitID == this.$stateParams.accountID) {
	                self.charts.push({ name: 'Average Reboots' });
	                self.charts.push({ name: 'Units VMP' });
	            } else {
	                self.charts.push({ name: 'VMP Uptime' });
	            }
	        }


	        if(self.$localStorage.charts && self.$stateParams.unitID != self.$stateParams.accountID) {
	            if(self.currentUser.role !== 'admin') {
	                _.remove(self.$localStorage.charts, { name: 'Reboots' });
	                _.remove(self.$localStorage.charts, { name: 'VMP Uptime' });
	                _.remove(self.$localStorage.charts, { name: 'Average Reboots' });
	                _.remove(self.$localStorage.charts, { name: 'Units VMP' });
	            }
	            self.selectedCharts = self.$localStorage.charts;
	        } else {
	            self.selectedCharts = self.charts;
	        }
	        if(self.$stateParams.edate || self.$stateParams.sdate || self.$stateParams.gran) {
	            if(self.$stateParams.gran) {
	                self.selectedResolution = self.resolutions[self.$stateParams.gran - 1] || self.resolutions[3];
	            } else {
	                self.selectedResolution = self.resolutions[1];
	            }
	            let sdate;
	            if(/^\d+$/.test(self.$stateParams.sdate)) {
	                sdate = self.moment(+self.$stateParams.sdate);
	            } else {
	                sdate = self.moment(self.$stateParams.sdate);
	            }
	            let edate;
	            if(/^\d+$/.test(self.$stateParams.edate)) {
	                edate = self.moment(+self.$stateParams.edate);
	            } else {
	                edate = self.moment(self.$stateParams.edate);
	            }
	            if(sdate.isValid() || edate.isValid()) {
	                self.ranges = self.selectedResolution.ranges;
	                self.selectedRange = 'Custom';
	                self.customRange = { start: 0, end: new self.moment().valueOf() };
	                if(edate.isValid() && sdate.isValid()) {
	                    self.customRange = { start: sdate.valueOf(), end: edate.valueOf() };
	                } else if(edate.isValid()) {
	                    self.customRange = { start: self.getMaxStartDate(edate), end: edate.valueOf() };
	                } else {
	                    self.customRange = { start: sdate.valueOf(), end: self.getMaxEndDate(sdate) };
	                }
	            } else {
	                self.ranges = self.selectedResolution.ranges;
	                self.selectedRange = self.ranges[0];
	                self.customRange = { start: 0, end: new self.moment().valueOf() };
	            }
	        } else {
	            self.selectedResolution = self.resolutions[3];
	            self.ranges = self.selectedResolution.ranges;
	            self.selectedRange = self.ranges[0];
	            self.customRange = { start: 0, end: new self.moment().valueOf() };
	        }

	        self.fromOpened = false;
	        self.toOpened = false;
	        self.regions = self.getRegions();

	        //self.$scope.$watchGroup(['$ctrl.selectedRange', '$ctrl.selectedResolution', '$ctrl.customRange'], (newVal, oldVal) => {
	        //self.regions = self.getRegions();
	        //}, true);
	        self.resizeChartName = '';

	        self.gridsterOpts = {
	            columns: 6, // the width of the grid, in columns
	            pushing: true, // whether to push other items out of the way on move or resize
	            floating: true, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
	            swapping: true, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
	            width: 'auto', // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
	            colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
	            rowHeight: 'match', // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
	            margins: [10, 10], // the pixel distance between each widget
	            outerMargin: true, // whether margins apply to outer edges of the grid
	            sparse: false, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
	            isMobile: false, // stacks the grid items if true
	            mobileBreakPoint: 600, // if the screen is not wider that this, remove the grid layout and stack the items
	            mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
	            minColumns: 1, // the minimum columns the grid must have
	            minRows: 2, // the minimum height of the grid, in rows
	            maxRows: 100,
	            defaultSizeX: 3, // the default width of a gridster item, if not specifed
	            defaultSizeY: 2, // the default height of a gridster item, if not specified
	            minSizeX: 1, // minimum column width of an item
	            maxSizeX: null, // maximum column width of an item
	            minSizeY: 1, // minumum row height of an item
	            maxSizeY: null, // maximum row height of an item
	            resizable: {
	                enabled: true,
	                handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
	                start(event, $element, widget) { }, // optional callback fired when resize is started,
	                resize(event, $element, widget) {
	                    // self.resizeChartName = 'None';
	                    // self.$timeout(function () {
	                    // 	self.resizeChartName = widget.name;
	                    // }, 0);
	                }, // optional callback fired when item is resized,
	                stop(event, $element, widget) {
	                    self.resizeChartName = 'None';
	                    setTimeout(() => {
	                        self.resizeChartName = widget.name;
	                    }, 400);
	                    // if (widget.hasData) {
	                    // }
	                } // optional callback fired when item is finished resizing
	            },
	            draggable: {
	                enabled: true, // whether dragging items is supported
	                handle: 'h3', // optional selector for drag handle
	                start(event, $element, widget) { }, // optional callback fired when drag is started,
	                drag(event, $element, widget) { }, // optional callback fired when item is moved,
	                stop(event, $element, widget) { } // optional callback fired when item is finished dragging
	            }
	        };
	        self.$timeout(() => {
	            self.$scope.$broadcast('refreshCharts');
	        }, 0);
	    });
	}

	$onDestroy() {
	    let self = this;
	    if(!self.accountCharts) {
	        self.$localStorage.charts = self.selectedCharts;
	    }
	}

	getRegions() {
	    let self = this;
	    self.regionsLoading = true;
	    self.$scope.$evalAsync();
	    if(self.$stateParams.unitID == 'None') {
	        return null;
	    }
	    let allPromisses = [];
	    let start;
	    let end = self.moment();
	    if(self.selectedRange == 'Custom') {
	        end = self.customRange.end;
	        start = self.customRange.start;
	    } else {
	        let range = self.selectedRange.split(' ');
	        start = self.moment().subtract(+range[0], range[1].toLowerCase());
	    }
	    let sim1Uptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID)
	        .customGET('SIM 1 Uptime', { start: start.valueOf(), end: end.valueOf() })
	        .then(results => {
	            if(results.length > 0) {
	                let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	                // let invertedActivities = self.invertActivityTime(activityTimes,start,end,'SIM 2 Uptime');
	                return activityTimes;
	            } else {
	                return [];
	            }
	        });
	    allPromisses.push(sim1Uptime);
	    let sim2Uptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID)
	        .customGET('SIM 2 Uptime', { start: start.valueOf(), end: end.valueOf() })
	        .then(results => {
	            if(results.length > 0) {
	                let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	                // let invertedActivities = self.invertActivityTime(activityTimes,start,end,'SIM 2 Uptime');
	                return activityTimes;
	            } else {
	                return [];
	            }
	        });
	    allPromisses.push(sim2Uptime);
	    // self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID).customGET('SIM 2 Uptime', {start:start.valueOf(),end:end.valueOf()}).then((results) => {
	    // });
	    let uptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID)
	        .customGET('Uptime', { start: start.valueOf(), end: end.valueOf() })
	        .then(results => {
	            if(results.length > 0) {
	                let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	                let invertedActivities = self.invertActivityTime(activityTimes, start, end, 'Downtime');
	                return activityTimes.concat(invertedActivities);
	            } else {
	                return [];
	            }
	        });
	    allPromisses.push(uptime);
	    let MainsUptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID)
	        .customGET('Mains Uptime', { start: start.valueOf(), end: end.valueOf() })
	        .then(results => {
	            if(results.length > 0) {
	                let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	                return activityTimes;
	            } else {
	                return [];
	            }
	        });
	    allPromisses.push(MainsUptime);
	    let BatteryUptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID)
	        .customGET('Battery Uptime', { start: start.valueOf(), end: end.valueOf() })
	        .then(results => {
	            if(results.length > 0) {
	                let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	                return activityTimes;
	            } else {
	                return [];
	            }
	        });
	    allPromisses.push(BatteryUptime);
	    // let uptime = self.Restangular.one('activities', self.$stateParams.accountID).all(self.$stateParams.unitID).customGET('Uptime', {start:start.valueOf(),end:end.valueOf()}).then((results) => {
	    // 	if(results.length > 0){
	    // 		let activityTimes = self.calculateActivityTimes(_.cloneDeep(results), start, end);
	    // 		let invertedActivities = self.invertActivityTime(activityTimes,start,end,'Downtime');
	    // 		return activityTimes.concat(invertedActivities);
	    // 	}else {
	    // 		return [];
	    // 	}
	    // });
	    // allPromisses.push(uptime);

	    return Promise.all(allPromisses).then(data => {
	        self.regionsLoading = false;
	        self.$scope.$evalAsync();
	        return data;
	    });
	}

	calculateActivityTimes(activities, start, end) {
	    let self = this;
	    let currentActivity = {};
	    let newActivities = [];
	    if(activities[0].type == 'End') {
	        let activity = activities.shift();
	        newActivities.push({ class: activity.activity.replace(/ /g, ''), start, end: self.moment.utc(activity.ts) });
	    }
	    let lastActivity;
	    if(activities[activities.length - 1] && activities[activities.length - 1].type == 'Start') {
	        let activity = activities.pop();
	        lastActivity = {
	            class: activity.activity.replace(/ /g, ''),
	            end,
	            start: self.moment.utc(activity.ts)
	        };
	    }
	    let count = 0;
	    activities.forEach((activity, index) => {
	        currentActivity.class = activity.activity.replace(/ /g, '');
	        if(!currentActivity.start) {
	            if(activity.type == 'Start') {
	                currentActivity.start = self.moment.utc(activity.ts);
	            }
	        } else if(activity.type == 'End') {
	            currentActivity.end = self.moment.utc(activity.ts);
	            newActivities.push(_.cloneDeep(currentActivity));
	            currentActivity = {};
	        }
	    });
	    if(currentActivity && currentActivity.start && !currentActivity.end) {
	        currentActivity.end = self.moment.utc(end);
	        newActivities.push(_.cloneDeep(currentActivity));
	    } else if(lastActivity) {
	        newActivities.push(lastActivity);
	    }
	    return newActivities;
	}

	invertActivityTime(activities, start, end, name) {
	    let self = this;
	    let newActivities = [];
	    activities.forEach((activity, index) => {
	        if(activity.start != start && index == 0) {
	            newActivities.push({ class: name.replace(/ /g, ''), start, end: activity.start });
	        }
	        if(activities[index + 1]) {
	            newActivities.push({ class: name.replace(/ /g, ''), start: activity.end, end: activities[index + 1].start });
	        } else if(activity.end != end) {
	            newActivities.push({ class: name.replace(/ /g, ''), start: activity.end, end });
	        }
	    });
	    return newActivities;
	}

	toggleToOpened($event) {
	    $event.preventDefault();
	    $event.stopPropagation();
	    this.toOpened = !this.toOpened;
	}

	toggleFromOpened($event) {
	    $event.preventDefault();
	    $event.stopPropagation();
	    this.fromOpened = !this.fromOpened;
	}

	onChartSelected() {

	}

	removeChart(chartName) {
	    let self = this;
	    let index = _.findIndex(self.selectedCharts, ['name', chartName]);
	    if(index != -1) {
	        self.selectedCharts.splice(index, 1);
	    }
	}

	onDetailUpdate() {
	    this.ranges = this.selectedResolution.ranges;
	    this.selectedRange = this.ranges[0];
	    //this.$stateParams.selectedRange = this.selectedRange;
	    //this.$stateParams.selectedResolution = this.selectedResolution;
	}

	onSelectedRangeSelected() {
	    //this.$stateParams.selectedRange = this.selectedRange;
	    this.setMinDate();
	}

	onPDFExport() {
	    console.debug('pdf');
	}

	onPNGExport() {
	    console.debug('png');
	}

	setMinDate() {
	    let self = this;
	    let end;
	    let min;
	    switch (self.selectedResolution.name) {
	    case '1 Minute':
	        end = self.moment(self.customRange.end);
	        min = end.clone();
	        min.subtract(1440, 'minutes');
	        if(self.customRange.start < min) {
	            self.customRange.start = min.toDate();
	        } else if(self.customRange.start > end) {
	            self.customRange.start = min.toDate();
	        }
	        self.fromOptions = {
	            minDate: min,
	            maxDate: end.toDate()
	        };
	        break;
	    case '5 Minutes':
	        end = self.moment(self.customRange.end);
	        min = end.clone();
	        min.subtract(1440 * 5, 'minutes');
	        if(self.customRange.start < min) {
	            self.customRange.start = min.toDate();
	        } else if(self.customRange.start > end) {
	            self.customRange.start = min.toDate();
	        }
	        self.fromOptions = {
	            minDate: min,
	            maxDate: end.toDate()
	        };
	        break;
	    case '1 Hour':
	        end = self.moment(self.customRange.end);
	        min = end.clone();
	        min.subtract(1440, 'hours');
	        if(self.customRange.start < min) {
	            self.customRange.start = min.toDate();
	        } else if(self.customRange.start > end) {
	            self.customRange.start = min.toDate();
	        }
	        self.fromOptions = {
	            minDate: min,
	            maxDate: end.toDate()
	        };
	        break;
	    case '1 Day':
	        end = self.moment(self.customRange.end);
	        min = end.clone();
	        min.subtract(1440, 'days');
	        if(self.customRange.start < min) {
	            self.customRange.start = min.toDate();
	        } else if(self.customRange.start > end) {
	            self.customRange.start = min.toDate();
	        }
	        self.fromOptions = {
	            minDate: min,
	            maxDate: end.toDate()
	        };
	        break;
	    default:
	    }
	}

	reloadCharts() {
	    let self = this;
	    self.$scope.$broadcast('refreshCharts');
	    delete self.regions;
	    self.regions = self.getRegions();
	}

	zoomToSingleDate(data) {
	    let self = this;
	    let date = self.moment(data.date);
	    switch (self.selectedResolution.name) {
	    case '1 Minute':
	    case '5 Minutes':

	        if(self.$stateParams.unitID == self.$stateParams.accountID) {
	            // self.$stateParams.from = date.subtract(10,'minutes').startOf('minute').toDate();
	            // self.$stateParams.to = date.add(10,'minutes').startOf('minute').toDate();
	            self.$state.go('main.units.detail.metricsum',
	                {
	                    accountID: self.$stateParams.accountID,
	                    unitID: self.$stateParams.unitID,
	                    from: date.clone().subtract(10, 'minutes')
	                        .startOf('minute')
	                        .toDate(),
	                    to: date.clone().add(20, 'minutes')
	                        .startOf('minute')
	                        .toDate(),
	                    metricName: data.metric
	                });
	        } else if(self.selectedResolution.name == '1 Minute') {
	            self.$state.go('main.units.detail.heartbeats',
	                {
	                    accountID: self.$stateParams.accountID,
	                    unitID: self.$stateParams.unitID,
	                    from: date.clone().startOf('minute')
	                        .toDate(),
	                    to: date.clone().endOf('minute')
	                        .toDate(),
	                });
	        } else {
	            self.$state.go('main.units.detail.heartbeats',
	                {
	                    accountID: self.$stateParams.accountID,
	                    unitID: self.$stateParams.unitID,
	                    from: date.clone().toDate(),
	                    to: date.clone().add(5, 'minutes')
	                        .endOf('minute')
	                        .toDate(),
	                });
	        }
	        break;
	    case '1 Hour':
	        let minute = _.find(self.resolutions, { name: '5 Minutes' });
	        self.selectedResolution = minute;
	        self.selectedRange = 'Custom';
	        self.customRange.start = date.clone().startOf('hour')
	            .subtract(1, 'day')
	            .valueOf();
	        self.customRange.end = date.clone().endOf('hour')
	            .add(1, 'day')
	            .valueOf();
	        self.$scope.$broadcast('refreshCharts');

	        break;
	    case '1 Day':
	        let hour = _.find(self.resolutions, { name: '1 Hour' });
	        self.selectedResolution = hour;
	        self.selectedRange = 'Custom';
	        self.customRange.start = date.clone().startOf('day')
	            .subtract(7, 'days')
	            .valueOf();
	        self.customRange.end = date.clone().endOf('day')
	            .add(7, 'days')
	            .valueOf();
	        self.$scope.$broadcast('refreshCharts');
	        break;
	    default:
	    }
	}

	zoomToRegion(data) {
	    let self = this;
	    let start = self.moment(data.start);
	    let end = self.moment(data.end);
	    self.selectedResolution = self.getMinGranularity(start, end);
	    self.selectedRange = 'Custom';
	    self.customRange.start = start.valueOf();
	    self.customRange.end = end.valueOf();
	    self.$scope.$broadcast('refreshCharts');
	}

	getMaxStartDate(end) {
	    let self = this;
	    switch (self.selectedResolution.name) {
	    case '1 Minute':
	        return self.moment(end).subtract(self.dataPointsLimit, 'minutes')
	            .valueOf();
	        break;
	    case '5 Minutes':
	        return self.moment(end).subtract(self.dataPointsLimit * 5, 'minutes')
	            .valueOf();
	        break;
	    case '1 Hour':
	        return self.moment(end).subtract(self.dataPointsLimit, 'hours')
	            .valueOf();
	        break;
	    case '1 day':
	        return self.moment(end).subtract(self.dataPointsLimit, 'days')
	            .valueOf();
	        break;
	    default:
	        return self.moment(end).subtract(self.dataPointsLimit, 'minutes')
	            .valueOf();
	    }
	}

	getMaxEndDate(start) {
	    let self = this;
	    switch (self.selectedResolution.name) {
	    case '1 Minute':
	        return self.moment(start).add(self.dataPointsLimit, 'minutes')
	            .valueOf();
	        break;
	    case '5 Minutes':
	        return self.moment(start).add(self.dataPointsLimit * 5, 'minutes')
	            .valueOf();
	        break;
	    case '1 Hour':
	        return self.moment(start).add(self.dataPointsLimit, 'hours')
	            .valueOf();
	        break;
	    case '1 day':
	        return self.moment(start).add(self.dataPointsLimit, 'days')
	            .valueOf();
	        break;
	    default:
	        return self.moment(start).add(self.dataPointsLimit, 'minutes')
	            .valueOf();
	    }
	}

	getMinGranularity(start, end) {
	    let self = this;
	    let diff = end.diff(start, 'minutes');
	    if(diff <= self.dataPointsLimit) {
	        return _.find(self.resolutions, { name: '1 Minute' });
	    } else if(diff <= self.dataPointsLimit * 5) {
	        return _.find(self.resolutions, { name: '5 Minutes' });
	    } else if(diff <= self.dataPointsLimit * 60) {
	        return _.find(self.resolutions, { name: '1 Hour' });
	    } else {
	        return _.find(self.resolutions, { name: '1 Day' });
	    }
	}

	zoomToDate(data) {
	    let self = this;
	    if(data.date) {
	        self.zoomToSingleDate(data);
	    } else if(data.start && data.end) {
	        self.zoomToRegion(data);
	    }
	}

	zoomOut() {
	    let self = this;
	    let date;
	    if(self.selectedRange == 'Custom') {
	        let diff = self.moment(self.customRange.end).diff(self.moment(self.customRange.start));
	        diff = diff / 2;
	        date = self.moment(self.customRange.start).add(diff, 'ms');
	    } else {
	        date = self.moment();
	    }
	    switch (self.selectedResolution.name) {
	    case '1 Minute':
	        let minute = _.find(self.resolutions, { name: '5 Minutes' });
	        self.selectedResolution = minute;
	        self.selectedRange = 'Custom';
	        self.customRange.start = date.clone().startOf('hour')
	            .subtract(1, 'day')
	            .valueOf();
	        self.customRange.end = date.clone().endOf('hour')
	            .add(1, 'day')
	            .valueOf();
	        self.$scope.$broadcast('refreshCharts');
	        break;
	    case '5 Minutes':
	        let hour = _.find(self.resolutions, { name: '1 Hour' });
	        self.selectedResolution = hour;
	        self.selectedRange = 'Custom';
	        self.customRange.start = date.clone().startOf('day')
	            .subtract(7, 'days')
	            .valueOf();
	        self.customRange.end = date.clone().endOf('day')
	            .add(7, 'days')
	            .valueOf();
	        self.$scope.$broadcast('refreshCharts');
	        break;
	    case '1 Hour':
	        let day = _.find(self.resolutions, { name: '1 Day' });
	        self.selectedResolution = day;
	        self.selectedRange = 'Custom';
	        self.customRange.start = date.clone().startOf('day')
	            .subtract(30, 'days')
	            .valueOf();
	        self.customRange.end = date.clone().endOf('day')
	            .add(30, 'days')
	            .valueOf();
	        self.$scope.$broadcast('refreshCharts');
	        break;
	    default:
	    }
	}
	move(direction) {
	    let self = this;
	    let start;
	    let end;
	    if(self.selectedRange == 'Custom') {
	        let diff = self.moment(self.customRange.end).diff(self.moment(self.customRange.start));
	        diff = diff;
	        if(direction == 'next') {
	            start = self.moment(self.customRange.end);
	            end = start.clone().add(diff, 'ms');
	        } else {
	            end = self.moment(self.customRange.start);
	            start = end.clone().subtract(diff, 'ms');
	        }
	    } else {
	        end = self.moment();
	        let amount = +self.selectedRange.split(' ')[0];
	        let gran = self.selectedRange.split(' ')[1];
	        start = end.clone().subtract(amount, gran);
	    }
	    self.selectedRange = 'Custom';
	    self.customRange.start = start.valueOf();
	    self.customRange.end = end.valueOf();
	    self.$scope.$broadcast('refreshCharts');
	}
}

export default angular.module('insideInfoApp.units')
    .component('unitdetailcharts', {
        template: require('./units.detail.charts.html'),
        controller: UnitsDetailChartsComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class UserModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	availableGroups;
	contact;
	Contacts;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular, Auth) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.Auth = Auth;
	}

	$onInit() {
		let self = this;
		this.settings = this.resolve.settings;
		this.$uibModalInstance = this.modalInstance;

		this.currentUser = this.Auth.getCurrentUserSync();
		this.currentAccount = this.Auth.getCurrentAccountSync();

		if (this.currentUser.role === "admin") {
			this.availableRoles = ["user", "accountManager", "admin"];
		}
		if (this.currentUser.role === "accountManager") {
			this.availableRoles = ["user", "accountManager"];
		}
		if (this.currentUser.role === "user") {
			this.availableRoles = [];
		}
		// Add alert data to controller
		if (this.settings.item) {
			let item = this.settings.item;
			this.user = this.Restangular.copy(item);
		} else {
			this.user = {
				accounts: [
					{
						name: this.currentAccount.name,
						ref: this.currentAccount.ref,
					},
				],
				role: "user",
			};
		}
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		this.$uibModalInstance.close(this.user);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss("Cancel pressed");
	}
}

export default angular.module("insideInfoApp.users").component("usermodal", {
	template: require("./users.modal.component.html"),
	bindings: {
		resolve: "<",
		close: "&",
		dismiss: "&",
		modalInstance: "<",
	},
	controller: UserModalController,
	controllerAs: "$ctrl",
}).name;
// .controller(ContactModalController).name;

(function() {
    'use strict';

    angular.module('SmartAdmin', [
        'SmartAdmin.Forms',
        'SmartAdmin.Layout',
        'SmartAdmin.UI',
    ]);
}());
import smartAdminForms from './forms/module';
import smartAdminLayout from './layout/module';
import smartAdminUI from './ui/module';
import jqueryui from 'jquery-ui';
import '../../SmartAdmin/js';

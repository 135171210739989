'use strict';
// @flow
type User = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export class ChangePasswordComponent {
  user: User = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
  };
  errors = {
      other: undefined
  };
  message = '';
  submitted = false;
  Auth;

  /*@ngInject*/
  constructor(Auth, toastr) {
      this.Auth = Auth;
      this.toastr = toastr;
  }

  changePassword(form) {
      this.submitted = true;

      if(form.$valid) {
          this.Auth.changePassword(this.user.oldPassword, this.user.newPassword)
              .then(() => {
                  this.message = 'Password successfully changed.';
                  this.toastr.info('Password successfully changed', 'Changed Password');
              })
              .catch(() => {
                  form.password.$setValidity('mongoose', false);
                  this.errors.other = 'Incorrect password';
                  this.message = '';
              });
      }
  }
}

export default angular.module('insideInfoApp.settings')
    .component('changepassword', {
        template: require('./settings.user.changepassword.html'),
        controller: ChangePasswordComponent,
        controllerAs: '$ctrl'
    })
    .name;

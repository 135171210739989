import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import routes from './alerts.routes';
import Restangular from 'restangular';
import socket from '../../components/socket/socket.service';
import uiSelect from 'ui-select';


export default angular.module('insideInfoApp.alerts', [uiRouter, uiBootstrap, Restangular, 'btford.socket-io', uiSelect])
    .config(routes)
    .name;

require('./alerts.active.component');
require('./alerts.closed.component');
require('./alerts.modal.component');
require('./alerts.settings.component');

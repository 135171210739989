import { findIndex } from "lodash";

export class UsersListComponent {
	Restangular;
	Contacts;
	Auth;
	$stateParams;
	NgTableParams;
	tableParams;
	$uibModal;
	$log;

	/*@ngInject*/
	constructor(
		Restangular,
		Auth,
		$stateParams,
		NgTableParams,
		$uibModal,
		$http,
		toastr,
		$ngConfirm
	) {
		this.Auth = Auth;
		this.$stateParams = $stateParams;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$log = $uibModal;
		this.Restangular = Restangular;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.$http = $http;
		this.toastr = toastr;
		this.$ngConfirm = $ngConfirm;
	}

	$onInit() {
		let self = this;
		this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
		this.filter = "";
		this.Users = this.Restangular.all("users");

		this.currentUser = this.Auth.getCurrentUserSync();
		this.currentAccount = this.Auth.getCurrentAccountSync();

		this.tableParams = new this.NgTableParams(
			{
				page: 1, // start with first page
				count: 10, // count per page
				sorting: {
					firstname: "asc", // initial sorting
				},
			},
			{
				total: 0,
				getData(params) {
					let order;
					if (params && params.sorting) {
						order = params.sorting();
						return self.Users.getList({
							accountID: self.$stateParams.accountID,
							filter: self.filter.length
								? self.filter
								: undefined,
							skip: (params.page() - 1) * params.count(),
							limit: params.count(),
							by: Object.keys(order)[0],
							order: order[Object.keys(order)[0]],
						})
							.then(function (users) {
								self.users = users;
								self.total = users.total;
								params.total(users.total);
								return users;
							})
							.catch((err) => {
								console.error(
									"Error when retrieving users:",
									err.data.err
								);
							});
					}
				},
			}
		);
		this.tableParams.reload();
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	getAccountName(accountID) {
		let user = this.getCurrentUser();
		if (!user.accounts || typeof user.accounts === Array) {
			return "No Account";
		}
		let account;
		for (let i = 0; i < user.accounts.length; ++i) {
			if (user.accounts[i].ref === accountID) {
				account = user.accounts[i];
				break;
			}
		}
		if (account !== undefined) {
			return account.name;
		}
		return "No Account";
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openCreateUser() {
		let self = this;
		let modalInstance = this.$uibModal.open({
			component: "usermodal",
			bindToController: true,
			size: "md",
			backdrop: "static",
			resolve: {
				settings() {
					return {
						edit: false,
						create: true,
						account: {
							ref: self.$stateParams.accountID,
							name: self.getAccountName(
								self.$stateParams.accountID
							),
						},
					};
				},
			},
		});

		modalInstance.result.then(function (result) {
			self.Users.post(result, {
				accountID: self.$stateParams.accountID,
			})
				.then(function () {
					self.tableParams.reload();
				})
				.catch((err) => {
					self.toastr.error("User creation failed");
				});
		});
	}

	openEditUser(user) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: "usermodal",
			bindToController: true,
			size: "md",
			backdrop: "static",
			resolve: {
				settings() {
					return {
						edit: true,
						create: false,
						account: {
							ref: self.$stateParams.accountID,
							name: self.getAccountName(
								self.$stateParams.accountID
							),
						},
						item: user,
					};
				},
			},
		});

		modalInstance.result.then(function (result) {
			result
				.patch()
				.then(function () {
					self.tableParams.reload();
				})
				.catch((err) => {
					self.toastr.error("User edit failed");
				});
		});
	}

	onDeleteUser(user) {
		let self = this;

		this.$ngConfirm({
			title: `Delete user?`,
			content: `This will permanently delete ${user.firstname} ${user.lastname}.`,
			scope: self.$scope,
			buttons: {
				ok: {
					text: '<i class="fa fa-trash"></i> Delete',
					btnClass: "btn-danger",
					action() {
						user.remove()
							.then(function () {
								self.tableParams.reload();
							})
							.catch((err) => {
								self.toastr.error("User delete failed");
							});
					},
				},
				cancel: {
					text: "Cancel",
					btnClass: "btn-warning",
					action() {},
				},
			},
		});
	}

	onRemoveUser(user) {
		let self = this;
		this.$ngConfirm({
			title: `Remove user?`,
			content: `This will ${user.firstname} ${user.lastname} from the current account.`,
			scope: self.$scope,
			buttons: {
				ok: {
					text: '<i class="fa fa-trash"></i> Remove',
					btnClass: "btn-danger",
					action() {
						self.$http
							.patch(`/api/users/${user._id}/removeAccount`)
							.then((res) => {
								self.tableParams.reload();
							})
							.catch((err) => {
								self.toastr.error("User remove failed");
							});
					},
				},
				cancel: {
					text: "Cancel",
					btnClass: "btn-warning",
					action() {},
				},
			},
		});
	}

	openImportUsers() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: "usersImportModal",
			size: "md",
			backdrop: "static",
		});

		modalInstance.result
			.then(function () {
				self.tableParams.reload();
			})
			.catch((err) => {
				if (err !== "Cancel pressed")
					self.toastr.error("User import failed");
			});
	}
}

export default angular.module("insideInfoApp.users").component("userslist", {
	template: require("./users.list.component.html"),
	controller: UsersListComponent,
	controllerAs: "$ctrl",
}).name;


import { availableDataBundles } from './availableDataBundles';

const AIRTIME_RELOAD_VALUE = 10;
const DATA_RELOAD_VALUE = 1024 * 1024 * 50;
export class DataManagementModalComponent {
    /*@ngInject*/
    constructor(Restangular, Auth, toastr, NgTableParams, $ngConfirm, $scope, moment, socket, $stateParams) {
        this.Auth = Auth;
        this.Restangular = Restangular;
        this.toastr = toastr;
        this.$scope = $scope;
        this.moment = moment;
        this.$ngConfirm = $ngConfirm;
        this.NgTableParams = NgTableParams;
        this.$stateParams = $stateParams;
        this.socket = socket;
        this.availableDataBundles = availableDataBundles;
        this.reloadType = 'airtime';
        this.checked = {
            maps: {
                airtime: {},
                data: {},
                errors: {},
                warnings: {},
                ignored: {},
                remaining: {},
            },
            lists: {
                airtime: [],
                data: [],
                errors: [],
                warnings: [],
                ignored: [],
                remaining: [],
                excludable: [],
            }
        };
        this.rechargeList = [];
        this.airtimeAmount = 30;
        this.metricList = [
            'Delta Data Usage',
            'Delta Network Data Usage',
            'Delta Network Airtime Usage',
            'Airtime Reload',
            'Data Reload',
        ];
    }

    async $onInit() {
        this.$uibModalInstance = this.modalInstance;

        this.currentUser = this.Auth.getCurrentUserSync();
        this.dataWithErrors = [];
        this.dataWithWarnings = [];
        this.loadAirtimeData = [];
        this.loadData = [];
        this.remainingData = [];
        this.ignoredList = [];

        await this.reloadUnits();
        this.unitEventListener = this.onReceiptOfUnit.bind(this);
        this.socket.socket.on('unit:save', this.unitEventListener);
        this.socket.joinRoom(`${this.$stateParams.accountID}:*:units`);

        this.$scope.$watch(() => JSON.stringify(this.checked.maps), this.updateCheckedLists.bind(this));
    }

    $onDestroy() {
        if(this.unitEventListener) {
            this.socket.socket.removeListener('unit:save', this.unitEventListener);
        }
        this.socket.leaveRoom(`${this.$stateParams.accountID}:*:units`);
    }

    updateCheckedLists() {
        for(const key in this.checked.maps) {
            const checkedMap = this.checked.maps[key];
            this.checked.lists[key] = Object.entries(checkedMap).filter(a => a[1])
                .map(a => a[0]);
        }

        this.checked.lists.excludable = [
            ...this.checked.lists.airtime,
            ...this.checked.lists.data,
            ...this.checked.lists.warnings,
            ...this.checked.lists.errors,
            ...this.checked.lists.remaining,
        ];
    }

    async reloadUnits() {
        this.loading = true;
        this.unselectAll();
        this.units = await this.Restangular.all('units').all('dataManagementUnits')
            .getList();
        this.formatAndGroupData();
        this.loading = false;
    }

    unselectAll() {
        this.checked = {
            maps: {
                airtime: {},
                data: {},
                errors: {},
                warnings: {},
                ignored: {},
                remaining: {},
            },
            lists: {
                airtime: [],
                data: [],
                errors: [],
                warnings: [],
                ignored: [],
                remaining: [],
                excludable: [],
            }
        };
        this.rechargeList = [];
    }

    onReceiptOfUnit(unit) {
        let index = _.findIndex(this.units, o => o._id == unit._id);
        if(index !== -1) {
            _.mergeWith(this.units[index], unit, (objValue, srcValue) => {
                if(_.isArray(objValue) && srcValue) {
                    return srcValue;
                }
            });
        }
        this.formatAndGroupData();
    }

    formatAndGroupData() {
        this.dataWithErrors = [];
        this.dataWithWarnings = [];
        this.loadAirtimeData = [];
        this.loadData = [];
        this.remainingData = [];
        this.ignoredList = [];

        this.formattedData = this.formatUnitData(this.units);
        console.log(this.formattedData);
        for(const data of this.formattedData) {
            if(data.excludeFromAirtimeManagement) {
                this.ignoredList.push(data);
                continue;
            }

            data.errors = this.formatErrors(data);
            if(data.errors.length) {
                this.dataWithErrors.push(data);
                continue;
            }
            data.warnings = this.formatWarnings(data);
            if(data.warnings.length) {
                this.dataWithWarnings.push(data);
                continue;
            }
            const reloadData = this.shouldReloadData(data);
            const reloadAirtime = this.shouldReloadAirtime(data);
            if(reloadData) this.loadData.push(data);
            if(reloadAirtime) this.loadAirtimeData.push(data);

            if(reloadData || reloadAirtime) {
                continue;
            }
            this.remainingData.push(data);
        }
        this.totalErrors = this.dataWithErrors.reduce((sum, { errors }) => sum + errors.length, 0);
        this.totalWarnings = this.dataWithWarnings.reduce((sum, { warnings }) => sum + warnings.length, 0);
    }

    findUnitByKey(key) {
        return this.units.find(unit => unit._id === key.split('_')[0]);
    }

    formatKeyForRechargeList(key, data = false) {
        //Add to list
        const split = key.split('_');
        const sim = split[1] - 1;
        const unit = this.findUnitByKey(key);
        const simInfo = unit.simInfo[`sim${sim}`];
        const providerRaw = unit.status[`networkNameSIM${sim + 1}`];
        const provider = providerRaw.match(/mtn/i) ? 'mtn' : providerRaw.match(/vodacom/i) ? 'vodacom' : undefined;
        const value = !data ? this.airtimeAmount : provider === 'mtn' ? this.mtnDataBundle.value : this.vodacomDataBundle.value;
        if(!provider) {
            _.remove(this.rechargeList, { id: key });
            this.toastr.error(`Could not determine network provider from ${providerRaw}`);
        }
        return {
            id: key,
            msisdn: simInfo.number.replace('+27', '0'),
            network: `${data ? 'pd-' : ''}${provider}`,
            value,
            reference: `${unit.bcUnit?.account?.name} - ${unit.name}`
        };
    }

    exportSimControlCSV() {
        this.airtimeAmount = 30;
        this.updateLastTopUpDate = true;
        this.reloadModal = {
            airtime: this.checked.lists.airtime.map(this.findUnitByKey.bind(this)),
            mtnData: this.checked.lists.airtime.map(key => {
                const unit = this.findUnitByKey(key);
                if(unit.status[`networkNameSIM${key.split('_')[1]}`].match(/mtn/i)) {
                    return unit;
                }
                return false;
            }).filter(a => a),
            vodacomData: this.checked.lists.airtime.map(key => {
                const unit = this.findUnitByKey(key);
                if(unit.status[`networkNameSIM${key.split('_')[1]}`].match(/vodacom/i)) {
                    return unit;
                }
                return false;
            }).filter(a => a),
        };
        this.$ngConfirm({
            title: 'Recharge Airtime / Data',
            content: require('./confirm-content.html'),
            scope: this.$scope,
            buttons: {
                ok: {
                    text: '<i class="fa fa-download"></i> Export',
                    btnClass: 'btn-primary',
                    action: async() => {
                        //Export
                        try {
                            const rechargeList = [
                                ...this.checked.lists.airtime.map(key => this.formatKeyForRechargeList(key, false)),
                                ...this.checked.lists.data.map(key => this.formatKeyForRechargeList(key, true))
                            ];
                            if(this.updateLastTopUpDate) {
                                const results = await this.updateUnitsLastTopUpDate(rechargeList);
                            }
                            this.generateAndDownloadCSV(rechargeList);
                        } catch(error) {
                            console.error(error);
                            this.toastr.error('Export failed.');
                        }
                    },
                },
                cancel: {
                    text: 'Cancel',
                    btnClass: 'btn-warning',
                    action() { },
                },
            },
        });
    }

    exportCSV() {
        const keys = [
            { key: 'name', label: 'Name' },
            { key: 'online', label: 'Online' },
            { key: 'lastHB.0.ts', label: 'Last Activity' },
            { key: 'bcUnit.account.name', label: 'BC Account Name' },
            { key: 'simInfo.sim0.remainingAirtime', label: 'Sim 1 Reamining Airtime' },
            { key: 'simInfo.sim0.remainingAirtimeUpdated', label: 'Sim 1 Remaining Airtime Updated' },
            { key: 'simInfo.sim0.lastAirtimeTopUpDate', label: 'Sim 1 Last Top-up Date' },
            { key: 'simInfo.sim0.remainingData', label: 'Sim 1 Reamining Data' },
            { key: 'simInfo.sim0.remainingDataUpdated', label: 'Sim 1 Reamining Data Updated' },
            { key: 'bcUnit.sim1.number', label: 'Sim 1 Number' },
            { key: 'bcUnit.sim1.network', label: 'Sim 1 Network' },
            { key: 'simInfo.sim1.remainingAirtime', label: 'Sim 2 Reamining Airtime' },
            { key: 'simInfo.sim1.remainingAirtimeUpdated', label: 'Sim 2 Reamining Airtime Updated' },
            { key: 'simInfo.sim1.lastAirtimeTopUpDate', label: 'Sim 2 Last Top-up Date' },
            { key: 'simInfo.sim1.remainingData', label: 'Sim 2 Reamining Data' },
            { key: 'simInfo.sim1.remainingDataUpdated', label: 'Sim 2 Reamining Data Updated' },
            { key: 'bcUnit.sim2.number', label: 'Sim 2 Number' },
            { key: 'bcUnit.sim2.network', label: 'Sim 2 Network' },
        ];

        this.generateAndDownloadCSV(this.units, keys);
    }

    updateUnitsLastTopUpDate(rechargeList) {
        let promises = [];
        let date = new Date();
        let updateUnits = [];
        rechargeList.forEach(item => {
            const key = item.id;
            const split = key.split('_');
            const unitId = split[0];
            let sim = split[1] - 1;
            let unit = _.find(updateUnits, u => u._id === unitId);
            if(!unit) {
                unit = _.find(this.units, u => u._id === unitId);
                updateUnits.push(unit);
            }
            const topUpType = item.network.startsWith('pd-') ? 'Data' : 'Airtime';
            _.set(unit, `simInfo.sim${sim}.last${topUpType}TopUpDate`, date);
            _.set(unit, `simInfo.sim${sim}.last${topUpType}TopUpAmount`, item.value);
        });
        updateUnits.forEach(unit => {
            promises.push(this.Restangular.one('units', unit._id).patch(unit));
        });
        return Promise.all(promises);
    }

    convertSelectedToUpdate(include) {
        const updates = {};
        const list = include ? this.checked.lists.ignored : this.checked.lists.excludable;
        for(const key of list) {
            const split = key.split('_');
            const unitId = split[0];
            if(!updates[unitId]) {
                updates[unitId] = { _id: unitId };
            }
            updates[unitId][`sim${split[1] - 1}`] = true;
        }
        return Object.values(updates);
    }

    async excludeSelected() {
        const updates = this.convertSelectedToUpdate();

        try {
            const updatedUnits = await this.Restangular.all('units').customPATCH({ exclude: true, units: updates }, 'dataManagementUnits');
            this.toastr.info(`Excluded ${updatedUnits.length} sim(s)`);
        } catch(error) {
            this.toastr.error(`Could not update units : ${error.message}`);
            console.error(error);
        }
        this.unselectAll();
    }

    async includeSelected() {
        const updates = this.convertSelectedToUpdate(true);

        try {
            const updatedUnits = await this.Restangular.all('units').customPATCH({ exclude: false, units: updates }, 'dataManagementUnits');
            this.toastr.info(`Included ${updatedUnits.length} sim(s)`);
        } catch(error) {
            this.toastr.error(`Could not update units : ${error.message}`);
            console.error(error);
        }
        this.unselectAll();
    }

    generateAndDownloadCSV(units = this.rechargeList, keys = [
        { key: 'msisdn', label: 'msisdn', },
        { key: 'network', label: 'network' },
        { key: 'value', label: 'value' },
        { key: 'reference', label: 'reference' },
    ]) {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += keys.map(k => k.label).join(',');
        csvContent += '\n';
        units.forEach(unit => {
            let row = keys.map(key => _.get(unit, key.key, 'N/A')).join(',');
            csvContent += row;
            csvContent += '\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `recharge_list_${this.moment().format()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    // exportDataXLSX() {

    //     let arrs = [];
    //     let wb = xlsx.utils.book_new();

    //     let formatPivots = [];
    //     let accountPivots = {};
    //     let headerLabels = _.map(this.cols, (col) => {
    //         let title = col.title;
    //         if(col.unit) title += ` (${col.unit})`;
    //         return title;
    //     });
    //     let headers = _.map(this.cols, 'field');
    //     let columnWidths = [];
    //     headerLabels.forEach(label => {
    //         columnWidths.push(label.length);
    //     });

    //     _.forEach(pivots, pivot => {
    //         let formattedPivot = this.formatPivot(pivot);

    //         _.forEach(this.cols, (col, index) => {
    //             columnWidths[index] = Math.max(columnWidths[index], formattedPivot[col.field]?.length || 0);
    //         });

    //         if(accountPivots[pivot.account.ref] == undefined) {
    //             accountPivots[pivot.account.ref] = {
    //                 name: pivot.account.name,
    //                 pivots: [],
    //             };
    //         }
    //         accountPivots[pivot.account.ref].pivots.push(_.omit(formattedPivot, ['account']));
    //         formatPivots.push(formattedPivot);
    //     });

    //     arrs.push(headerLabels);
    //     let ws = xlsx.utils.aoa_to_sheet(arrs);
    //     let wscols = _.map(columnWidths, (width) => {wch: width})

    //     ws['!cols'] = wscols;
    //     xlsx.utils.sheet_add_json(ws, formatPivots, { header: headers, skipHeader: true, origin: arrs.length });
    //     xlsx.utils.book_append_sheet(wb, ws, "All accounts");

    //     _.forEach(accountPivots, account => {
    //         arrs = [];
    //         arrs.push(headerLabels);
    //         ws = xlsx.utils.aoa_to_sheet(arrs);
    //         ws['!cols'] = wscols;

    //         xlsx.utils.sheet_add_json(ws, account.pivots, { header: headers, skipHeader: true, origin: arrs.length });
    //         xlsx.utils.book_append_sheet(wb, ws, `${account.name}`);
    //     });


    //     xlsx.write(wb, { bookType: "xlsx", bookSST: true, type: 'base64' });
    //     xlsx.writeFile(wb, `Pivot_Configurations_${this.moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`);
    // }

    canReload({
        number,
        remainingAirtime,
        network
    }) {
        if(!number) {
            return false;
        }
        if(!network) {
            return false;
        }
        if(!network.match(/mtn|vodacom/i)) {
            return false;
        }
        if(remainingAirtime == undefined) {
            return false;
        }
        return true;
    }

    shouldReloadAirtime(data) {
        const {
            remainingAirtime,
            remainingAirtimeUpdated,
            lastAirtimeTopUpDate,
        } = data;
        if(!this.canReload(data)) {
            return false;
        }
        if(remainingAirtime > AIRTIME_RELOAD_VALUE) {
            return false;
        }

        const lastAirtimeResponseMoment = this.moment.utc(remainingAirtimeUpdated);

        const lastAirtimeTopUpMoment = this.moment.utc(lastAirtimeTopUpDate);
        // Check last reload
        if(lastAirtimeTopUpDate && lastAirtimeTopUpMoment.isAfter(this.moment().subtract(2, 'weeks'))) {
            return false;
        }

        // Check last USSD > last reload
        if(lastAirtimeTopUpDate && remainingAirtimeUpdated && lastAirtimeTopUpMoment.isAfter(lastAirtimeResponseMoment)) {
            return false;
        }


        return true;
    }

    shouldReloadData(data) {
        const {
            remainingData,
            remainingDataUpdated,
            lastDataTopUpDate,
            remainingAirtime,
        } = data;
        if(!this.canReload(data)) {
            return false;
        }
        if(remainingData > DATA_RELOAD_VALUE || remainingAirtime > AIRTIME_RELOAD_VALUE) {
            return false;
        }

        const lastDataResponseMoment = this.moment.utc(remainingDataUpdated);

        const lastDataTopUpMoment = this.moment.utc(lastDataTopUpDate);
        // Check last reload
        if(lastDataTopUpDate && lastDataTopUpMoment.isAfter(this.moment().subtract(2, 'weeks'))) {
            return false;
        }

        // Check last USSD > last reload
        if(lastDataTopUpDate && remainingDataUpdated && lastDataTopUpMoment.isAfter(lastDataResponseMoment)) {
            return false;
        }


        return true;
    }

    formatErrors({
        remainingAirtimeUpdated,
        network,
        lastActivity
    }) {
        const errors = [];
        if(!lastActivity) {
            errors.push({
                message: 'No heartbeat has been received for unit.',
                type: 'NO_HB',
                label: 'No Heartbeat'
            });
        }

        if(!network) {
            errors.push({
                message: 'Unit has unknown network.',
                type: 'UNKNOWN_NETWORK',
                label: 'Unknown Network Provider'
            });
        }

        if(network && !network.match(/mtn|vodacom/i)) {
            errors.push({
                message: `Unit has unsupported network : ${network} (Only Vodacom and MTN are supported).`,
                type: 'UNSUPPORTED_NETWORK',
                label: 'Unsupported Network Provider'
            });
        }

        if(!remainingAirtimeUpdated) {
            errors.push({
                message: 'No USSD response has been received on this sim.',
                type: 'NO_USSD_RESPONSE',
                label: 'No USSD Response'
            });
        }

        return errors;
    }

    formatWarnings({
        number,
        lastActivity,
        remainingAirtimeUpdated,
        network
    }) {
        const warnings = [];
        if(!number && remainingAirtimeUpdated && network) {
            warnings.push({
                message: 'No number available.',
                type: 'NO_NUMBER',
                label: 'No Number'
            });
        }
        if(lastActivity && this.moment.utc(lastActivity).isBefore(this.moment().subtract(1, 'month'))) {
            const diff = this.moment.utc(lastActivity).subtract(this.moment().subtract(1, 'month'));
            const duration = this.moment.duration(diff);
            warnings.push({
                message: `Unit has been offline for more that 1 month (${duration.humanize()})`,
                type: 'OFFLINE',
                label: 'Extended Offline'
            });
        }

        return warnings;
    }

    formatUnitData(units) {
        return units
            .map(({ _id, name, bcUnit, simInfo, status, metrics, metricsWeekly, ...unit }) => ({
                _id,
                name,
                bcAccount: bcUnit?.account?.name,
                activeSim: unit.lastHb?.[0]?.status?.sim,
                lastActivity: unit.lastHB?.[0]?.ts,
                online: unit.online,
                sim0: { ...simInfo?.sim0, network: status?.networkNameSIM1, metrics: metrics?.sim0, metricsWeekly: metricsWeekly?.sim0 },
                sim1: { ...simInfo?.sim1, network: status?.networkNameSIM2, metrics: metrics?.sim1, metricsWeekly: metricsWeekly?.sim1},
            }))
            .reduce((list, { sim0, sim1, ...rest }) => {
                sim0.metricTotals = this.sumMetrics(sim0.metrics);
                sim1.metricTotals = this.sumMetrics(sim1.metrics);
                sim0.weeklyMetricTotals = this.sumMetrics(sim0.metricsWeekly);
                sim1.weeklyMetricTotals = this.sumMetrics(sim1.metricsWeekly);
                const sim0Info = { ...rest, ...sim0, sim: 1 };
                const sim1Info = { ...rest, ...sim1, sim: 2 };
                list.push({...sim0Info, otherSim: sim1Info});
                list.push({...sim1Info, otherSim: sim0Info});
                return list;
            }, []);
    }

    sumMetrics(metrics) {
        const totals = {};
        for(const key of this.metricList) {
            if(!totals[key]) {
                totals[key] = 0;
            }
            const data = metrics[key] || [];
            totals[key] = data.reduce((sum, metric) => sum + metric.value, 0);
        }
        return totals;
    }


    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }
    doLog() {
        console.debug(this);
    }
}

export default angular.module('insideInfoApp.units')
    .component('datamanagement', {
        template: require('./data-management.html'),
        controller: DataManagementModalComponent,
        controllerAs: '$ctrl'
    })
    .name;

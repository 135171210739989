'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.users', {
            abstract: true,
            url: '/users',
            data: {
                title: 'Users'
            },
            authenticate: true
        })

        .state('main.users.list', {
            url: '/list',
            views: {
                'content@main': {
                    template: '<userslist></userslist>'
                }
            },
            data: {
                title: 'List'
            },
            authenticate: true
        })
}

'use strict';

angular.module('SmartAdmin.Layout').directive('resetWidgets', function($state) {
    return {
        restrict: 'A',
        link(scope, element) {
            element.on('click', function() {
                $.SmartMessageBox({
                    title: '<i class=\'fa fa-refresh\' style=\'color:green\'></i> Clear Local Storage',
                    content: 'Would you like to RESET all your saved widgets and clear LocalStorage?1',
                    buttons: '[No][Yes]'
                }, function(ButtonPressed) {
                    if(ButtonPressed == 'Yes' && localStorage) {
                        localStorage.clear();
                        location.reload();
                    }
                });
            });
        }
    };
});

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('verify', {
	  url: '/verify/email/:id/:token?next',
	  views: {
		  root: {
			  template: '<verifyemail></verifyemail>'
		  }
	  }
        });
}

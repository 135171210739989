export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.groups', {
            abstract: true,
            url: '/groups',
            authenticate: true,
            data: {
                title: 'Groups'
            }
        })

        .state('main.groups.users', {
            url: '/users',
            authenticate: true,
            views: {
                'content@main': {
                    template: '<groupusers></groupusers>'
                }
            },
            data: {
                title: 'Users'
            }
        })

        .state('main.groups.units', {
            url: '/units',
            authenticate: true,
            views: {
                'content@main': {
                    template: '<groupunits></groupunits>'
                }
            },
            data: {
                title: 'Units'
            }
        });
}

'use strict';

export default function routes($stateProvider) {
    'ngInject';

    $stateProvider.state('main', {
        url: '^/:accountID',
        abstract: true,
        params: {
            accountID: {
                dynamic: true
            }
        },
        views: {
            root: {
                template: '<main></main>'
            }
        },
        /*resolve: {
			accountID: ['$stateParams','Auth', function($stateParams, Auth) {

				var p = new Promise((resolve, reject) => {
				    setTimeout(() => {
				        resolve('Frikkie');
				    }, 5000);
				});
				return "59b62f358c5a511a330ae49c";
				// return Auth.getCurrentAccount().then((account)=>{
				// 	return account.ref;
				// });
			}]
		}*/
    });
}

export class ResetPasswordRequestComponent {
  $stateParams;
  User;
  url;
  requested = false;
  requesting = false;
  email = '';
  changed = false;
  changing = false;

  /*@ngInject*/
  constructor($stateParams, User) {
      this.$stateParams = $stateParams;
      this.User = User;
      this.url = this.$stateParams.next;
  }

  forgotPassword(form) {
      this.requesting = true;
      if(form.$valid) {
          this.User.forgotPassword({email: this.email}).$promise.then((/*value, requestHeaders*/) => {
              this.requested = true;
              this.requesting = false;
          });
      }
  }
}

export default angular.module('insideInfoApp.reset')
    .component('resetpasswordrequest', {
        template: require('./reset.password.request.html'),
        controller: ResetPasswordRequestComponent,
        controllerAs: '$ctrl'
    })
    .name;

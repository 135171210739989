'use strict';

import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import 'angular-socket-io';
import uiGrid from 'angular-ui-grid';

import uiRouter from 'angular-ui-router';
import 'angular-ui-router/lib/legacy/stateEvents';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import ngMessages from 'angular-messages';
import ngValidationMatch from 'angular-validation-match';
import angularGridster from 'angular-gridster';
import angularToastr from 'angular-toastr';
import tinyGradient from 'tinygradient';
import angularColorPicker from 'angular-bootstrap-colorpicker';
import angularConfirm from "angular-confirm1";

import Restangular from 'restangular';
// import ngTable from 'ng-table';
import NgTable from 'ng-table/bundles/ng-table';

import 'jquery';
import 'jquery-ui-bundle';

import {routeConfig} from './app.config';

import smartAdminConfig from './smartadmin.config';
window.appConfig = smartAdminConfig;

import _Auth from '../components/auth/auth.module';
import navbar from '../components/navbar/navbar.component';
import footer from '../components/footer/footer.component';
import header from '../components/header/header.component';
import main from './main/main.component';
import account from './account';
import constants from './app.constants';
import util from '../components/util/util.module';
import socket from '../components/socket/socket.service';
import consent from '../components/consent/consent.component';
import smartAdmin from './_common/module';
import Contacts from './contacts';
import Reports from './reports';
import Alerts from './alerts';
import Units from './units';
import Events from './events';
import Dashboard from './dashboard';
import Groups from './groups';
import Settings from './settings';
import Users from './users';
import './app.scss';
import ngIdle from 'ng-idle';
import ngStorage from 'ngstorage';
import angularMoment from 'angular-moment';
import d3 from 'd3';
import c3 from 'c3';
import angularPaging from 'angular-paging';
import angularBootstrapContextmenu from 'angular-bootstrap-contextmenu';
// import c3Angular from 'c3-angularjs';

require('cookieconsent');

const cc = window.cookieconsent.initialise({
    palette: {
        popup: {
            background: '#252e39'
        },
        button: {
            background: '#14a7d0'
        }
    },
    content: {
        href: '/assets/policy/privacy.html'
    }
});

angular.module('insideInfoApp', [ngCookies, ngResource, ngAnimate, 'ngSanitize', 'btford.socket-io', uiRouter, 'ui.router.state.events',
    uiBootstrap, uiSelect, Restangular, 'ui.grid', 'ui.bootstrap.contextMenu',
    "cp.ngConfirm",
    _Auth,
    account, 'validation.match', navbar, footer, header, main, constants, socket, util,
    'SmartAdmin', 'SmartAdmin.Layout', 'SmartAdmin.UI', 'SmartAdmin.Forms',
    Contacts, Reports, Alerts, Units, Dashboard, Groups, Settings, Users, ngIdle, Events,
    'ngStorage', 'ngMessages', 'angularMoment', 'ngTable', 'gridster', 'toastr', 'colorpicker.module', 'bw.paging',
    consent
])
    .config(routeConfig)
    .constant('APP_CONFIG', smartAdminConfig)
    .run(function($rootScope, $location, Auth, $state, $stateParams, $trace) {
        'ngInject';
        // Redirect to login if route requires auth and you're not logged in

        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

    // $trace.enable('TRANSITION');
    // $rootScope.$on('$stateChangeStart', function(event, next) {
    //   Auth.isLoggedIn(function(loggedIn) {
    //     if(next.authenticate && !loggedIn) {
    //       $location.path('/login');
    //     }
    //   });
    // });
    });

angular.element(document)
    .ready(() => {
        angular.bootstrap(document, ['insideInfoApp'], {
            strictDi: true
        });
    });

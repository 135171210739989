'use strict';

angular.module('SmartAdmin.Layout').directive('dismisser', function() {
    return {
        restrict: 'A',
        compile(element) {
            element.removeAttr('dismisser data-dissmiser');
            var closer = '<button class="close">&times;</button>';
            element.prepend(closer);
            element.on('click', '>button.close', function() {
                element.fadeOut('fast', function() {
                    $(this).remove();
                });
            });
        }
    };
});


export class UsersImportModalController {
    /*@ngInject*/
    constructor($stateParams, Restangular, toastr, Auth, $http, $uibModal) {
        this.$stateParams = $stateParams;
        this.Restangular = Restangular;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$uibModal = $uibModal;
        this.users = [];
        this.userIds = [];
    }

    $onInit() {
        this.settings = this.resolve.settings;
        this.$uibModalInstance = this.modalInstance;

        this.currentUser = this.Auth.getCurrentUserSync();

        this.$http.get(`/api/users/importable`).then((response) => {
            this.users = response.data;
        }).catch((err) => {
            console.error(err);
        });

    }

    // Setup all the models for display
    // Modal accept: send resulting object back to promise in parent controller
    ok() {
        this.$http.post(`/api/users/importUsers`, {userIds: this.userIds}).then((result) => {
            this.$uibModalInstance.close(result);
        }).catch((err) => {
            this.toastr.error('Users import failed')
        });;
    }

    // Modal cancelled: either cancel pressed or clicked off screen
    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }

}

export default angular.module('insideInfoApp.users')
    .component('usersImportModal', {
        template: require('./users.import.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        },
        controller: UsersImportModalController,
        controllerAs: '$ctrl'
    })
    .name;
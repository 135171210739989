export class ClassifyEventModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	availableGroups;
	contact;
	Contacts;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular) {
	    this.$stateParams = $stateParams;
	    this.Restangular = Restangular;
	}

	$onInit() {
	    let self = this;
	    this.settings = this.resolve.settings;
	    this.$uibModalInstance = this.modalInstance;
	    // Add alert data to controller
	    let item = this.settings.item;
	    this.event = this.Restangular.copy(item);
	    this.availableClasses = [
	        {
	            class: 0,
	            description: 'Unknown'
	        },
	        {
	            class: 1,
	            description: 'Battery Drained'
	        },
	        {
	            class: 2,
	            description: 'Possible Bad Battery'
	        },
	        {
	            class: 3,
	            description: 'Possible Connection Issues'
	        },
	        {
	            class: 4,
	            description: 'Battery Not Charging'
	        },
	        {
	            class: 5,
	            description: 'Testing (Mains > 20V)'
	        },
	        {
	            class: 6,
	            description: 'Battery & Mains Undetected'
	        },
	        {
	            class: 7,
	            description: 'No Data'
	        },
	    ];
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
	    let self = this;
	    let result = self.event;
	    result.metaData.oldClass = result.metaData.class;
	    result.metaData.oldDescription = result.metaData.description;
	    result.metaData.description = self.newClass.description;
	    result.metaData.class = self.newClass.class;
	    self.$uibModalInstance.close(result);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
	}
}

export default angular.module('insideInfoApp.events')
    .component('classifyeventmodal', {
        template: require('./classify-event.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        },
        controller: ClassifyEventModalController,
        controllerAs: '$ctrl'
    })
    .name;

export class UnitsClientComponent {
  $stateParams;
  Restangular;
  moment;
  socket;
  baseUnit;
  baseClients;
  clients;

  // refreshClients : Function;
  // onClientChanged : Function;


  /*@ngInject*/
  constructor($stateParams, Restangular, moment, socket) {
      let self = this;
      self.$stateParams = $stateParams;
      self.Restangular = Restangular;
      self.moment = moment;
      self.socket = socket;

      self.baseUnit = self.Restangular.one('units', self.$stateParams.unitID);

      self.baseUnit.get().then(function(unit) {
          self.clients = unit.clients;
          self.baseClients = self.baseUnit.all('clients');
      });
  }

  refreshClients() {
	  let self = this;
	  self.baseUnit.get().then(function(unit) {
		  self.clients = unit.clients;
	  });
  }

  onClientChanged(client) {
	  let self = this;
	  client.lastActivity = self.moment.utc().toDate();
	  self.baseClients.customPUT(client).then(function() {
		  self.refreshClients();
	  });
  }

  onClientRemoved(client) {
	  let self = this;
	  self.baseClients.one(client.mac).remove()
          .then(function() {
		  self.refreshClients();
	  });
  }
}


export default angular.module('insideInfoApp.units')
    .component('unitclients', {
        template: require('./units.detail.clients.html'),
        controller: UnitsClientComponent,
        controllerAs: '$ctrl'
    })
    .name;

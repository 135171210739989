'use strict';

angular.module('SmartAdmin.Layout').factory('lazyScript', function($q, $http) {
    var cache = {};

    function isPending(scriptName) {
        return cache.hasOwnProperty(scriptName) && cache[scriptName].promise && cache[scriptName].promise.$$state.pending;
    }

    function isRegistered(scriptName) {
        return cache.hasOwnProperty(scriptName);
    }
    function loadScript(scriptName) {
        if(!cache[scriptName]) {
            cache[scriptName] = $q.defer();
            var el = document.createElement('script');
            el.onload = function(script) {
                cache[scriptName].resolve(scriptName);
            };
            el.src = scriptName;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(el, x);
        }
        return cache[scriptName].promise;
    }

    function register(scriptName) {
        if(isPending(scriptName)) {
            return cache[scriptName].promise;
        }
        if(isRegistered(scriptName)) {
            return $q.resolve(scriptName);
        } else {
            var dfd = $q.defer();

            loadScript(scriptName).then(function() {
                dfd.resolve(scriptName);
            });

            return dfd.promise;
        }
    }
    return {
        register(scripts) {
            var dfd = $q.defer();
            var promises = [];
            if(angular.isString(scripts)) {
                scripts = [scripts];
            }

            angular.forEach(scripts, function(script) {
                promises.push(register(script));
            });

            $q.all(promises).then(function(resolves) {
                dfd.resolve(resolves);
            });
            return dfd.promise;
        }
    };
});

export class UnitsDetailComponent {
  $scope;
  Restangular;
  $stateParams;
  $interval;
  $timeout;
  socket;
  unit;
  unitID;
  heartbeats;
  charts = [];
  selectedResolution;
  selectedRange;
  customRange;
  selectedCharts;

  // formatHeartbeat : Function;


  /*@ngInject*/
  constructor($stateParams, $scope, Restangular, socket, moment, Auth, $state) {
      this.$stateParams = $stateParams;
      this.$scope = $scope;
      this.Restangular = Restangular;
      this.socket = socket;
      this.moment = moment;
      this.Auth = Auth;
      this.$state = $state;
  }

  $onInit() {
      let self = this;
      self.availableUnits = [];
      this.Units = this.Restangular.all('units');
      this.unitID = this.$stateParams.unitID;
      if(this.unitID !== 'None') {
          this.Restangular.one('units', this.unitID).get()
              .then(function(unit) {
                  self.unit = unit;
              });
      }

      // this.Restangular.all('heartbeats').getList({
      // 	limit: 20,
      // 	unitID: this.unitID
      // }).then(function(heartbeats) {
      // 	self.heartbeats = heartbeats;
      // 	//self.$stateParams.heartbeats = heartbeats;
      // 	// self.socket.syncUpdates('heartbeat', self.heartbeats);
      // });

      // this.socket.joinRoom('accounts/' + this.$stateParams.accountID + '/units/' + this.$stateParams.unitID);
      // this.socket.joinRoom(`${this.$stateParams.accountID}:${this.$stateParams.unitID}:*:heartbeats`);
      // this.$scope.$on('$destroy', function() {
      //     self.socket.leaveRoom(`${self.$stateParams.accountID}:${self.$stateParams.unitID}:*:heartbeats`);
      // 	self.socket.leaveRoom('accounts/' + self.$stateParams.accountID + '/units/' + self.$stateParams.unitID);
      // });
      // //TODO save hb rather than getting all
      // this.socket.socket.on('heartbeat:save', function(doc) {
      // 	self.Restangular.all('heartbeats').getList({
      // 		limit: 20,
      // 		unitID: self.unitID
      // 	}).then(function(heartbeats) {
      // 		self.heartbeats = heartbeats;
      // 		self.$stateParams.heartbeats = heartbeats;
      // 		//socket.syncUpdates('heartbeat', $scope.heartbeats);
      // 	});
      // });


      this.selectedResolution = {};
      this.selectedCharts = this.charts;
      this.selectedRange = '';
      this.customRange = {start: this.moment().subtract(1, 'year')
          .toDate(), end: this.moment().toDate()};

      //if(this.$stateParams.selectedResolution === null) {
      //this.$stateParams.selectedResolution = this.selectedResolution;
      //}
      //if(this.$stateParams.selectedCharts === null) {
      //this.$stateParams.selectedCharts = this.selectedCharts;
      //}
      //if(this.$stateParams.selectedRange === null) {
      //this.$stateParams.selectedRange = this.selectedRange;
      //}
      //if(this.$stateParams.customRange === null) {
      //this.$stateParams.customRange = this.customRange;
      //}

      this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;

      this.currentState = this.$state.current;
      // Clean up listeners when the controller is destroyed
      /*$scope.$on('$destroy', function () {
        socket.unsyncUpdates('heartbeat');
    });*/
  }

  formatHeartbeat(hb) {
      return _.omit(hb.plain(), 'accordionOpen');
  }

  refreshUnits(query) {
      let self = this;
      self.Units.getList({
          filter: query ? query : undefined,
          limit: 20,
      }).then(function(units) {
          self.availableUnits = units;
      })
          .catch(err => {
              console.error('Error caught when getting data for units: ', err.data.err);
          });
  }

  reloadUnit() {
      let self = this;
      self.unitID = self.unit._id;
      self.$state.go('.', {unitID: self.unitID}, {notify: false});
      self.$scope.$broadcast('refreshCharts');
  }
}

export default angular.module('insideInfoApp.units')
    .component('unitdetail', {
        template: require('./units.detail.html'),
        controller: UnitsDetailComponent,
        controllerAs: '$ctrl'
    })
    .name;

'use strict';
// @flow


export class UserSettingsComponent {
  Auth;

  /*@ngInject*/
  constructor(Auth) {
      this.Auth = Auth;
  }

  $onInit() {

  }
}

export default angular.module('insideInfoApp.settings')
    .component('usersettings', {
        template: require('./settings.user.html'),
        controller: UserSettingsComponent,
        controllerAs: '$ctrl'
    })
    .name;

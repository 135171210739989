export class GroupUnitModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	availableUnits;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular) {
	    this.$stateParams = $stateParams;
	    this.Restangular = Restangular;
	}

	$onInit() {
	    let self = this;
	    this.settings = this.resolve.settings;
	    this.$uibModalInstance = this.modalInstance;
	    this.Units = this.Restangular.all('units');
	    if(self.settings.edit) {
	        self.group = self.settings.item;
	    }
	}
	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
	    let self = this;
	    let promises = [];
	    self.selectedUnits.forEach(unit => {
	        unit.groups.push(this.group);
	        unit.groups = _.uniq(unit.groups);
	        promises.push(unit.patch());
	    });
	    Promise.all(promises).then(() => {
	        this.$uibModalInstance.close(this.group);
	    });
	}

	refreshUnits(query) {
	    let self = this;
	    self.Units.getList({
	        filter: query ? query : undefined,
	        limit: 20,
	    }).then(function(units) {
	        self.availableUnits = units;
	    })
	        .catch(err => {
	            console.error('Error caught when getting data for units: ', err.data.err);
	        });
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
	}
}

export default angular.module('insideInfoApp.groups')
    .component('groupunitmodal', {
        template: require('./groups.unit.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        },
        controller: GroupUnitModalController,
        controllerAs: '$ctrl'
    })
    .name;
// .controller(ContactModalController).name;

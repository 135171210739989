export class AlertsActiveComponent {
  Restangular;
  socket;
  $stateParams;
  $scope;
  alerts = [];
  filterToOpened = false;
  filterFromOpened = false;
  baseAlerts;
  Auth;

  /*@ngInject*/
  constructor(Restangular, socket, $stateParams, $scope, Auth) {
      this.Auth = Auth;
      this.Restangular = Restangular;
      this.socket = socket;
      this.$stateParams = $stateParams;
      this.$scope = $scope;
  }

  $onInit() {
      let self = this;
      this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref; //temporarily define an account
      this.baseAlerts = this.Restangular.all('alerts');
      this.baseAlerts.getList({active: true, accountID: self.$stateParams.accountID}).then(alerts => {
          self.alerts = alerts;
      });

      this.popOverTextStart = 'Search for alerts that were triggered after this date';
      this.popOverTextEnd = 'Search for alerts that were triggered before this date';

      this.socket.joinRoom(`${this.$stateParams.accountID}:*:alerts`);

      this.$scope.$on('$destroy', function() {
          self.socket.leaveRoom(`${self.$stateParams.accountID}:*:alerts`);
      });

      this.socket.socket.on('alert:save', function(doc) {
  		self.reloader();
  	});
  }

  reloader() {
      this.baseAlerts.getList({active: true, accountID: this.$stateParams.accountID}).then(function(alerts) {
          self.alerts = alerts;
      });
  }

  openTo($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.filterToOpened = true;
  }

  openFrom($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.filterFromOpened = true;
  }

  activeAlerts = function() {
      return _.filter(this.alerts, {waiting: false}).length;
  }
}

export default angular.module('insideInfoApp.alerts')
    .component('alertsactive', {
        template: require('./alerts.active.html'),
        controller: AlertsActiveComponent,
        controllerAs: '$ctrl',
    })
    .name;

import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routes from './reset.routes';

export default angular.module('insideInfoApp.reset', [uiRouter])
    .config(routes)
    .name;

require('./reset.password.component');
require('./reset.password.request.component');

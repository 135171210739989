export class GroupUserComponent {
    /*@ngInject*/
    constructor() {


        //constructor end
    }
}


export default angular.module('insideInfoApp.groups')
    .component('groupusers', {
        template: require('./groups.users.html'),
        controller: GroupUserComponent,
        controllerAs: '$ctrl'
    })
    .name;

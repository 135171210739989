export class GroupUnitComponent {
	Auth;
	$stateParams;
	Restangular;
	NgTableParams;
	$uibModal;
	$log;
	filter;
	groups;
	selectedGroup;
	Contacts;
	Groups;

	/*@ngInject*/
	constructor(Auth, $stateParams, Restangular, NgTableParams, $uibModal, $log) {
	    this.Auth = Auth;
	    this.$stateParams = $stateParams;
	    this.Restangular = Restangular;
	    this.NgTableParams = NgTableParams;
	    this.$uibModal = $uibModal;
	    this.$log = $log;
	    this.getCurrentUser = Auth.getCurrentUserSync;
	}

	$onInit() {
	    let self = this;
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
	    this.filter = '';
	    this.Units = this.Restangular.all('units');
	    this.Groups = this.Units.all('groups');

	    this.groups = [];
	    this.selectedGroup = '';

	    this.Groups.getList({
	        accountID: this.$stateParams.accountID
	    }).then(function(groups) {
	        self.groups = groups;
	        self.selectedGroup = self.groups[0];
	        self.applyFilter();
	    });


	    this.tableParams = new this.NgTableParams({
	        page: 1, // start with first page
	        count: 10, // count per page
	        sorting: {
	            name: 'asc' // initial sorting
	        }
	    }, {
	        total: 0,
	        getData(params) {
	            let order;
	            if(params && params.sorting) {
	                order = params.sorting();
	                return self.Units.getList({
	                    accountID: self.$stateParams.accountID,
	                    query: self.selectedGroup || ' ',
	                    field: 'groups',
	                    skip: (params.page() - 1) * params.count(),
	                    limit: params.count(),
	                    by: Object.keys(order)[0],
	                    order: order[Object.keys(order)[0]]
	                }).then(function(units) {
	                    self.units = units;
	                    self.total = units.total;
	                    params.total(units.total);
	                    return units;
	                });
	            }
	        }
	    });
	}

	onGroupSelected(item, model) {
	    this.applyFilter();
	}

	applyFilter() {
	    this.tableParams.page(1);
	    this.tableParams.reload();
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openCreateUnitGroup() {
	    let self = this;
	    let modalInstance = this.$uibModal.open({
	        component: 'groupunitmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: false,
	                    create: true,
	                    account: {
	                        ref: self.$stateParams.accountID
	                    }
	                };
	            }
	        }
	    });


	    modalInstance.result.then(function(group) {
	        self.Groups.getList({
	            accountID: self.$stateParams.accountID
	        }).then(function(groups) {
	            self.groups = groups;
	            let index = _.findIndex(self.groups, o => o == group);
	            if(index >= 0) {
	                self.selectedGroup = self.groups[index];
	            } else {
	                self.selectedGroup = self.groups[0];
	            }
	            self.applyFilter();
	        });
	        // self.Contacts.post(result, {
	        // 	accountID: self.$stateParams.accountID
	        // }).then(function() {
	        // 	self.tableParams.reload();
	        // });
	    });
	}

	openAddUnitToGroup() {
	    let self = this;
	    let modalInstance = this.$uibModal.open({
	        component: 'groupunitmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: true,
	                    create: false,
	                    item: self.selectedGroup,
	                    account: {
	                        ref: self.$stateParams.accountID
	                    }
	                };
	            }
	        }
	    });

	    modalInstance.result.then(function(group) {
	        self.Groups.getList({
	            accountID: self.$stateParams.accountID
	        }).then(function(groups) {
	            self.groups = groups;
	            let index = _.findIndex(self.groups, o => o == group);
	            if(index >= 0) {
	                self.selectedGroup = self.groups[index];
	            } else {
	                self.selectedGroup = self.groups[0];
	            }
	            self.applyFilter();
	        });
	    });
	}

	onRemoveFromGroup(unit) {
	    let self = this;
	    _.pull(unit.groups, self.selectedGroup);
	    let tempUnit = _.omit(unit, ['account', 'active', 'clients', 'connID', 'cref', 'imei', 'lastHB', 'name', 'type', '__v']);
	    tempUnit.patch().then(() => {
	        self.Groups.getList({
	            accountID: self.$stateParams.accountID
	        }).then(function(groups) {
	            self.groups = groups;
	            let index = _.findIndex(self.groups, obj => obj == self.selectedGroup);
	            if(index >= 0) {
	                self.selectedGroup = self.groups[index];
	            } else {
	                self.selectedGroup = self.groups[0];
	            }
	            self.applyFilter();
	        });
	    });
	}
}


export default angular.module('insideInfoApp.groups')
    .component('groupunits', {
        template: require('./groups.units.html'),
        controller: GroupUnitComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class AlertsModalComponent {
	$sce;
	Restangular;
	$stateParams;
	$uibModalInstance;
	settings;
	Groups;
	Contacts;
	availableGroups;
	availableContacts;
	targets = [];
	target;
	targetType;
	conditionMetric;
	threshold;
	thresholdType;
	thresholdUnitType;
	considerOnly;
	ifPersistingFor;
	persisting;
	targetFilters = [];
	comparisonTypes = [];
	notifyTargets = [];
	notifyTargetTypes = [];
	notifyBackoffTypes = [];
	selectedConditionMetric;
	tooltipBackoffHTML;
	modalInstance;
	Auth;

	/*@ngInject*/
	constructor($sce, Restangular, $stateParams, Auth) {
	    this.Auth = Auth;
	    this.$sce = $sce;
	    this.Restangular = Restangular;
	    this.$stateParams = $stateParams;
	}

	$onInit() {
	    let self = this;
	    this.currentUser = this.Auth.getCurrentUserSync();
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
	    this.$uibModalInstance = this.modalInstance;
	    this.settings = this.resolve.settings;
	    this.Contacts = this.Restangular.all('contacts');
	    this.Groups = this.Contacts.all('groups');
	    this.availableGroups = [];
	    this.availableContacts = [];

	    // Setup all the models for display
	    // TODO maybe fetch these settings from server?
	    let standardMetricThresholdTypes = {
	        standard: ['above', 'below'],
	        data: ['above']
	    };

	    let standardMetricThresholdUnits = {
	        standard: ['in 5 minutes', 'in 1 hour', 'in 1 day', 'in 1 month'],
	        data: ['KiB', 'MiB', 'GiB'],
	        other: ['times'],
	        currency: ['R']
	    };
	    this.standardMetricThresholdDurations = ['in 5 minutes', 'in 1 hour', 'in 1 day', 'in 1 month'];

	    this.targets = [{
	        name: 'Unit',
	        types: [{
	            name: 'Any'
	        },
	        {
	            name: 'Router'
	        }
	        ],
	        metrics: [{
	            name: 'Delta Data Usage',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage In',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage Out',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 1',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 2',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 1 In',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 2 In',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 1 Out',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Delta Data Usage SIM 2 Out',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.data,
	            type: 'Metric'
	        },
	        {
	            name: 'Available Data SIM 1',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.other,
	            type: 'Metric'
	        },
	        {
	            name: 'Available Data SIM 2',
	            thresholds: standardMetricThresholdTypes.data,
	            units: standardMetricThresholdUnits.other,
	            type: 'Metric'
	        },
	        {
	            name: 'Available Airtime SIM 1',
	            thresholds: standardMetricThresholdTypes.standard,
	            units: standardMetricThresholdUnits.currency,
	            type: 'Metric'
	        },
	        {
	            name: 'Available Airtime SIM 2',
	            thresholds: standardMetricThresholdTypes.standard,
	            units: standardMetricThresholdUnits.currency,
	            type: 'Metric'
	        },
	        // {
	        // 	name: 'Data Usage',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage SIM1',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage SIM2',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage In',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage In SIM1',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage In SIM2',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage Out',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage Out SIM1',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Data Usage Out SIM2',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.data,
	        // 	type: 'Metric'
	        // },
	        {
	            name: 'SIM Swaps',
	            thresholds: standardMetricThresholdTypes.standard,
	            units: standardMetricThresholdUnits.other,
	            type: 'Metric'
	        },
	        // {
	        // 	name: 'Mains',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Battery',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'RSSI',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'BER',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        //{
	        //name: 'Reboots',
	        //thresholds: standardMetricThresholdTypes.standard,
	        //units: standardMetricThresholdUnits.other,
	        //type: 'Metric'
	        //},
	        // {
	        // 	name: 'Clients',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        // {
	        // 	name: 'Uptime',
	        // 	thresholds: standardMetricThresholdTypes.standard,
	        // 	units: standardMetricThresholdUnits.standard,
	        // 	type: 'Metric'
	        // },
	        {
	            name: 'Is Not Communicating',
	            type: 'Condition'
	        },
	        {
	            name: 'Is On Battery',
	            type: 'Condition'
	        },
	        {
	            name: 'Device Disconnected',
	            type: 'Condition'
	        },
	        ],
	        properties: [{
	            name: 'Name'
	        },
	        {
	            name: 'Groups'
	        },
	        ]
	    }, {
	        name: 'Account',
	        metrics: [
	            //{
	            //name: 'Reboots',
	            //thresholds: standardMetricThresholdTypes.standard,
	            //units: standardMetricThresholdUnits.other,
	            //type: 'Metric'
	            //},
	            {
	                name: 'SIM Swaps',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            }, {
	                name: 'Delta Data Usage',
	                thresholds: standardMetricThresholdTypes.data,
	                units: standardMetricThresholdUnits.data,
	                type: 'Metric'
	            }, {
	                name: 'Delta Data Usage In',
	                thresholds: standardMetricThresholdTypes.data,
	                units: standardMetricThresholdUnits.data,
	                type: 'Metric'
	            }, {
	                name: 'Delta Data Usage Out',
	                thresholds: standardMetricThresholdTypes.data,
	                units: standardMetricThresholdUnits.data,
	                type: 'Metric'
	            }, {
	                name: 'Online Units',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            },
	            //{
	            //name: 'Units VMP',
	            //thresholds: standardMetricThresholdTypes.standard,
	            //units: standardMetricThresholdUnits.other,
	            //type: 'Metric'
	            //},
	            {
	                name: 'Units NTP',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            }, {
	                name: 'Units Battery',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            }, {
	                name: 'Units Mains',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            }, {
	                name: 'Units WAN',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            }]
	    }];

	    if(this.currentUser.role == 'admin') {
	        this.targets[0].metrics.push({
	            name: 'Reboots',
	            thresholds: standardMetricThresholdTypes.standard,
	            units: standardMetricThresholdUnits.other,
	            type: 'Metric'
	        });
	        this.targets[1].metrics.push(
	            {
	                name: 'Reboots',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            });
	        this.targets[1].metrics.push(
	            {
	                name: 'Units VMP',
	                thresholds: standardMetricThresholdTypes.standard,
	                units: standardMetricThresholdUnits.other,
	                type: 'Metric'
	            });
	    }

	    this.target = this.targets[0];
	    this.targetType = this.target.types[0];
	    this.conditionMetric = this.target.metrics[0];
	    this.thresholdType = this.conditionMetric.thresholds[0] || undefined;
	    this.threshold = 0;
	    this.thresholdUnitType = this.conditionMetric.units[0] || undefined;
	    this.considerOnly = false;
	    this.ifPersistingFor = false;
	    this.persisting = 0;
	    this.comparisonTypes = ['is', 'is not', 'contains', 'does not contain', 'starts with', 'ends with', 'matches'];

	    // TODO
	    this.notifyTargetTypes = [
	        'Contact',
	        'Group',
	        'Email'
	    ];

	    this.notifyBackoffTypes = [
	        'Linear',
	        'Exponential',
	        'Fibonacci'
	    ];

	    // Add alert data to controller
	    if(this.settings.item) {
	        let item = this.settings.item;

	        this.target = _.filter(this.targets, {
	            name: item.target.name
	        })[0];
	        this.targetType = _.filter(this.target.types, {
	            name: item.target.kind
	        })[0];
	        if(item.metric) {
	            this.conditionMetric = _.filter(this.target.metrics, {
	                name: item.metric.name
	            })[0];
	            this.thresholdUnitType = item.metric.mode;
	            this.thresholdDuration = item.metric.duration;
	            this.thresholdType = item.metric.test;
	            this.threshold = item.metric.params;
	        } else {
	            this.conditionMetric = _.filter(this.target.metrics, {
	                name: item.condition.name
	            })[0];
	        }
	        if(item.filter && item.filter.length > 0) {
	            this.targetFilters = [];
	            item.filter.forEach(function(filter) {
	                self.targetFilters.push({
	                    property: {
	                        name: filter.field
	                    },
	                    comparison: filter.test,
	                    filter: filter.value
	                });
	            });
	            this.considerOnly = this.targetFilters.length > 0;
	        }
	        if(item.notify && item.notify.length > 0) {
	            this.notifyTargets = [];
	            item.notify.forEach(function(notify) {
	                let backoffType;
	                if(notify.repeat.kind === 'linear') {
	                    backoffType = self.notifyBackoffTypes[0];
	                } else if(notify.repeat.kind === 'exponential') {
	                    backoffType = self.notifyBackoffTypes[1];
	                } else {
	                    backoffType = self.notifyBackoffTypes[2];
	                }
	                let newNotify = {
	                    delay: notify.delay,
	                    backoffType,
	                    resendInterval: notify.repeat.period,
	                    type: notify.type,
	                    onlyOn: notify.onlyOn || 'Open',
	                    repeatNotification: notify.repeatNotification || false,
	                };

	                switch (notify.type) {
	                case 'Email':
	                    newNotify.email = notify.to;
	                    break;
	                case 'Contact':
	                    newNotify.contact = {};
	                    newNotify.contact._id = notify.contact;
	                    newNotify.contact.firstname = notify.firstname;
	                    newNotify.contact.lastname = notify.lastname;
	                    newNotify.contact.email = notify.email;
	                    newNotify.contact.phone = notify.phone;
	                    break;
	                case 'Group':
	                    newNotify.group = notify.group;
	                    break;
	                }

	                self.notifyTargets.push(newNotify);
	            });
	        }

	        this.selectedConditionMetric = this.conditionMetric; //this was declared above 'onConditionMetricSelected' function


	        if(item.persisting) {
	            this.ifPersistingFor = true;
	            this.persisting = item.persisting;
	        }
	    } else {
	        this.targetType = this.target.types[0] || undefined;
	        this.selectedConditionMetric = this.target.metrics[0] || undefined;
	        this.thresholdType = this.selectedConditionMetric.thresholds[0] || undefined;
	        this.thresholdUnitType = this.selectedConditionMetric.units[0] || undefined;
	        this.thresholdDuration = this.standardMetricThresholdDurations[2] || undefined;
	    }

	    this.tooltipBackoffHTML = this.$sce.trustAsHtml('<p><b>Linear backoff</b> will resend notifications every repeat rate interval.</p><p><strong>Exponential backoff</strong> will resend each notification with an incremental delay, following an exponential curve.</p><p><strong>Fibonnaci backoff</strong> resends each notification with an incremental delay, following the fibonacci sequence.</p>');
	}

	onAddNotification(notifyTargetType) {
	    this.notifyTargets.push({
	        type: notifyTargetType,
	        delay: 0,
	        backoffType: 'Linear',
	        resendInterval: 5,
	        onlyOn: 'Open',
	        repeatNotification: false,
	    });
	}

	onRemoveNotification(index) {
	    this.notifyTargets.splice(index, 1);
	}

	onConsiderChecked() {
	    if(this.considerOnly && this.targetFilters.length === 0) {
	        this.targetFilters.push({
	            property: this.target.properties[0],
	            comparison: this.comparisonTypes[0]
	        });
	    }
	}

	onFilterAdded() {
	    this.targetFilters.push({
	        property: this.target.properties[0],
	        comparison: this.comparisonTypes[0]
	    });
	}

	onFilterRemoved(index) {
	    this.targetFilters.splice(index, 1);
	    if(this.targetFilters.length === 0) {
	        this.considerOnly = false;
	    }
	}

	onTargetSelected(item) {
	    switch (item.name) {
	    case 'Unit':
	        {
	            this.targetType = this.target.types[0] || undefined;
	            this.selectedConditionMetric = this.target.metrics[0] || undefined;
	            this.thresholdType = this.selectedConditionMetric.thresholds[0] || undefined;
	            this.thresholdUnitType = this.selectedConditionMetric.units[0] || undefined;
	            this.thresholdDuration = this.standardMetricThresholdDurations[2] || undefined;
	        }
	        break;
	    default:
	        this.targetType = {};
	        break;
	    }
	}

	// TODO figure this out...
	onConditionMetricSelected(item) {
	    this.selectedConditionMetric = item;
	    if(this.selectedConditionMetric.thresholds) {
	        this.thresholdType = this.selectedConditionMetric.thresholds[0] || undefined;
	    }
	    if(this.selectedConditionMetric.units) {
	        this.thresholdUnitType = this.selectedConditionMetric.units[0] || undefined;
	    }
	}

	// Modal accept: send resulting object back to promise in parent controller
	ok() {
	    var result = {
	        target: {
	            name: this.target.name,
	            kind: this.targetType.name
	        },
	    };

	    if(this.conditionMetric.thresholds || this.conditionMetric.units) {
	        result.metric = {
	            name: this.conditionMetric.name,
	            mode: this.thresholdUnitType,
	            test: this.thresholdType,
	            params: this.threshold,
	            duration: this.thresholdDuration,
	        };
	    } else {
	        result.condition = {
	            name: this.conditionMetric.name
	        };
	    }

	    if(this.targetFilters.length > 0) {
	        result.filter = [];
	        this.targetFilters.forEach(function(filter) {
	            result.filter.push({
	                field: filter.property.name,
	                test: filter.comparison,
	                value: filter.filter
	            });
	        });
	    }

	    if(this.notifyTargets.length > 0) {
	        result.notify = [];
	        this.notifyTargets.forEach(function(notify) {
	            var backoffType = notify.backoffType.toLowerCase();

	            var notifyInvalid = false;
	            if(backoffType.indexOf('linear') !== -1) {
	                backoffType = 'linear';
	            } else if(backoffType.indexOf('exponential') !== -1) {
	                backoffType = 'exponential';
	            } else if(backoffType.indexOf('fibonacci') !== -1) {
	                backoffType = 'fibonacci';
	            }
	            var notifyTarget = {
	                delay: notify.delay,
	                repeat: {
	                    kind: backoffType,
	                    period: notify.resendInterval
	                },
	                type: notify.type,
	                onlyOn: notify.onlyOn || 'Open',
	                repeatNotification: notify.repeatNotification || false,
	            };

	            switch (notify.type) {
	            case 'Email':
	                if(!notify.email) {
	                    notifyInvalid = true;
	                    break;
	                }
	                notifyTarget.to = notify.email;
	                break;
	            case 'Contact':
	                if(!notify.contact) {
	                    notifyInvalid = true;
	                    break;
	                }
	                notifyTarget.contact = notify.contact._id;
	                notifyTarget.firstname = notify.contact.firstname;
	                notifyTarget.lastname = notify.contact.lastname;
	                notifyTarget.email = notify.contact.email;
	                notifyTarget.phone = notify.contact.phone;
	                break;
	            case 'Group':
	                if(!notify.group) {
	                    notifyInvalid = true;
	                    break;
	                }
	                notifyTarget.group = notify.group;
	                break;
	            }

	            if(!notifyInvalid) {
	                result.notify.push(notifyTarget);
	            }
	        });
	    }

	    if(this.ifPersistingFor) {
	        result.persisting = this.persisting;
	    }
	    this.$uibModalInstance.close(result);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
	}


	refreshGroups() {
	    let self = this;
	    Groups.getList({
	        accountID: this.$stateParams.accountID
	    }).then(function(groups) {
	        self.availableGroups = groups;
	    });
	}

	refreshContacts() {
	    let self = this;
	    this.Contacts.getList({
	        accountID: this.$stateParams.accountID
	    }).then(function(contacts) {
	        self.availableContacts = contacts;
	    });
	}
}
export default angular.module('insideInfoApp.alerts')
    .component('alertsmodals', {
        template: require('./alerts.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<',
            settings: '<'
        },
        controller: AlertsModalComponent,
        controllerAs: '$ctrl'
    })
    .name;

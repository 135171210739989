import { v1 as uuidv1 } from 'uuid';

export class DashboardComponent {
	Restangular;
	$stateParams;
	$scope;
	Auth;
	$uibModal;
	toastr;
	moment;


	/*@ngInject*/
	constructor(Restangular, $stateParams, $scope, Auth, $uibModal, toastr, moment, $state) {
	    this.Restangular = Restangular;
	    this.$stateParams = $stateParams;
	    this.charts = {};
	    this.account = Auth.getCurrentAccountSync;
	    this.$scope = $scope;
	    this.Auth = Auth;
	    this.$uibModal = $uibModal;
	    this.toastr = toastr;
	    this.moment = moment;
	    this.$state = $state;
	    this.availableRanges = [
	        { id: 'last24h', alias: 'Last 24 Hours' },
	        { id: 'last7d', alias: 'Last 7 Days' },
	        { id: 'last30d', alias: 'Last 30 Days' },
	        { id: 'lastYear', alias: 'Last Year' },
	        { id: 'thisDay', alias: 'Today' },
	        { id: 'thisWeek', alias: 'This Week' },
	        { id: 'thisMonth', alias: 'This Month' },
	        { id: 'thisYear', alias: 'This Year' },
	    ];

	    //constructor end
	}

	$onDestroy() {
	    //TODO: Add button to allow saving dashboard
	    let self = this;
	    if(self.dashboardChanged) {
	        self.toastr.warning('Click here to save your dashboard', 'Save Dashboard', {
	            onTap(currentToastr) {
	                self.saveDashboard();
	            },
	            closeButton: true,
	        });
	    }
	    _.forEach(self.charts, c => {
	        c = c.destroy();
	    });
	}

	$onInit() {
	    let self = this;
	    self.dashboardChanged = false;
	    this.Auth.getCurrentUser().then(user => {
	        if(self.account()) {
	            self.accountID = self.account().ref;
	            self.currentUser = user;

	            self.gridsterOpts = {
	                columns: 6, // the width of the grid, in columns
	                pushing: true, // whether to push other items out of the way on move or resize
	                floating: true, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
	                swapping: true, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
	                width: 'auto', // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
	                colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
	                rowHeight: 'match', // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
	                margins: [10, 10], // the pixel distance between each widget
	                outerMargin: true, // whether margins apply to outer edges of the grid
	                sparse: false, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
	                isMobile: false, // stacks the grid items if true
	                mobileBreakPoint: 600, // if the screen is not wider that this, remove the grid layout and stack the items
	                mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
	                minColumns: 1, // the minimum columns the grid must have
	                minRows: 2, // the minimum height of the grid, in rows
	                maxRows: 100,
	                defaultSizeX: 2, // the default width of a gridster item, if not specifed
	                defaultSizeY: 1, // the default height of a gridster item, if not specified
	                minSizeX: 1, // minimum column width of an item
	                maxSizeX: null, // maximum column width of an item
	                minSizeY: 1, // minumum row height of an item
	                maxSizeY: null, // maximum row height of an item
	                resizable: {
	                    enabled: true,
	                    handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
	                    start(event, $element, widget) { }, // optional callback fired when resize is started,
	                    resize(event, $element, widget) {
	                        if(widget.hasData) {
	                            let chartEl = $(`#${widget._id}`)[0];
	                            if(chartEl && chartEl.parentElement && chartEl.parentElement.parentElement) {
	                                let box = chartEl.parentElement.parentElement;
	                                self.charts[widget._id].resize({ height: box.clientHeight - 60, width: box.clientWidth - 20 });
	                                self.charts[widget._id].flush();
	                            }
	                        }
	                    }, // optional callback fired when item is resized,
	                    stop(event, $element, widget) {
	                        if(widget.hasData) {
	                            setTimeout(() => {
	                                let chartEl = $(`#${widget._id}`)[0];
	                                if(chartEl && chartEl.parentElement && chartEl.parentElement.parentElement) {
	                                    let box = chartEl.parentElement.parentElement;
	                                    self.charts[widget._id].resize({ height: box.clientHeight - 60, width: box.clientWidth - 20 });
	                                    self.charts[widget._id].flush();
	                                }
	                            }, 400);
	                        }
	                    } // optional callback fired when item is finished resizing
	                },
	                draggable: {
	                    enabled: true, // whether dragging items is supported
	                    handle: 'h3', // optional selector for drag handle
	                    start(event, $element, widget) { }, // optional callback fired when drag is started,
	                    drag(event, $element, widget) { }, // optional callback fired when item is moved,
	                    stop(event, $element, widget) { } // optional callback fired when item is finished dragging
	                }
	            };
	            let restDashboard = self.Restangular.one('dashboards', self.accountID).one('user', self.currentUser._id);
	            restDashboard.getList().then(dashboards => {
	                if(dashboards && dashboards.length > 0) {
	                    self.dashboard = dashboards[0];
	                    self.dashboard.widgets.forEach(widget => {
	                        self.loadWidget(widget);
	                    });
	                } else {
	                    self.dashboard = {
	                        name: 'New Dashboard',
	                        user: self.currentUser._id,
	                        account: self.accountID,
	                        widgets: []
	                    };
	                }
	            });


	            self.debounceUpdate = _.debounce(widget => {
	                self.loadWidget(widget);
	            }, 1000);
	        } else {
	            console.error('No Account Selected');
	        }
	    });
	}

	loadWidget(widget) {
	    let self = this;
	    this.getData(`get${widget.chartId}`, widget).then(results => {
	        if(results.length >= 1) {
	            widget.hasData = true;
	            let data = {
	                columns: [],
	                type: widget.type
	            };
	            let options = { originalData: results };

	            if(widget.dataType == 'date' && !widget.timeDelta) {
	                let now = new self.moment().utc();
	                results.forEach(result => {
	                    result.value = now.diff(self.moment(result.value).utc(), 'seconds');
	                });
	            }
	            switch (widget.dataType) {
	            case 'bytes':
	                options;

	                break;
	            default:
	            }
	            if(!options.tooltip) {
	                options.tooltip = {};
	            }
	            if(!options.tooltip.format) {
	                options.tooltip.format = {};
	            }
	            if(widget.type == 'pie') {
	                results.forEach(result => {
	                    data.columns.push([`${result.name} (${result.value})`, result.value]);
	                });
	                options.data = data;
	                options.tooltip.format.title = function(d) {
	                    return d;
	                };
	                options.tooltip.format.name = function(d) {
	                    return d;
	                };
	            } else {
	                if(!options.axis) {
	                    options.axis = {};
	                }
	                if(!options.axis.x) {
	                    options.axis.x = {};
	                }
	                if(!options.axis.y) {
	                    options.axis.y = {};
	                }
	                if(!options.axis.y.tick) {
	                    options.axis.y.tick = {};
	                }
	                if(!options.legend) {
	                    options.legend = {};
	                }
	                options.axis.x.type = 'category';
	                options.axis.x.categories = [];
	                options.axis.y.tick.format = function(d) {
	                    let val = self.formatValue(d, widget.dataType);
	                    return val;
	                };
	                if(widget.dataType == 'bytes') {
	                    let minVal = 1024 * 1024;
	                    options.axis.y.max = results[0].value > minVal ? results[0].value : minVal;
	                } else if(widget.dataType == 'count' && widget.chartId != 'EventsSummary') {
	                    let minVal = 3;
	                    options.axis.y.max = results[0].value > minVal ? results[0].value : minVal;
	                }
	                if(widget.dataType == 'date') {
	                    options.axis.y.tick.count = 1;
	                }
	                if(widget.chartId == 'EventsSummary') {
	                    data = {
	                        json: _.cloneDeep(results),
	                        keys: results.keys,
	                        type: widget.type,
	                        names: results.names,
	                        groups: results.groups
	                    };
	                } else {
	                    data.columns[0] = ['Values'];
	                    results.forEach(result => {
	                        data.columns[0].push(result.value);
	                        options.axis.x.categories.push(result.name);
	                    });
	                }
	                if(widget.rotated) {
	                    options.axis.rotated = true;
	                }
	                if(!options.axis.y.label) {
	                    options.axis.y.label = {};
	                    options.axis.y.label.position = 'outer-middle';
	                }
	                if(!options.axis.x.label) {
	                    options.axis.x.label = {};
	                }
	                options.axis.x.label.text = 'Units';
	                options.axis.x.label.position = 'outer-center';
	                let dataType = '';
	                switch (widget.dataType) {
	                case 'bytes':
	                    dataType = 'Bytes';
	                    break;
	                case 'count':
	                    dataType = 'Amount';
	                    break;
	                case 'date':
	                    dataType = 'Time';
	                    break;
	                case 'mV':
	                    dataType = 'Voltage';
	                    break;
	                case 'currency':
	                    dataType = 'R';
	                    break;
	                default:
	                }
	                options.axis.y.label.text = dataType;
	                if(widget.chartId != 'EventsSummary') {
	                    options.tooltip.format.title = function(d) {
	                        if(d !== undefined) {
	                            return options.axis.x.categories[d];
	                        }
	                        return d;
	                    };
	                    options.tooltip.format.name = function(d) {
	                        return dataType;
	                    };
	                }
	                if(widget.chartId == 'EventsSummary') {
	                    options.legend.hide = false;
	                } else {
	                    options.legend.hide = true;
	                }
	                options.data = data;
	            }

	            if(widget.chartId != 'EventsSummary') {
	                options.tooltip.format.value = function(value, ratio, id, index) {
	                    if(ratio) {
	                        ratio = `${(ratio * 100).toFixed(2)}%`;
	                        ratio = `${ratio} (${value})`;
	                        return ratio;
	                    } else {
	                        let val = self.formatValue(value, widget.dataType);
	                        return val;
	                    }
	                };
	            }
	            options.data.onclick = (d, el) => {
	                if(results[d.index] && results[d.index]._id && results[d.index]._id !== results[d.index].name) {
	                    let url;
	                    if(widget.chartId != 'EventsSummary') {
	                        url = self.$state.href('main.units.detail.charts', { accountID: self.account().ref, unitID: results[d.index]._id });
	                    } else {
	                        url = self.$state.href('main.units.detail.charts', { accountID: self.account().ref, unitID: results[d.index]._id.unit });
	                    }
	                    window.open(url);
	                }
	            };

	            try {
	                if(self.charts[widget._id]) {
	                    self.charts[widget._id] = self.charts[widget._id].destroy();
	                }

	                self.charts[widget._id] = self.createChart(widget._id, options, widget.colours);
	            } catch(e) {
	                console.error(e);
	            }
	            setTimeout(() => {
	                let chartEl = $(`#${widget._id}`)[0];

	                if(widget.type == 'pie') {
	                    $(chartEl).addClass('pie');
	                }
	                if(chartEl && chartEl.parentElement && chartEl.parentElement.parentElement) {
	                    let box = chartEl.parentElement.parentElement;
	                    self.charts[widget._id].resize({ height: box.clientHeight - 60, width: box.clientWidth - 20 });
	                    self.charts[widget._id].flush();
	                }
	            }, 10);
	        } else {
	            widget.hasData = false;
	        }
	    })
	        .catch(err => {
	            console.error(err);
	        });
	}

	addWidget() {
	    let self = this;

	    this.modalInstance = this.$uibModal.open({
	        //template: "<alertsmodals></alertsmodals>",
	        component: 'dashboardmodals',
	        size: 'lg',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: false,
	                    create: true
	                };
	            }
	        }
	    });

	    this.modalInstance.result.then(function(tempResult) {
	        // if(!result._id){
	        // 	result._id = uuidv1();
	        // }
	        //Ensure a letter is first to allow jQuery selections.
	        let result = _.cloneDeep(tempResult);
	        result._id = `a${uuidv1().replace(/-/g, '')}`;
	        self.dashboardChanged = true;
	        self.dashboard.widgets.push(result);
	        self.loadWidget(result);
	    }, function(err) {
	        // ignore error
	    });
	}

	removeWidget(widgetId) {
	    let self = this;
	    let index = self.dashboard.widgets.findIndex(widget => widget._id == widgetId);
	    if(index > -1) {
	        self.dashboardChanged = true;
	        self.dashboard.widgets.splice(index, 1);
	    } else {
	        console.error('No such Widget');
	    }
	}

	getData(route, widget) {
	    let single = widget.single;
	    let last = widget.last;
	    let metric = widget.metric;
	    let limit = widget.limit;
	    let self = this;
	    let addProperties;
	    if(widget.colours && widget.colours.type == 'match') {
	        addProperties = _.map(widget.colours.colourValues, 'property');
	    }
	    if(single) {
	        let data = this.Restangular.all('dashboards').one(route);
	        let query = {
	            accountID: self.accountID
	        };
	        query.sort = widget.sort || -1;
	        if(last) {
	            query.last = last;
	        }
	        if(metric) {
	            query.metric = metric;
	        }
	        if(addProperties) {
	            query.addProperties = addProperties;
	        }
	        return data.get(query).then(function(result) {
	            return result;
	        })
	            .catch(err => {
	                console.error(err);
	                return null;
	            });
	    } else {
	        let data = this.Restangular.all('dashboards').all(route);
	        let query = {
	            accountID: self.accountID
	        };
	        query.sort = widget.sort || -1;
	        if(last) {
	            query.last = last;
	        }
	        if(metric) {
	            query.metric = metric;
	        }
	        if(limit) {
	            query.limit = limit;
	        }
	        if(addProperties) {
	            query.addProperties = addProperties;
	        }
	        return data.getList(query).then(function(result) {
	            if(widget.chartId == 'EventsSummary') {
	                result.names = {};
	                result.keys = { x: '_id.unitname', value: [] };
	                result.groups = [[]];
	                result.forEach(unit => {
	                    unit.eventsObj = {};
	                    unit.events.forEach(event => {
	                        unit.eventsObj[event.predicted] = event;
	                        let key = `eventsObj.${event.predicted}.count`;
	                        result.keys.value.push(key);
	                        result.names[key] = event.description;
	                        result.groups[0].push(key);
	                    });
	                });
	                result.groups[0] = _.uniq(result.groups[0]);
	                result.keys.value = _.uniq(result.keys.value);
	            }
	            return result;
	        })
	            .catch(err => {
	                console.error(err);
	                return null;
	            });
	    }
	}

	createChart(chartId, options, colours) {
	    let defaultOpt = {
	        bindto: `#${chartId}`,
	        onrendered() {
	            $(`#${chartId}`).css('max-height', 'none');
	        }
	    };
	    if(!defaultOpt.tooltip) {
	        defaultOpt.tooltip = {};
	    }
	    let chartData = options.data;
	    if(colours && colours.type == 'range') {
	        let grad = tinyGradient(colours.start, colours.end);
	        let length = 5;
	        if(options.data.type == 'pie') {
	            length = options.data.columns.length;
	        } else {
	            length = options.data.columns[0].length - 1;
	        }
	        let colourRange;
	        if(length < 2) {
	            length = 2;
	        }
	        switch (colours.gradient) {
	        case 'rgb':
	            colourRange = grad.rgb(length);

	            break;
	        case 'hsv':
	            colourRange = grad.hsv(length);
	            break;
	        case 'hsvi':
	            colourRange = grad.hsv(length, true);
	            break;
	        default:
	            colourRange = grad.hvs(length);
	        }

	        defaultOpt.data = {
	            color(color, d) {
	                if(d.index != undefined) {
	                    return `#${colourRange[d.index].toHex()}`;
	                } else if(d.id != undefined) {
	                    let index = _.findIndex(chartData.columns, e => e[0] == d.id);
	                    return `#${colourRange[index].toHex()}`;
	                } else if(chartData.type == 'pie') {
	                    let index = _.findIndex(chartData.columns, e => e[0] == d);
	                    return `#${colourRange[index].toHex()}`;
	                } else {
	                    return color;
	                }
	            }
	        };
	        defaultOpt.tooltip.contents = function(d, defaultTitleFormat, defaultValueFormat, color) {
	            let colorFunction = function() {
	                return `#${colourRange[d[0].index].toHex()}`;
	            };
	            return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, colorFunction);
	        };
	    } else if(colours && colours.type == 'discrete') {
	        defaultOpt.data = {
	            color(color, d) {
	                if(_.find(colours.colourValues, ['id', d])) {
	                    return _.find(colours.colourValues, ['id', d]).colour;
	                } else if(_.find(colours.colourValues, ['id', d.id])) {
	                    return _.find(colours.colourValues, ['id', d.id]).colour;
	                } else if(_.find(colours.colourValues, ['id', d.index])) {
	                    return _.find(colours.colourValues, ['id', d.index]).colour;
	                } else {
	                    return color;
	                }
	            }
	        };
	        defaultOpt.tooltip.contents = function(d, defaultTitleFormat, defaultValueFormat, color) {
	            let colorFunction = function() {
	                let returnVal;
	                d = d[0];
	                if(_.find(colours.colourValues, ['id', d])) {
	                    returnVal = _.find(colours.colourValues, ['id', d]).colour;
	                } else if(_.find(colours.colourValues, ['id', d.id])) {
	                    returnVal = _.find(colours.colourValues, ['id', d.id]).colour;
	                } else if(_.find(colours.colourValues, ['id', d.index])) {
	                    returnVal = _.find(colours.colourValues, ['id', d.index]).colour;
	                }
	                return returnVal;
	            };
	            return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, colorFunction);
	        };
	    } else if(colours && colours.type == 'match') {
	        defaultOpt.data = {
	            color(color, d) {
	                _.some(colours.colourValues, prop => {
	                    let val = _.get(options.originalData[d.index], prop.property);
	                    if(val == prop.value) {
	                        color = prop.colour;
	                        return true;
	                    }
	                });
	                return color;
	            }
	        };
	        defaultOpt.tooltip.contents = function(d, defaultTitleFormat, defaultValueFormat, color) {
	            let colorFunction = function() {
	                d = d[0];
	                _.some(colours.colourValues, prop => {
	                    let val = _.get(options.originalData[d.index], prop.property);
	                    if(val == prop.value) {
	                        color = prop.colour;
	                        return true;
	                    }
	                });
	                if(typeof color == 'function') {
	                    return color(d);
	                } else {
	                    return color;
	                }
	            };
	            return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, colorFunction);
	        };
	    }


	    let newOptions = _.merge(defaultOpt, options);
	    return c3.generate(newOptions);
	}

	changeRange(widget) {
	    this.loadWidget(widget);
	}

	saveDashboard() {
	    let self = this;
	    self.Restangular.one('dashboards', self.dashboard._id || 'new').customPUT(self.dashboard)
	        .then(result => {
	            if(result) {
	                self.dashboard = result;
	                self.dashboardChanged = false;
	                self.dashboard.widgets.forEach(widget => {
	                    self.loadWidget(widget);
	                });
	                self.toastr.success('Your dashboard has been saved!', 'Saved');
	            }
	        });
	}

	editWidget(widget) {
	    let self = this;
	    let categories = {
	        type: '',
	        values: []
	    };
	    if(self.charts[widget._id]) {
	        if(self.charts[widget._id].categories().length > 0) {
	            categories.type = 'category';
	            categories.values = self.charts[widget._id].categories();
	        } else {
	            categories.type = 'discrete';
	            self.charts[widget._id].data().forEach(e => {
	                categories.values.push(e.id);
	            });
	        }
	    }
	    this.modalInstance = this.$uibModal.open({
	        //template: "<alertsmodals></alertsmodals>",
	        component: 'dashboardmodals',
	        size: 'lg',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: true,
	                    create: false
	                };
	            },
	            widget() {
	                return widget;
	            },
	            categories() {
	                return categories;
	            }
	        }
	    });

	    this.modalInstance.result.then(function(result) {
	        try {
	            let index = _.findIndex(self.dashboard.widgets, e => e._id == result._id);
	            self.dashboardChanged = true;
	            self.dashboard.widgets.splice(index, 1, result);
	            self.loadWidget(result);
	        } catch(e) {
	            console.error(e);
	        }
	    }, function(err) {
	        // ignore error
	    });
	}

	changeLimit(widget) {
	    if(widget.limit <= 0) {
	        widget.limit = 1;
	    } else if(widget.limit > 50) {
	        widget.limit = 50;
	    } else if(widget.limit == undefined) {
	        widget.limit = 1;
	    }


	    this.debounceUpdate(widget);
	}

	formatValue(value, type) {
	    let self = this;
	    if(type == 'bytes') {
	        let precision = 1;
	        if(value === 0) {
	            return '0 bytes';
	        }
	        if(isNaN(parseFloat(value)) || !isFinite(value)) return '-';
	        if(typeof precision === 'undefined') precision = 1;

	        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
	        var number = Math.floor(Math.log(value) / Math.log(1024));
	        var val = (value / Math.pow(1024, Math.floor(number))).toFixed(precision);

	        return `${val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val} ${units[number]}`;
	    } else if(type == 'mV') {
	        return `${(value / 1000).toFixed(1)}V`;
	    } else if(type == 'currency') {
	        return `R${value.toFixed(2)}`;
	    } else if(type == 'date') {
	        //let val = self.moment().duration(value * -1, 'seconds').humanize();
	        let secondsIn = value;
	        let oneMinute = 60;
	        let oneHour = 60 * oneMinute;
	        let oneDay = 24 * oneHour;
	        let oneMonth = 30 * oneDay;
	        let oneYear = 12 * oneMonth;

	        let seconds = parseInt(secondsIn, 10);
	        let years = Math.floor(seconds / oneYear);
	        let left = seconds - years * oneYear;
	        let months = Math.floor(left / oneMonth);
	        left = left - months * oneMonth;
	        let days = Math.floor(left / oneDay);
	        left = left - days * oneDay;
	        let hours = Math.floor(left / oneHour);
	        left = left - hours * oneHour;
	        let minutes = Math.floor(left / oneMinute);
	        left = Math.floor(left - minutes * oneMinute);
	        let returnStr = '';
	        let precision = 2;
	        let count = 0;
	        if(years !== 0) {
	            returnStr += `${years} Years `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        if(months !== 0) {
	            returnStr += `${months} Months `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        if(days !== 0) {
	            returnStr += `${days} Days `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        if(hours !== 0) {
	            returnStr += `${hours} Hours `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        if(minutes !== 0) {
	            returnStr += `${minutes} Minutes `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        if(left !== 0) {
	            returnStr += `${left} Seconds `;
	            count++;
	            if(count >= precision) {
	                return returnStr;
	            }
	        }
	        return returnStr;
	    } else {
	        return value;
	    }
	}


	doLog() {
	    console.debug(this);
	}
}


export default angular.module('insideInfoApp.dashboard')
    .component('dashboard', {
        template: require('./dashboard.html'),
        controller: DashboardComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class USSDModalComponent {
    /*@ngInject*/
    constructor(Restangular, Auth, toastr) {
	    this.Auth = Auth;
	    this.Restangular = Restangular;
        this.toastr = toastr;
    }

    $onInit() {
	    let self = this;
	    this.$uibModalInstance = this.modalInstance;

	    this.currentUser = this.Auth.getCurrentUserSync();
        this.unit = this.resolve.unit;
    }

    send() {
        let ussdRequest = {
            ussdCode: this.ussdCode,
            responseCount: 0
        };
        if(this.unit?.ussd?.responseResult === 'AwaitingAnswer') {
            ussdRequest.responseCount = this.unit.ussd.responseCount;
        }
        this.Restangular.one('units', this.unit._id).all('ussd')
            .post(ussdRequest)
            .then(res => {
                this.toastr.info('USSD Code sent');
            })
            .catch(err => {
                console.error(err);
                this.toastr.error(err.data || err, 'USSD Request failed');
            });
    }

    cancelRequest() {
        let ussdRequest = {
            cancel: true,
        };
        if(this.unit?.ussd?.responseResult === 'AwaitingAnswer') {
            ussdRequest.responseCount = this.unit.ussd.responseCount;
        }
        this.Restangular.one('units', this.unit._id).all('ussd')
            .post(ussdRequest)
            .then(res => {
                this.toastr.info('USSD Code sent');
            })
            .catch(err => {
                console.error(err);
                this.toastr.error(err.data || err, 'USSD Request failed');
            });
    }

    balanceCheckRequest() {
        let ussdRequest = {
            balanceCheck: true,
        };
        this.Restangular.one('units', this.unit._id).all('ussd')
            .post(ussdRequest)
            .then(res => {
                this.toastr.info('USSD Code sent');
            })
            .catch(err => {
                console.error(err);
                this.toastr.error(err.data || err, 'USSD Request failed');
            });
    }
    numberCheckRequest() {
        let ussdRequest = {
            numberCheck: true,
        };
        this.Restangular.one('units', this.unit._id).all('ussd')
            .post(ussdRequest)
            .then(res => {
                this.toastr.info('USSD Code sent');
            })
            .catch(err => {
                console.error(err);
                this.toastr.error(err.data || err, 'USSD Request failed');
            });
    }

    cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
    }
    doLog() {
        console.debug(this);
    }
}
export default angular.module('insideInfoApp.units')
    .component('ussdModal', {
        template: require('./ussd.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<',
            settings: '<'
        },
        controller: USSDModalComponent,
        controllerAs: '$ctrl'
    })
    .name;

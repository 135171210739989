import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routing from './main.routes';

export class MainController {
	$http;
	socket;

	/*@ngInject*/
	constructor($http, $scope, socket, $state, Auth, $uibModal, appConfig) {
	    this.$http = $http;
	    this.socket = socket;
	    this.$state = $state;
	    this.Auth = Auth;
	    this.$uibModal = $uibModal;
	    this.appConfig = appConfig;
	}

	$onInit() {
	    let self = this;
	    self.Auth.getCurrentUser().then(user => {
	        self.Auth.refreshToken();
	        self.Auth.startIdle(user.idleDuration, user.tokenTTL / 2);
	        self.checkConsent(user);
	    });
	}

	checkConsent(user) {
	    let self = this;
	    let privacyDate = this.appConfig.policyUpdated.privacy;
	    let termsDate = this.appConfig.policyUpdated.terms;
	    let disclaimerDate = this.appConfig.policyUpdated.disclaimer;
	    if(user.consent && user.consent.privacy && user.consent.terms && new Date(user.consent.privacy) > privacyDate && new Date(user.consent.terms) > termsDate && new Date(user.consent.disclaimer) > disclaimerDate) {
	        //NOP
	    } else {
	        //Open privacy modal
	        let modalInstance = self.$uibModal.open({
	            component: 'consent',
	            backdrop: 'static',
	            //size:'lg',
	            keyboard: false,
	            resolve: {
	                user,
	            }
	        });
	    }
	}
}

export default angular.module('insideInfoApp.main', [uiRouter])
    .config(routing)
    .component('main', {
        template: require('./main.html'),
        controller: MainController,
        controllerAs: '$ctrl'
    })
    .name;

export class ContactsListComponent {
	Restangular;
	Contacts;
	Auth;
	$stateParams;
	NgTableParams;
	tableParams;
	$uibModal;
	$log;

	/*@ngInject*/
	constructor(Restangular, Auth, $stateParams, NgTableParams, $uibModal, $log) {
	    this.Auth = Auth;
	    this.$stateParams = $stateParams;
	    this.NgTableParams = NgTableParams;
	    this.$uibModal = $uibModal;
	    this.$log = $uibModal;
	    this.Restangular = Restangular;
	    this.getCurrentUser = Auth.getCurrentUserSync;
	}

	$onInit() {
	    let self = this;
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
	    this.filter = '';
	    this.Contacts = this.Restangular.all('contacts');

	    this.tableParams = new this.NgTableParams({

	        page: 1, // start with first page
	        count: 10, // count per page
	        sorting: {
	            firstname: 'asc' // initial sorting
	        }
	    }, {
	        total: 0,
	        getData(params) {
	            let order;
	            if(params && params.sorting) {
	                order = params.sorting();
	                return self.Contacts.getList({
	                    accountID: self.$stateParams.accountID,
	                    filter: self.filter.length ? self.filter : undefined,
	                    skip: (params.page() - 1) * params.count(),
	                    limit: params.count(),
	                    by: Object.keys(order)[0],
	                    order: order[Object.keys(order)[0]]})
	                    .then(function(contacts) {
	                        self.contacts = contacts;
	                        self.total = contacts.total;
	                        params.total(contacts.total);
	                        return contacts;
	                    })
	                    .catch(err => {
	                        console.error('Error when retrieving contacts:', err.data.err);
	                    });
	            }
	        }
	    }
	    );
	    this.tableParams.reload();
	}

	getAccountName(accountID) {
	    let user = this.getCurrentUser();
	    if(!user.accounts || typeof user.accounts === Array) {
	        return 'No Account';
	    }
	    let account;
	    for(let i = 0; i < user.accounts.length; ++i) {
	        if(user.accounts[i].ref === accountID) {
	            account = user.accounts[i];
	            break;
	        }
	    }
	    if(account !== undefined) {
	        return account.name;
	    }
	    return 'No Account';
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openCreateContact() {
	    let self = this;
	    let modalInstance = this.$uibModal.open({
	        component: 'contactmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: false,
	                    create: true,
	                    account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)}
	                };
	            }
	        }
	    });

	    modalInstance.result.then(function(result) {
	        self.Contacts.post(result, {accountID: self.$stateParams.accountID}).then(function() {
	            self.tableParams.reload();
	        });
	    });
	}

	openEditContact(contact) {
	    let self = this;
	    let modalInstance = self.$uibModal.open({
	        component: 'contactmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    edit: true,
	                    create: false,
	                    account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)},
	                    item: contact
	                };
	            }
	        }
	    });

	    modalInstance.result.then(function(result) {
	        result.patch().then(function() {
	            self.tableParams.reload();
	        });
	    });
	}

	onDeleteContact(contact) {
	    let self = this;
	    contact.remove().then(function() {
	        self.tableParams.reload();
	    });
	}
}

export default angular.module('insideInfoApp.contacts')
    .component('contactslist', {
        template: require('./contacts.list.html'),
        controller: ContactsListComponent,
        controllerAs: '$ctrl'
    })
    .name;

'use strict';
/* eslint no-sync: 0 */

import angular from 'angular';
import uiSelect from 'ui-select';
const uiBootstrap = require('angular-ui-bootstrap');


export class NavbarComponent {
  menu = [{
      title: 'Home',
      state: 'main.testSmartAdmin'
  }
  ];

  constructor(Auth, $location, $state, $rootScope) {
      'ngInject';
      this.Auth = Auth;
      this.isLoggedIn = Auth.isLoggedInSync;
      this.isAdmin = Auth.isAdminSync;
      this.getCurrentUser = Auth.getCurrentUserSync;
      this.getCurrentAccount = Auth.getCurrentAccountSync;
      this.setCurrentAccount = Auth.setCurrentAccount;
      this.hasPrivilege = Auth.hasPrivilegeSync;
      this.$location = $location;
      this.$state = $state;
      this.$rootScope = $rootScope;
  }

  $onInit() {
      let self = this;
      this.currentAccount = this.Auth.getCurrentAccountSync();
      this.currentUser = this.Auth.getCurrentUserSync();
      self.accountIcon = `assets/images/accounticons/${self.currentAccount.name}.png`;
      // TODO: Implement functionality that if no currentAccount.png exists, a next .png [see below] should be chosen
      // this.accountIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;

      this.$rootScope.$on('accountChanged', event => {
          self.currentAccount = this.getCurrentAccount();
          self.accountIcon = `assets/images/accounticons/${self.currentAccount.name}.png`;
      });
  }

  navigateTo(state) {
      this.$state.go(state);
  }

  testMenuPrivilege(privilege) {
      // console.trace();
      if(privilege !== 'main') {
          return this.hasPrivilege(privilege);
      } else {
          return true;
      }
  }
}

export default angular.module('directives.navbar', [uiBootstrap, uiSelect])
    .component('navbar', {
        template: require('./navbar.html'),
        controller: NavbarComponent,
        controllerAs: '$ctrl'
    })
    .name;

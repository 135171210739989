export class UnitsManageComponent {
  $stateParams;
  Restangular;
  moment;
  $log;
  baseUnit;
  suspend;

  // onSuspend : Function;

  /*@ngInject*/
  constructor($stateParams, Restangular, moment, $log) {
      this.$stateParams = $stateParams;
      this.Restangular = Restangular;
      this.moment = moment;
      this.$log = $log;

      this.baseUnit = this.Restangular.one('units', this.$stateParams.unitID);
      this.suspend = 0;
  }

  onSuspend() {
      let self = this;
      this.baseUnit.one('manage').one('suspend')
          .customPOST({suspend: this.suspend})
          .then(function() {
              self.$log.warn('suspend');
          });
  }
}
export default angular.module('insideInfoApp.units')
    .component('unitmanage', {
        template: require('./units.detail.manage.html'),
        controller: UnitsManageComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class ContactGroupsComponent {
	Auth;
	$stateParams;
	Restangular;
	NgTableParams;
	$uibModal;
	$log;
	filter;
	groups;
	selectedGroup;
	Contacts;
	Groups;

	/*@ngInject*/
	constructor(Auth, $stateParams, Restangular, NgTableParams, $uibModal, $log) {
	    this.Auth = Auth;
	    this.$stateParams = $stateParams;
	    this.Restangular = Restangular;
	    this.NgTableParams = NgTableParams;
	    this.$uibModal = $uibModal;
	    this.$log = $log;
	    this.getCurrentUser = Auth.getCurrentUserSync;
	}

	$onInit() {
	    let self = this;
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
	    this.filter = '';
	    this.Contacts = this.Restangular.all('contacts');
	    this.Groups = this.Contacts.all('groups');

	    this.groups = [];
	    this.selectedGroup = '';

	    this.Groups.getList({accountID: this.$stateParams.accountID}).then(function(groups) {
	        self.groups = groups;
	        self.selectedGroup = self.groups[0];
	        self.applyFilter();
	    });


	    this.tableParams = new this.NgTableParams({
	        page: 1, // start with first page
	        count: 10, // count per page
	        sorting: {
	            firstname: 'asc' // initial sorting
	        } }, {
	        total: 0,
	        getData(params) {
	            let order;
	            if(params && params.sorting) {
	                order = params.sorting();
	                return self.Contacts.getList({
	                    accountID: self.$stateParams.accountID,
	                    query: self.selectedGroup || ' ',
	                    field: 'groups',
	                    skip: (params.page() - 1) * params.count(),
	                    limit: params.count(),
	                    by: Object.keys(order)[0],
	                    order: order[Object.keys(order)[0]]}).then(function(contacts) {
	                    self.contacts = contacts;
	                    self.total = contacts.total;
	                    params.total(contacts.total);
	                    return contacts;
	                });
	            }
	        }
	    });
	}

	onGroupSelected(item, model) {
	    this.applyFilter();
	}

	getAccountName(accountID) {
	    let user = this.getCurrentUser();
	    if(!user.accounts || typeof user.accounts === Array) {
	        return 'No Account';
	    }
	    let account;
	    for(let i = 0; i < user.accounts.length; ++i) {
	        if(user.accounts[i].ref === accountID) {
	            account = user.accounts[i];
	            break;
	        }
	    }
	    if(account !== undefined) {
	        return account.name;
	    }
	    return 'No Account';
	}


	applyFilter() {
	    this.tableParams.page(1);
	    this.tableParams.reload();
	}


	onRemoveFromGroup(contact) {
	    let self = this;
	    _.pull(contact.groups, self.selectedGroup);
	    contact.put().then(contact => {
	        self.Groups.getList({accountID: self.$stateParams.accountID}).then(function(groups) {
	            self.groups = groups;
	            let index = _.findIndex(self.groups, self.selectedGroup);
	            if(index >= 0) {
	                self.selectedGroup = self.groups[index];
	            } else {
	                self.selectedGroup = self.groups[0];
	            }
	            self.applyFilter();
	        });
	    });
	}
}

export default angular.module('insideInfoApp.contacts')
    .component('contactgroups', {
        template: require('./contacts.groups.html'),
        controller: ContactGroupsComponent,
        controllerAs: '$ctrl'
    })
    .name;

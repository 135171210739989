export class AccountMetricSumComponent {
    $stateParams;
    heartbeats;

    // refreshClients : Function;
    // onClientChanged : Function;


    /*@ngInject*/
    constructor($stateParams, Restangular, moment, $scope, $timeout, NgTableParams, Auth) {
        this.Restangular = Restangular;
        this.$stateParams = $stateParams;
        this.moment = moment;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.NgTableParams = NgTableParams;
        this.Auth = Auth;
    }

    $onInit() {
        let self = this;
        self.Auth.getCurrentUser().then(user => {
            self.currentUser = user;
            // if(this.$stateParams.heartbeats !== null) {
            //     this.heartbeats = this.$stateParams.heartbeats;
            // }
            self.metrics = [];
            self.rangeOptions = ['Last 24 Hours', 'Last 7 Days', 'Last 31 Days', 'Today', 'This Week', 'This Month'];
            if(self.$stateParams) {
                if(self.$stateParams.from) {
                    self.from = self.$stateParams.from;
                } else {
                    self.from = self.moment().subtract(1, 'day')
                        .toDate();
                }
                if(self.$stateParams.to) {
                    self.to = self.$stateParams.to;
                } else {
                    self.to = self.moment().toDate();
                }
            }
            self.metricName = self.$stateParams.metricName || 'Delta Data Usage';
            self.getMetrics();
            //self.$scope.$watchGroup(['$ctrl.from', '$ctrl.to', '$ctrl.metricName'], () => {
            //self.getMetrics();
            //});
            self.availableMetricNames = [
                'Delta Data Usage',
                'Delta Data Usage In',
                'Delta Data Usage Out',
                'Delta Data Usage SIM 1',
                'Delta Data Usage SIM 2',
                'Delta Data Usage SIM 1 In',
                'Delta Data Usage SIM 2 In',
                'Delta Data Usage SIM 1 Out',
                'Delta Data Usage SIM 2 Out',
                'SIM Swaps',
                //'Reboots',
            ];
            if(self.currentUser.role == 'admin') {
                self.availableMetricNames.push('Reboots');
            }
            self.tableParams = new self.NgTableParams({}, {dataset: self.metrics});
        });
    }


    getMetrics() {
        let self = this;
        self.loading = true;
        self.Restangular.all('metrics').customGET(
            `${self.$stateParams.accountID}/${self.metricName}`,
            {
                from: self.moment(self.from).valueOf(),
                to: self.moment(self.to).valueOf(),
            })
            .then(results => {
                self.metrics = results.data;
                self.tableParams.settings({dataset: self.metrics});
                self.tableParams.reload();
                self.loading = false;
            })
            .catch(err => {
                console.error(err);
                self.loading = false;
            });
    }

    openDateTimePicker(index, picker, $event) {
        let self = this;
        $event.preventDefault();
        $event.stopPropagation();
        self[picker] = !self[picker];
    }

    quickPick(index, option) {
        let self = this;
        let to = this.moment();
        let from = this.moment();
        self.to = to.toDate();
        switch (option) {
        case 'Last 24 Hours':
            self.from = from.subtract(24, 'hours').toDate();
            break;
        case 'Last 7 Days':
            self.from = from.subtract(7, 'days').toDate();

            break;
        case 'Last 31 Days':
            self.from = from.subtract(31, 'days').toDate();

            break;
        case 'Today':
            self.to = to.endOf('day').toDate();
            self.from = from.startOf('day').toDate();
            break;
        case 'This Week':
            self.to = to.endOf('week').toDate();
            self.from = from.startOf('week').toDate();
            break;
        case 'This Month':
            self.to = to.endOf('month').toDate();
            self.from = from.startOf('month').toDate();

            break;
        default:
        }
    }
}


export default angular.module('insideInfoApp.units')
    .component('metricsum', {
        template: require('./units.detail.metricsum.html'),
        controller: AccountMetricSumComponent,
        controllerAs: '$ctrl'
    })
    .filter('metricsum', function() {
        return function(value, precision, type) {
            if(type.includes('Data')) {
                if(isNaN(parseFloat(value)) || !isFinite(value)) return '-';
                if(typeof precision === 'undefined') precision = 1;
                var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
                var number = Math.floor(Math.log(value) / Math.log(1024));
                if(value == 0) {
                    return `${value} ${units[0]}`;
                }
                return `${(value / Math.pow(1024, Math.floor(number))).toFixed(precision)} ${units[number]}`;
            } else if(precision >= 0 && type != 'SIM Swaps' && type != 'Reboots') {
                let val = value.toFixed(precision);
                var parts = val.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return parts.join('.');
            } else {
                var parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return parts.join('.');
            }
        };
    })
    .name;

'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.contacts', {
            abstract: true,
            url: '/contacts',
            data: {
                title: 'Contacts'
            },
            authenticate: true
        })

        .state('main.contacts.list', {
            url: '/list',
            views: {
                'content@main': {
                    template: '<contactslist></contacts>'
                }
            },
            data: {
                title: 'List'
            },
            authenticate: true
        })

        .state('main.contacts.groups', {
            url: '/groups',
            views: {
                'content@main': {
                    template: '<contactgroups></contactgroups>'
                }
            },
            data: {
                title: 'Groups'
            },
            authenticate: true
        });
}

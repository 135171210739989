'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import Restangular from 'restangular';
import routes from './groups.routes';


export default angular.module('insideInfoApp.groups', [Restangular])
    .config(routes)
    .name;

require('./groups.units.component');
require('./groups.users.component');
require('./groups.unit.modal.component');


export class AirtimeTable {
    /*@ngInject*/
    constructor(NgTableParams, $scope) {
        this.NgTableParams = NgTableParams;
        this.$scope = $scope;

        this.metricNameMap = {
            'Delta Data Usage': 'Data Usage (Internal)',
            'Delta Network Data Usage': 'Data Usage (USSD)',
            'Delta Network Airtime Usage': 'Airtime Usage (USSD)',
            'Airtime Reload': 'Airtime Reloads',
            'Data Reload': 'Data Reloads',
        };

        this.metricTypeMap = {
            'Delta Data Usage': 'data',
            'Delta Network Data Usage': 'data',
            'Delta Network Airtime Usage': 'currency',
            'Airtime Reload': 'currency',
            'Data Reload': 'currency',
        };
    }

    $onInit() {
        this.data = this.data || [];
        this.simFilterData = [{
            id: 1,
            title: 'Sim 1'
        },
        {
            id: 2,
            title: 'Sim 2'
        }];
        this.unWatch = this.$scope.$watch(() => this.data, () => {
            this.resetTable();
        });
    }


    $onDestroy() {
        this.unWatch();
    }

    resetTable() {
        const settings = {
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc' // initial sorting
            },
            ...{
                page: this.tableParams?.page(),
                sorting: this.tableParams?.sorting(),
                filter: this.tableParams?.filter(),
            }
        };
        this.tableParams = new this.NgTableParams(settings, {
            dataset: this.data,
        });
    }

    toggleSelectAll() {
        this.tableParams.data.forEach(item => {
            const key = `${item._id}_${item.sim}`;
            if(this.allChecked) {
                this.checkedList[key] = true;
            } else {
                this.checkedList[key] = false;
            }
            this.updateCheckedList(key);
        });
    }

    toggleSelect(key) {
        this.updateAllChecked();
        this.updateCheckedList(key);
    }

    updateAllChecked() {
        this.allChecked = _.every(this.tableParams.data, item => {
            const key = `${item._id}_${item.sim}`;
            return this.checkedList[key];
        });
    }
}
export default angular.module('insideInfoApp.units')
    .component('airtimeTable', {
        template: require('./airtime-table.html'),
        controller: AirtimeTable,
        controllerAs: '$ctrl',
        bindings: {
            data: '=',
            infoType: '<',
            checkedList: '=',
            updateCheckedList: '&'
        }
    })
    .name;

export class UnitsDetailChartComponent {
	$q;
	Restangular;
	socket;
	$scope
	loaded;
	title;
	icon;
	graph;
	chart = {};
	chartName;
	chartID;
	unitID;
	moment;


	/*@ngInject*/
	constructor($q, Restangular, socket, $scope, $stateParams, moment, $timeout, $document) {
	    this.$q = $q;
	    this.Restangular = Restangular;
	    this.socket = socket;
	    this.$scope = $scope;
	    this.$stateParams = $stateParams;
	    //this.selectedRange = $stateParams.selectedRange;
	    //this.selectedResolution = $stateParams.selectedResolution;
	    //this.customRange = $stateParams.customRange;
	    //this.selectedCharts = $stateParams.selectedCharts;
	    this.unitID = $stateParams.unitID;
	    this.accountID = $stateParams.accountID;
	    this.moment = moment;
	    this.$timeout = $timeout;
	    this.$document = $document;
	}

	$onInit() {
	    let self = this;

	    self.menuOptions = [
	        {
	            text: 'Mark selected as anomaly',
	            click($itemScope, $event, modelValue, text, $li) {
	                self.markAnomaly();
	            }
	        },
	        {
	            text: 'Unmark selected as anomaly',
	            click($itemScope, $event, modelValue, text, $li) {
	                self.markAnomaly(true);
	            }
	        },
	        {
	            text: 'Zoom to selected',
	            click($itemScope, $event, modelValue, text, $li) {
	                let selected = self.c3chart.selected();
	                if(selected) {
	                    if(selected.length > 1) {
	                        selected.sort((a, b) => self.moment(a.x) - self.moment(b.x));
	                        self.zoomToFunction({start: selected[0].x, end: selected[selected.length - 1].x, metric: self.chartName});
	                    } else if(selected.length == 1) {
	                        self.zoomToFunction({date: selected[0].x, metric: self.chartName});
	                    }
	                }
	                self.c3chart.unselect();
	            }
	        },
	        {
	            text: 'Unselect all',
	            click($itemScope, $event, modelValue, text, $li) {
	                self.c3chart.unselect();
	            }
	        }

	    ];
	    //self.$document.bind('keyup', function (e) {
	    //if(e.keyCode == 16){
	    //self.disableZoom();
	    //}
	    //});
	    //self.$document.bind('keydown', function (e) {
	    //if(e.keyCode == 16){
	    //self.enableZoom();
	    //}
	    //});
	    //We're removing all spaces from the chartID - this is because jQuery has a problem with handling
	    //ID's that contain 'special characters'
	    this.chartID = this.chartName.replace(/ /g, '');
	    this.title = this.chartName;
	    this.chart.name = this.chartName;
	    this.icon = 'fa fa-area-chart';
	    this.graph = {
	        data: [],
	        opts: {
	            labels: ['Date', 'A']
	        }
	    };


	    self.watchers = [];
	    //TODO : Revice this as each chart registers on a socket.  Could be done with one.
	    // this.socket.joinRoom(`${self.$stateParams.accountID}:${self.unitID}:*:heartbeats`);
	    // this.socket.socket.on('heartbeat:save', function(doc) {
	    // 	self.throttleRefresh();
	    // });
	    //
	    // this.$scope.$on('$destroy', function() {
	    // 	self.socket.leaveRoom(`${self.$stateParams.accountID}:${self.unitID}:*:heartbeats`);
	    // });


	    //TODO: Find out if the following $watch functions are efficient. It seems to
	    //      effect performance.

	    //TODO: Think about live-monitoring. That is, opening the screen and watching the charts update live.

	    // this.$scope.$watch('selectedRange', function() {
	    //   self.reloadCharts();
	    // }, true);
	    self.throttleRefresh = _.debounce(self.reloadCharts, 500);
	    // self.watchers.push(this.$scope.$watch(function() {
	    // 	return self.selectedRange;
	    // }, function(newValue, oldValue) { //parent.parent
	    // 	//self.selectedRange = self.$stateParams.selectedRange;
	    // 	//self.selectedResolution = self.$stateParams.selectedResolution;
	    // 	self.throttleRefresh();
	    // }));
	    //
	    // self.watchers.push(this.$scope.$watch(function() {
	    // 	return self.selectedResolution;
	    // }, function(newValue, oldValue) { //parent.parent
	    // 	//self.selectedRange = self.$stateParams.selectedRange;
	    // 	//self.selectedResolution = self.$stateParams.selectedResolution;
	    // 	if (newValue !== oldValue) {
	    // 		self.throttleRefresh();
	    // 	}
	    // }));
	    //
	    // self.watchers.push(this.$scope.$watch(function() {
	    // 	return self.customRange.start;
	    // }, function(newValue, oldValue) { //parent.parent
	    // 	if (newValue !== oldValue) {
	    // 		self.throttleRefresh();
	    // 	}
	    // }));
	    //
	    // self.watchers.push(this.$scope.$watch(function() {
	    // 	return self.customRange.end;
	    // }, function(newValue, oldValue) { //parent.parent
	    // 	if (newValue !== oldValue) {
	    // 		self.throttleRefresh();
	    // 	}
	    //
	    // }));
	    self.watchers.push(this.$scope.$watch(function() {
	        return self.activeRegions;
	    }, function(newValue, oldValue) { //parent.parent
	        if(newValue !== oldValue) {
	            self.changeActiveRegions();
	        }
	    }));
	    self.watchers.push(this.$scope.$watch(() => self.resize, (newVal, oldVal) => {
	        if(newVal == self.chartName && self.c3chart) {
	            let chartEl = $(`#${self.chartIndex}`)[0];
	            if(chartEl && chartEl.parentElement && chartEl.parentElement.parentElement) {
	                let box = chartEl.parentElement.parentElement;
	                self.c3chart.resize({height: box.clientHeight - 60, width: box.clientWidth - 20});
	            }
	        }
	    }));
	    self.watchers.push(this.$scope.$on('refreshCharts', () => {
	        self.throttleRefresh();
	    }));
	}

	$onDestroy() {
	    let self = this;
	    self.watchers.forEach(f => {
	        if(typeof f == 'function') {
	            f();
	        }
	    });
	    if(self.c3chart) {
	        self.c3chart = self.c3chart.destroy();
	    }
	}

	reloadCharts() {
	    let self = this;
	    if(self.unitID == 'None') {
	        return null;
	    }
	    this.graph.data = [];
	    self.loading = true;
	    let accountID = this.accountID;
	    let granularity;
	    let range;
	    let custom = {};
	    switch (this.selectedResolution.name) { //parent.parent
	    case '1 Minute':
	        {
	            granularity = 'minute';
	            switch (this.selectedRange) { //parent.parent
	            case '1 Hour':
	                {
	                    range = 60;
	                }
	                break;
	            case '6 Hours':
	                {
	                    range = 60 * 6;
	                }
	                break;
	            case '12 Hours':
	                {
	                    range = 60 * 12;
	                }
	                break;
	            case '24 Hours':
	                {
	                    range = 60 * 24;
	                }
	                break;
	            case '48 Hours':
	                {
	                    range = 60 * 48;
	                }
	                break;
	            case 'Custom':
	                {
	                    range = 999999;
	                    custom = {
	                        start: this.customRange.start.valueOf(),
	                        end: this.customRange.end.valueOf()
	                    }; //parent.parentx2
	                }
	                break;
	            }
	        }
	        break;
	    case '5 Minutes':
	        {
	            granularity = '5minute';
	            switch (this.selectedRange) { //parent.parent
	            case '6 Hours':
	                {
	                    range = 12 * 6;
	                }
	                break;
	            case '12 Hours':
	                {
	                    range = 12 * 12;
	                }
	                break;
	            case '24 Hours':
	                {
	                    range = 12 * 24;
	                }
	                break;
	            case '48 Hours':
	                {
	                    range = 12 * 48;
	                }
	                break;
	            case 'Custom':
	                {
	                    range = 999999;
	                    custom = {
	                        start: this.customRange.start.valueOf(),
	                        end: this.customRange.end.valueOf()
	                    }; //parent.parentx2
	                }
	                break;
	            }
	        }
	        break;
	    case '1 Hour':
	        {
	            granularity = 'hourly';
	            switch (this.selectedRange) { //parent.parent
	            case '1 Day':
	                {
	                    range = 24;
	                }
	                break;
	            case '1 Week':
	                {
	                    range = 24 * 7;
	                }
	                break;
	            case '2 Weeks':
	                {
	                    range = 24 * 14;
	                }
	                break;
	            case '1 Month':
	                {
	                    range = 24 * 31;
	                }
	                break;
	            case '2 Months':
	                {
	                    range = 24 * 31 * 2;
	                }
	                break;
	            case 'Custom':
	                {
	                    range = 999999;
	                    custom = {
	                        start: this.customRange.start.valueOf(),
	                        end: this.customRange.end.valueOf()
	                    }; //parent.parentx2
	                }
	                break;
	            }
	        }
	        break;
	    case '1 Day':
	        {
	            granularity = 'daily';
	            switch (this.selectedRange) { //parent.parent
	            case '1 Week':
	                {
	                    range = 7;
	                }
	                break;
	            case '2 Weeks':
	                {
	                    range = 14;
	                }
	                break;
	            case '1 Month':
	                {
	                    range = 31;
	                }
	                break;
	            case '6 Months':
	                {
	                    range = 31 * 6;
	                }
	                break;
	            case '1 Year':
	                {
	                    range = 365;
	                }
	                break;
	            case 'Custom':
	                {
	                    range = 999999;
	                    custom = {
	                        start: this.customRange.start.valueOf(),
	                        end: this.customRange.end.valueOf()
	                    }; //parent.parentx2
	                }
	                break;
	            }
	        }
	        break;
	    }

	    if(this.chart.name.indexOf('Data Usages') > -1) {
	        let datas = [{}, {}, {}];
	        self.origData = {};
	        let xvals = [];
	        let loadData = function(chart) {
	            return function(data) {
	                let label = self.graph.opts.labels[chart + 1];
	                self.origData[label] = {};
	                _.forEach(data.data, function(value) {
	                    datas[chart][value[0]] = value[1];
	                    xvals.push(value[0]);
	                    self.origData[label][value[0]] = {val: value[1], anomaly: value[2]};
	                });
	            };
	        };
	        let allLoaded;
	        if(this.chart.name.indexOf('Total') > -1) {
	            this.graph.opts.labels = ['Date', 'Total', 'In', 'Out'];
	            allLoaded = this.$q.all([
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(0)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage In')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(1)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage Out')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(2)),
	            ]);
	        } else if(this.chart.name.indexOf('SIM1') > -1) {
	            this.graph.opts.labels = ['Date', 'Total', 'SIM1 In', 'SIM1 Out'];
	            allLoaded = this.$q.all([
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage SIM1')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(0)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage In SIM1')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(1)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage Out SIM1')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(2)),
	            ]);
	        } else {
	            this.graph.opts.labels = ['Date', 'Total', 'SIM2 In', 'SIM2 Out'];
	            allLoaded = this.$q.all([
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage SIM2')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(0)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage In SIM2')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(1)),
	                self.Restangular.one('metrics', self.unitID).all(accountID)
	                    .all('Data Usage Out SIM2')
	                    .all(granularity)
	                    .customGET(range, custom)
	                    .then(loadData(2)),
	            ]);
	        }
	        // allLoaded.push(self.regoins.then((o) => {
	        // 	self.thisRegions = o;
	        // }));
	        allLoaded.then(function() {
	            let xaxis = _.uniq(xvals);
	            _.forEach(xaxis, function(x) {
	                let point = [new Date(x)];
	                _.forEach(datas, function(series) {
	                    if(series[x]) {
	                        point.push(series[x]);
	                    } else {
	                        point.push(0);
	                    }
	                });

	                self.graph.data.push(point);
	            });
	            delete self.graph.opts.color;

	            self.graph.opts.colors = ['#3276b1', '#ff0066', '#00cc33'];

	            //$scope.graph.opts.labelsDiv = 'label'+$scope.$index;
	            //$scope.graph.opts.stackedGraph = true;
	            self.graph.opts.type = 'area';
	            self.loaded = true;
	            if(self.graph.data && self.graph.data.length > 0) {
	                self.noData = false;
	                self.drawChart();
	            } else {
	                self.noData = true;
	            }
	            self.loading = false;
	        }).catch(err => {
	            self.noData = true;
	            self.loading = false;
	        });
	    } else {
	        let dataPromises = [];
	        if(this.chart.name.indexOf('Average') > -1) {
	            let dataPromise = this.Restangular.one('metrics', this.unitID).all(accountID)
	                .all(this.chart.name.replace('Average ', ''))
	                .all(granularity)
	                .customGET(range, custom);
	            dataPromises.push(dataPromise);
	            let onlineUnitsPromise = this.Restangular.one('metrics', this.unitID).all(accountID)
	                .all('Online Units')
	                .all(granularity)
	                .customGET(range, custom);
	            dataPromises.push(onlineUnitsPromise);
	        } else {
	            let dataPromise = this.Restangular.one('metrics', this.unitID).all(accountID)
	                .all(this.chart.name)
	                .all(granularity)
	                .customGET(range, custom);
	            dataPromises.push(dataPromise);
	        }
	        Promise.all(dataPromises).then(function(datas) {
	            let data = datas[0];
	            let onlineUnits;
	            if(self.chart.name.indexOf('Average') > -1) {
	                onlineUnits = datas[1];
	            }
	            let total = 0;
	            self.origData = {};
	            self.graph.data = _.map(data.data, function(value, index) {
	                if(self.chart.name.includes('Uptime')) {
	                    total += value[1];
	                    //return [new Date(value[0]), total];
	                    self.graph.opts.stepPlot = true;
	                    let totalSeconds = 60; //1 Minute
	                    if(granularity == '5minute') {
	                        totalSeconds = 60 * 5; //5 Minutes
	                    }
	                    if(granularity == 'hourly') {
	                        totalSeconds = 60 * 60; //1 Hour
	                    }
	                    if(granularity == 'daily') {
	                        totalSeconds = 60 * 60 * 24; //1 Day
	                    }
	                    return [new Date(value[0]), value[1] / totalSeconds * 100];
	                } else if(self.chart.name === 'Mains' || self.chart.name === 'Battery') {
	                    //$scope.graph.opts.rollPeriod = 5;
	                    self.origData[value[0]] = {val: value[1], anomaly: value[2]};
	                    let newval = value[1] / 1000;
	                    return [new Date(value[0]), newval];
	                } else if(self.chart.name.indexOf('Average') > -1) {
	                    let avgValue = value[1];
	                    if(granularity !== 'minute') {
	                        avgValue = value[1] / onlineUnits.data[index][1];
	                    }
	                    self.origData[value[0]] = {val: avgValue || 0, anomaly: value[2]};
	                    return [new Date(value[0]), avgValue || 0];
	                } else {
	                    self.origData[value[0]] = {val: value[1], anomaly: value[2]};
	                    return [new Date(value[0]), value[1]];
	                }
	            });
	            self.graph.opts.labels = data.columns;
	            //$scope.graph.opts.labelsDiv = 'label'+$scope.$index;
	            if(data.avg) {
	                self.graph.opts.type = 'area';
	            } else {
	                self.graph.opts.type = 'bar';
	            }
	            self.loaded = true;
	            if(self.graph.data && self.graph.data.length > 0) {
	                self.noData = false;
	                self.drawChart();
	            } else {
	                self.noData = true;
	            }
	            // self.regions.then((o) => {
	            // 	self.thisRegions = o;
	            // })
	            self.loading = false;
	        })
	            .catch(err => {
	            self.noData = true;
	            self.loading = false;
	        });
	    }

	    self.granularity = granularity;
	}

	drawChart() {
	    let self = this;

	    /**
		 * This is an array that contains all the data-values that will be used by
		 * C3. Note that the first index of the array contains the data TYPES
		 * and following indexes contain the data itself.
		 * @type {Array}
		 */
	    let chartData = [];

	    self.yLabel = '';
	    self.xAxisTickFormat = {
	        '1 Minute': 'HH:mm',
	        '5 Minutes': 'HH:mm',
	        '1 Hour': 'Y-M-D',
	        '1 Day': 'Y-M-D'
	    };

	    if(self.chart.name.indexOf('Reload') > -1) {
	        self.yLabel = 'R';
	    } else if(self.chart.name.indexOf('Data') > -1) {
	        self.yLabel = 'bytes';
	    } else if(self.chart.name.indexOf('RSSI') > -1) {
	        self.yLabel = 'dBm';
	    } else if(self.chart.name.indexOf('BER') > -1 || self.chart.name.indexOf('Uptime') > -1 || self.chart.name.indexOf('Application Link Quality Sim 1') > -1 || self.chart.name.indexOf('Application Link Quality Sim 2') > -1 || self.chart.name.indexOf('Management Link Quality Sim 1') > -1 || self.chart.name.indexOf('Management Link Quality Sim 2') > -1) {
	        self.yLabel = '% [percent]';
	    } else if(self.chart.name.indexOf('Reboots') > -1 || self.chart.name.indexOf('Swaps') > -1) {
	        self.yLabel = 'times';
	    } else if(self.chart.name.indexOf('Units') > -1) {
	        self.yLabel = 'Units';
	    } else if(self.chart.name.indexOf('Airtime') > -1) {
	        self.yLabel = 'R';
	    } else {
	        self.yLabel = 'Volts';
	    }

	    if(this.selectedResolution) {
	        chartData.push(self.graph.opts.labels);
	    }

	    _.forEach(self.graph.data, function(el) {
	        chartData.push(el);
	    });
	    let start = new self.moment(chartData[1][0]);
	    let end = new self.moment(chartData[chartData.length - 1][0]);
	    let count = end.diff(start, 'minute');
	    //TODO : zero pad missing date values to ensure correct plot look
	    switch (self.granularity) {
	    case '5minute':
	        count = count / 5 || 1;
	        break;
	    case 'hourly':
	        count = count / 60 || 1;
	        break;
	    case 'daily':
	        count = count / (60 * 24) || 1;
	        break;
	    default:
	    }
	    self.ratio = count * -0.014 + 0.94;
	    if(self.ratio < 0.1) {
	        self.ratio = 0.1;
	    } else if(self.ratio > 0.8) {
	        self.ratio = 0.8;
	    }
	    // self.c3chart = c3.generate({
	    // 	bindto: `#${self.chartIndex}`,
	    // 	data: {
	    // 		x: 'Date',
	    // 		rows: chartData,
	    // 		types: {
	    // 			[chartData[0][1]]: self.graph.opts.type,
	    // 			[chartData[0][2]]: self.graph.opts.type,
	    // 			[chartData[0][3]]: self.graph.opts.type,
	    // 			// 'Reboots': 'line',
	    // 			// 'SIM Swaps': 'line'
	    // 			//NOTE: You need to define how you wish each data-type to be displayed.
	    // 			// The above approach would simply give an area-graph where up to three
	    // 			// data-types are present.
	    // 		}
	    // 	},
	    // 	tooltip: {
	    // 		format: {
	    // 			title: function(d) {
	    // 				let dayFormat = d3.time.format('%Y-%m-%d  %H:%M');
	    // 				return 'Value at: ' + dayFormat(new Date(d));
	    // 			},
	    // 			value: function(value, ratio, id) {
	    // 				let returnValue = value;
	    // 				let returnYLabel = yLabel;
	    // 				if (yLabel === 'bytes') {
	    // 					if (value < (1024 * 1024 * 1024)) {
	    // 						returnValue = (value / 1024 / 1024);
	    // 						returnYLabel = 'MB';
	    // 					} else {
	    // 						returnValue = (value / 1024 / 1024 / 1024);
	    // 						returnYLabel = 'GB';
	    // 					}
	    // 				}
	    // 				let twoDecimal = d3.format('.2f');
	    // 				let newValue = returnValue % 1 === 0 ? returnValue : twoDecimal(returnValue)
	    // 				let format = d3.format(',');
	    // 				if (id === 'BER')
	    // 					returnYLabel = '%'
	    // 				return format(newValue) + ` ${returnYLabel}`;
	    // 			}
	    // 		}
	    // 	},
	    // 	zoom: {
	    // 		enabled: true,
	    // 		rescale: true
	    // 	},
	    // 	point: {
	    // 		show: false
	    // 	},
	    // 	bar: {
	    // 		width: {
	    // 			ratio: ratio
	    // 		}
	    // 	},
	    // 	axis: {
	    // 		x: {
	    // 			type: 'timeseries',
	    // 			tick: {
	    // 				culling: true,
	    // 				count: 12,
	    // 				fit: false,
	    //
	    // 				// format: '%Y-%m-%d'
	    // 				format: function(d) {
	    // 					let resolution = self.selectedResolution.name;
	    // 					let format = d3.time.format(xAxisTickFormat[resolution]);
	    // 					return format(new Date(d));
	    // 				}
	    //
	    // 			}
	    // 		},
	    // 		y: {
	    // 			label: {
	    // 				text: (yLabel == 'Units' ? yLabel : 'in ' + yLabel),
	    // 				position: 'outer-middle'
	    // 			},
	    // 			tick: {
	    // 				format: function(value, ratio, id) {
	    // 					let format = d3.format('.2f');
	    // 					if (yLabel === 'bytes') {
	    // 						// if (value < 1024) {
	    // 						//   return format(value) + 'B';
	    // 						// } else if (value < 1024 * 1024) {
	    // 						//   return format(value / 1024) + 'kB';
	    // 						//   }
	    // 						if (value < 1024 * 1024 * 1024) {
	    // 							return format(value / 1024 / 1024) + 'MB';
	    // 						} else {
	    // 							return format(value / 1024 / 1024 / 1024) + 'GB';
	    // 						}
	    // 					}
	    // 					return value;
	    // 				},
	    // 				culling: true
	    // 			}
	    // 		}
	    // 	},
	    // 	// regions:self.thisRegions,
	    // });
	    if(!self.c3chart) {
	        try {
	            self.c3chart = self.generateChart(chartData);
	            // self.c3chart.zoom.enable(false);
	            // self.zoomEnabled = false;
	        } catch(e) {
	            console.error(e);
	        }
	    } else {
	        // self.c3chart.unload();
	        self.c3chart.unselect();
	        self.c3chart.load({
	            // x: 'Date',
	            rows: chartData,
	            // types: {
	            // 	[chartData[0][1]]: self.graph.opts.type,
	            // 	[chartData[0][2]]: self.graph.opts.type,
	            // 	[chartData[0][3]]: self.graph.opts.type,
	            // 	// 'Reboots': 'line',
	            // 	// 'SIM Swaps': 'line'
	            // 	//NOTE: You need to define how you wish each data-type to be displayed.
	            // 	// The above approach would simply give an area-graph where up to three
	            // 	// data-types are present.
	            // }
	        });
	        self.c3chart.internal.config.bar_width.ratio = self.ratio;
	        self.c3chart.internal.config.bar_width_ratio = self.ratio;
	        self.$timeout(function() {
	            self.c3chart.flush();
	        }, 100);
	    }

	    self.changeActiveRegions();
	    self.$timeout(function() {
	        let chartEl = $(`#${self.chartIndex}`)[0];
	        if(chartEl && chartEl.parentElement && chartEl.parentElement.parentElement) {
	            let box = chartEl.parentElement.parentElement;
	            self.c3chart.resize({height: box.clientHeight - 60, width: box.clientWidth - 20});
	        }
	    }, 0);
	}

	throttleRefresh = _.throttle(this.reloadCharts, 60000);

	changeActiveRegions() {
	    let self = this;
	    if(self.c3chart) {
	        if(self.activeRegions && self.activeRegions.length > 0) {
	            self.regions.then(regions => {
	                if(regions && regions.length > 0) {
	                    let newregions = _.filter(_.flatten(regions), o => self.activeRegions.includes(o.class));
	                    self.c3chart.regions(newregions);
	                } else {
	                }
	            });
	        } else {
	            self.c3chart.regions.remove();
	        }
	    }
	}


	generateChart(chartData) {
	    let self = this;
	    let chart = c3.generate({
	        bindto: `#${self.chartIndex}`,
	        // onrendered: function() {
	        // 	$(`#${self.chartIndex}`).css("max-height", "none");
	        // },
	        data: {
	            x: 'Date',
	            rows: chartData,
	            types: {
	                [chartData[0][1]]: self.graph.opts.type,
	                [chartData[0][2]]: self.graph.opts.type,
	                [chartData[0][3]]: self.graph.opts.type,
	                // 'Reboots': 'line',
	                // 'SIM Swaps': 'line'
	                //NOTE: You need to define how you wish each data-type to be displayed.
	                // The above approach would simply give an area-graph where up to three
	                // data-types are present.
	            },
	            selection: {
	                enabled: true
	            },
	            onclick: (d, el) => {
	                // if(!self.enableZoom){
	                // 	self.c3chart.zoom.enable(true);
	                // 	self.enableZoom = true;
	                // }else if(d.x){
	                // }
	                //self.zoomToFunction({date:d.x, metric:self.chartName});
	            },
	            color: (c, o) => {
	                if(o && o.x && self.origData && o.id) {
	                    let origPoint;
	                    if(['Total', 'In', 'Out', 'SIM1 In', 'SIM1 Out', 'SIM2 In', 'SIM2 Out'].includes(o.id)) {
	                        origPoint = self.origData[o.id][new Date(o.x).getTime()];
	                    } else {
	                        origPoint = self.origData[new Date(o.x).getTime()];
	                    }
	                    if(origPoint && origPoint.anomaly) {
	                        return 'red';
	                    }
	                }
	                return c;
	            }

	        },
	        onrendered() {
	            d3.select('#chart').selectAll('.c3-event-rect')
	                .on('contextmenu', function(d, i) {
	                    d3.event.preventDefault(); // prevent default menu
	                    var vals = chart.data().map(function(series) {
	                        var name = series.id;
	                        return {
	                            name,
	                            value: chart.data.values(name)[d.x]}; // d.x is the index
	                    });
	                    alert(`data: ${JSON.stringify(vals)}`);
	                })
	            ;
	        },
	        tooltip: {
	            format: {
	                title(d) {
	                    return self.moment(d).format('YYYY-M-D HH:mm');
	                    // let dayFormat = d3.time.format('%Y-%m-%d  %H:%M');
	                    // return 'Value at: ' + dayFormat(new Date(d));
	                },
	                value(value, ratio, id) {
	                    let returnValue = value;
	                    let returnYLabel = self.yLabel;
	                    if(self.yLabel === 'bytes') {
	                        if(value < 1024 * 1024 * 1024) {
	                            returnValue = value / 1024 / 1024;
	                            returnYLabel = 'MB';
	                        } else {
	                            returnValue = value / 1024 / 1024 / 1024;
	                            returnYLabel = 'GB';
	                        }
	                    }
	                    // let twoDecimal = d3.format('.2f');
	                    let newValue = returnValue % 1 === 0 ? returnValue : Math.round(returnValue * 100) / 100;
	                    // let format = d3.format(',');
	                    if(id === 'BER') {
	                        returnYLabel = '%';
	                    }
	                    if(self.yLabel === 'R') {
	                        return `R ${newValue}`;
	                    }
	                    return `${newValue} ${returnYLabel}`;
	                }
	            }
	        },
	        zoom: {
	            enabled: true,
	            rescale: true
	        },
	        //Removed this to enable showing anomalies on line charts
	        //point: {
	        //show: false
	        //},
	        bar: {
	            width: {
	                ratio: self.ratio
	            }
	        },
	        axis: {
	            x: {
	                type: 'timeseries',
	                tick: {
	                    culling: true,
	                    count: 12,
	                    fit: true,

	                    // format: '%Y-%m-%d'
	                    format(d) {
	                        let resolution = self.selectedResolution.name;
	                        // let format = d3.time.format(self.xAxisTickFormat[resolution]);
	                        // return format(new Date(d));
	                        return self.moment(d).format(self.xAxisTickFormat[resolution]);
	                    }

	                }
	            },
	            y: {
	                label: {
	                    text: self.yLabel == 'Units' || self.yLabel == 'R' ? self.yLabel : `in ${self.yLabel}`,
	                    position: 'outer-middle'
	                },
	                tick: {
	                    format(value, ratio, id) {
	                        // let format = d3.format('.2f');
	                        let format = function(a) {
	                            return Math.round(a * 100) / 100;
	                        };
	                        if(self.yLabel === 'bytes') {
	                            // if (value < 1024) {
	                            //   return format(value) + 'B';
	                            // } else if (value < 1024 * 1024) {
	                            //   return format(value / 1024) + 'kB';
	                            //   }
	                            if(value < 1024 * 1024 * 1024) {
	                                return `${format(value / 1024 / 1024)}MB`;
	                            } else {
	                                return `${format(value / 1024 / 1024 / 1024)}GB`;
	                            }
	                        }
	                        return value;
	                    },
	                    culling: true
	                }
	            }
	        },
	        // regions:self.thisRegions,
	    });

	    return chart;
	}


	enableZoom() {
	    let self = this;
	    self.c3chart.zoom.enable(true);
	    //if(self.c3chart){
	    //if(self.zoomEnabled){
	    //self.zoomEnabled = false;
	    //self.c3chart.zoom.enable(false);
	    //}else {
	    //self.zoomEnabled = true;
	    //}
	    //}
	}
	disableZoom() {
	    self.c3chart.zoom.enable(false);
	}

	markAnomaly(unMarkAnomaly) {
	    let self = this;
	    let selected = self.c3chart.selected();
	    if(selected && selected.length > 0) {
	        if(['Total Data Usages', 'SIM1 Data Usages', 'SIM2 Data Usages'].includes(self.chart.name)) {
	            let metricData = _.groupBy(selected, 'id');
	            let allUpdates = [];
	            _.forEach(metricData, (val, key) => {
	                let metric;
	                if(key == 'Total') {
	                    metric = 'Data Usage';
	                } else if(key.includes('In')) {
	                    metric = 'Data Usage In';
	                } else {
	                    metric = 'Data Usage Out';
	                }
	                if(self.chart.name.includes('SIM1')) {
	                    metric += ' SIM1';
	                } else if(self.chart.name.includes('SIM2')) {
	                    metric += ' SIM2';
	                }

	                let dates = _.map(val, o => o.x);
	                let update = self.Restangular
	                    .one('metrics')
	                    .customPATCH({
	                        unitID: self.unitID,
	                        metric,
	                        collection: self.granularity,
	                        dates,
	                        unMarkAnomaly,
	                    }, 'setAnomaly');
	                allUpdates.push(update);
	            });
	            return Promise.all(allUpdates).then(() => {
	                self.c3chart.unselect();
	                self.throttleRefresh();
	            });
	        } else {
	            let dates = _.map(selected, o => o.x);
	            return self.Restangular
	                .one('metrics')
	                .customPATCH({
	                    unitID: self.unitID,
	                    metric: selected[0].name,
	                    collection: self.granularity,
	                    dates,
	                    unMarkAnomaly,
	                }, 'setAnomaly')
	                .then(result => {
	                    self.c3chart.unselect();
	                    self.throttleRefresh();
	                });
	        }
	    }
	}
}


export default angular.module('insideInfoApp.units')
    .component('unitdetailchart', {
        template: require('./units.detail.chart.html'),
        controller: UnitsDetailChartComponent,
        controllerAs: '$ctrl',
        bindings: {
            chartName: '@',
            chartIndex: '@',
            selectedRange: '<',
            selectedResolution: '<',
            activeRegions: '<',
            customRange: '<',
            regions: '<',
            resize: '=',
            zoomToFunction: '&',
        }
    })
    .name;

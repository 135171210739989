import angular from 'angular';
import uiRouter from 'angular-ui-router';
import Restangular from 'restangular';
import routes from './verify.routes';


export default angular.module('insideInfoApp.verify', [uiRouter, Restangular])
    .config(routes)
    .name;

require('./verify.email.component');


'use strict';

angular.module('SmartAdmin.Layout').directive('smartInclude', function() {
    return {
        replace: true,
        restrict: 'A',
        templateUrl(element, attr) {
            return attr.smartInclude;
        },
        compile(element) {
            element[0].className = element[0].className.replace(/placeholder[^\s]+/g, '');
        }
    };
}
);

export class ResetPasswordComponent {
  $stateParams;
  User;
  user;
  $log;
  url;
  verified = false;
  verifying = false;
  changed = false;
  changing = false;
  token;
  uid;

  /*@ngInject*/
  constructor($stateParams, User, $log) {
      this.$stateParams = $stateParams;
      this.User = User;
      this.$log = $log;
      this.url = this.$stateParams.next;
      this.user = {password: ''};
      this.token = this.$stateParams.token;
      this.uid = this.$stateParams.id;

	  if(this.token && this.uid) {
		  this.verifying = true;
		  this.User.resetPasswordTest({id: this.uid, link: this.token}).$promise.then(() => {
			  this.verified = true;
			  this.verifying = false;
		  }).catch(() => this.verifying = false);
	  }
  }

  changePassword(form) {
      this.submitted = true;
      this.changing = true;
      if(form.$valid) {
		  this.User.resetPassword({id: this.uid, link: this.token}, { password: this.user.password}).$promise.then(() => {
              this.changed = true;
              this.changing = false;
          });
      }
  }
}

export default angular.module('insideInfoApp.reset')
    .component('resetpassword', {
        template: require('./reset.password.html'),
        controller: ResetPasswordComponent,
        controllerAs: '$ctrl'
    })
    .name;

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.settings', {
            abstract: true,
            url: '/settings',
            authenticate: true,
            data: {
                title: 'Settings'
            }
        })

        .state('main.settings.user', {
            url: '/user',
            views: {
                'content@main': {
                    template: '<usersettings></usersettings>'
                }
            },
            authenticate: true,
            data: {
                title: 'User'
            }
        })

        .state('main.settings.user.changepassword', {
            url: '',
            views: {
                userTabView: {
                    template: '<changepassword></changepassword>'
                }
            },
            authenticate: true
        })

        .state('main.settings.account', {
            url: '/accounts',
            views: {
                'content@main': {
                    template: '<accountsettings></accountsettings>'
                }
            },
            authenticate: true,
            data: {
                title: 'Account'
            }
        });
}

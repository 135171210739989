import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import routes from './units.routes';
import Restangular from 'restangular';
import socket from '../../components/socket/socket.service';
import uiSelect from 'ui-select';
import uiGrid from 'angular-ui-grid';
import ngSanitize from 'angular-sanitize';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
// import ngTable from 'ng-table';

export default angular.module('insideInfoApp.units', [uiRouter, uiBootstrap, Restangular, 'btford.socket-io',
    uiSelect, 'ngSanitize', 'ngTable', 'ui.grid'])
    .config(routes)
    .name;

require('./units.list.component');
require('./units.detail.component');
require('./units.detail.chart.component');
require('./units.detail.charts.component');
require('./units.detail.clients.component');
require('./units.detail.manage.component');
require('./units.detail.heartbeats.component');
require('./units.detail.metricsum.component');
require('./directives/compile-template');
require('./ussd/ussd.modal.component');
require('./dataManagementModal/data-management.modal.component');
require('./dataManagement/data-management.component');
require('./dataManagement/errorWarningTable/error-warning-table.component');
require('./dataManagement/airtimeTable/airtime-table.component');

export class DataManagementModalComponent {
    /*@ngInject*/
    constructor(Restangular, Auth, toastr, NgTableParams, $ngConfirm, $scope, moment) {
        this.Auth = Auth;
        this.Restangular = Restangular;
        this.toastr = toastr;
        this.$scope = $scope;
        this.moment = moment;
        this.$ngConfirm = $ngConfirm;
        this.NgTableParams = NgTableParams;
        this.checked = {};
        this.rechargeList = [];
        this.airtimeAmount = 30;

    }

    $onInit() {
        let self = this;
        this.$uibModalInstance = this.modalInstance;

        this.currentUser = this.Auth.getCurrentUserSync();
        this.units = [];

        self.Restangular.all('units').all('dataManagementUnits').getList().then(function (units) {
            self.units = units;
            self.tableParams = new self.NgTableParams({
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    name: 'asc' // initial sorting
                }
            }, {
                dataset: self.units,
            });
        });
    }


    updateRechargeList(key) {
        //Check if active
        if (this.checked[key]) {
            //Add to list
            const split = key.split('_');
            const unitId = split[0];
            const sim = split[1]-1;
            const unit = _.find(this.units, (unit) => {
                return unit._id === unitId;
            })
            const simInfo = unit.simInfo[`sim${sim}`];
            const providerRaw = unit.status[`networkNameSIM${sim+1}`];
            const provider = providerRaw.match(/mtn/i) ? 'mtn' : providerRaw.match(/vodacom/i) ? 'vodacom' : undefined;
            if(!provider){
                _.remove(this.rechargeList, { id: key });
                this.toastr.error(`Could not determine network provider from ${providerRaw}`);
            }
            const formatted = {
                id: key,
                msisdn: simInfo.number.replace('+27', '0'),
                network: provider,
                value: 30,
                reference: `${unit.bcUnit.account.name} - ${unit.name}`
            }
            this.rechargeList.push(formatted);
        } else {
            //Remove from list
            _.remove(this.rechargeList, { id: key })
        }

    }

    exportSimControlCSV() {
        let self = this;
        this.airtimeAmount = 30;
        this.updateLastTopUpDate = true;
        this.$ngConfirm({
            title: `Airtime Amount`,
            content: require('./confirm-content.html'),
            scope: self.$scope,
            buttons: {
                ok: {
                    text: '<i class="fa fa-download"></i> Export',
                    btnClass: "btn-primary",
                    action: async function () {
                        //Export
                        try {
                            if (self.updateLastTopUpDate) {
                                await self.updateUnitsLastTopUpDate();
                            }
                            self.generateAndDownloadCSV();
                        } catch (error) {
                            console.error(error);
                            self.toastr.error('Export failed.');
                        }
                        self.close();
                    },
                },
                cancel: {
                    text: "Cancel",
                    btnClass: "btn-warning",
                    action() { },
                },
            },
        });

    }

    exportCSV() {

        const keys = [
            { key: "name", label: "Name" },
            { key: "online", label: "Online" },
            { key: "lastHB.0.ts", label: "Last Activity" },
            { key: "bcUnit.account.name", label: "BC Account Name" },
            { key: "simInfo.sim0.remainingAirtime", label: "Sim 1 Reamining Airtime" },
            { key: "simInfo.sim0.remainingAirtimeUpdated", label: "Sim 1 Remaining Airtime Updated" },
            { key: "simInfo.sim0.lastAirtimeTopUpDate", label: "Sim 1 Last Top-up Date" },
            { key: "simInfo.sim0.remainingData", label: "Sim 1 Reamining Data" },
            { key: "simInfo.sim0.remainingDataUpdated", label: "Sim 1 Reamining Data Updated" },
            { key: "bcUnit.sim1.number", label: "Sim 1 Number" },
            { key: "bcUnit.sim1.network", label: "Sim 1 Network" },
            { key: "simInfo.sim1.remainingAirtime", label: "Sim 2 Reamining Airtime" },
            { key: "simInfo.sim1.remainingAirtimeUpdated", label: "Sim 2 Reamining Airtime Updated" },
            { key: "simInfo.sim1.lastAirtimeTopUpDate", label: "Sim 2 Last Top-up Date" },
            { key: "simInfo.sim1.remainingData", label: "Sim 2 Reamining Data" },
            { key: "simInfo.sim1.remainingDataUpdated", label: "Sim 2 Reamining Data Updated" },
            { key: "bcUnit.sim2.number", label: "Sim 2 Number" },
            { key: "bcUnit.sim2.network", label: "Sim 2 Network" },
        ];

        this.generateAndDownloadCSV(this.units, keys);
    }

    updateUnitsLastTopUpDate() {
        let self = this;

        let promises = [];
        let date = new Date();
        let updateUnits = [];
        self.rechargeList.forEach((item) => {
            const key = item.id;
            const split = key.split('_');
            const unitId = split[0];
            let sim = split[1];
            if (sim == 'sim1') {
                sim = 'sim0'
            } else {
                sim = 'sim1'
            }
            let unit = _.find(updateUnits, (unit) => {
                return unit._id === unitId;
            })
            if (!unit) {
                unit = _.find(this.units, (unit) => {
                    return unit._id === unitId;
                })
                updateUnits.push(unit);
            }
            _.set(unit, `simInfo.${sim}.lastAirtimeTopUpDate`, date);
        })
        updateUnits.forEach((unit) => {
            promises.push(self.Restangular.one('units', unit._id).patch(unit));
        })
        return Promise.all(promises);
    }

    generateAndDownloadCSV(units = this.rechargeList, keys = [
        { key: 'msisdn', label: 'msisdn', },
        { key: 'network', label: 'network' },
        { key: 'value', label: 'value' },
        { key: 'reference', label: 'reference' },
    ]) {
        let self = this;
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += keys.map(k => k.label).join(',');
        csvContent += '\n';
        units.forEach(unit => {
            let row = '';
            keys.forEach(key => {
                row += _.get(unit, key.key, 'N/A');
                row += ',';
            });

            row.slice(0, -1);
            csvContent += row;
            csvContent += '\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `recharge_list_${self.moment().format()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    // exportDataXLSX() {
    //     let self = this;

    //     let arrs = [];
    //     let wb = xlsx.utils.book_new();

    //     let formatPivots = [];
    //     let accountPivots = {};
    //     let headerLabels = _.map(self.cols, (col) => {
    //         let title = col.title;
    //         if(col.unit) title += ` (${col.unit})`;
    //         return title;
    //     });
    //     let headers = _.map(self.cols, 'field');
    //     let columnWidths = [];
    //     headerLabels.forEach(label => {
    //         columnWidths.push(label.length);
    //     });

    //     _.forEach(pivots, pivot => {
    //         let formattedPivot = self.formatPivot(pivot);

    //         _.forEach(this.cols, (col, index) => {
    //             columnWidths[index] = Math.max(columnWidths[index], formattedPivot[col.field]?.length || 0);
    //         });

    //         if(accountPivots[pivot.account.ref] == undefined) {
    //             accountPivots[pivot.account.ref] = {
    //                 name: pivot.account.name,
    //                 pivots: [],
    //             };
    //         }
    //         accountPivots[pivot.account.ref].pivots.push(_.omit(formattedPivot, ['account']));
    //         formatPivots.push(formattedPivot);
    //     });

    //     arrs.push(headerLabels);
    //     let ws = xlsx.utils.aoa_to_sheet(arrs);
    //     let wscols = _.map(columnWidths, (width) => {wch: width})

    //     ws['!cols'] = wscols;
    //     xlsx.utils.sheet_add_json(ws, formatPivots, { header: headers, skipHeader: true, origin: arrs.length });
    //     xlsx.utils.book_append_sheet(wb, ws, "All accounts");

    //     _.forEach(accountPivots, account => {
    //         arrs = [];
    //         arrs.push(headerLabels);
    //         ws = xlsx.utils.aoa_to_sheet(arrs);
    //         ws['!cols'] = wscols;

    //         xlsx.utils.sheet_add_json(ws, account.pivots, { header: headers, skipHeader: true, origin: arrs.length });
    //         xlsx.utils.book_append_sheet(wb, ws, `${account.name}`);
    //     });


    //     xlsx.write(wb, { bookType: "xlsx", bookSST: true, type: 'base64' });
    //     xlsx.writeFile(wb, `Pivot_Configurations_${self.moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`);
    // }

    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }
    doLog() {
        console.debug(this);
    }
}
export default angular.module('insideInfoApp.units')
    .component('airtimeManagementModal', {
        template: require('./data-management.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<',
            settings: '<'
        },
        controller: DataManagementModalComponent,
        controllerAs: '$ctrl'
    })
    .name;

'use strict';
angular.module('insideInfoApp.settings')
    .directive('tableDraggable', function($compile) {
        return {
            link(scope, elem, attr) {
                elem.attr('draggable', true);
                elem.attr('tableDraggable', true);
                let tableHeaders;
                let tableID = attr.id;
                let dragStartColumn;
                let dragDropColumn;
                let showedColumns = {};
                let wait = false;
                let cellEnteredInto = {};

                elem.on('dragstart', function dragstart(event) {
                    updateShowedColumns();
                    let hoveredColumnIndex = getCurrentCellColumn();

                    if(hoveredColumnIndex !== '-1' || undefined) {
                        tableHeaders = angular.element(document.getElementsByClassName('header'));
                        tableHeaders.addClass('dropzone');
                        let colTrueIndex = fetchColTrueIdx(hoveredColumnIndex);
                        dragStartColumn = colTrueIndex;

                        if(document.getElementById('dragTable') !== null) {
                            document.getElementById('dragTable').remove();
                            // Removes the previous dragImage if one exists.
                        }

                        if(event.dataTransfer) { //Some browsers have e.dataTransfer
                            var dragTable = document.createElement('table');
                            constructDragTable(dragTable, colTrueIndex);
                            document.body.appendChild(dragTable);
                            event.originalEvent.dataTransfer.setData('text', '');
                            event.originalEvent.dataTransfer.setDragImage(dragTable, 25, 25);
                        } else if(event.originalEvent.dataTransfer) { //Some browsers have e.originalEvent.dataTransfer
                            var dragTable = document.createElement('table');
                            constructDragTable(dragTable, colTrueIndex);
                            document.body.appendChild(dragTable);
                            event.originalEvent.dataTransfer.setData('text', '');
                            event.originalEvent.dataTransfer.setDragImage(dragTable, 25, 25);
                        }
                    } else {
                        event.preventDefault();
                        return;
                    }
                });

                elem.on('dragend', function onDrop(event) {
                    dragDropColumn = fetchColTrueIdx(getCurrentCellColumn());
                    if(dragDropColumn !== '-1') {
                        moveColumns(dragStartColumn, dragDropColumn);
                    }
                    if(cellEnteredInto.lastTarget !== undefined) {
                        cellEnteredInto.lastTarget.style.background = '';
                        cellEnteredInto = {};
                    }
                    $(tableHeaders).removeClass('dropzone');
                });

                elem.on('dragenter', function dragEnter(event) {
                    if(event.target.className.includes('dropzone')) {
                        if(Object.keys(cellEnteredInto).length === 0) {
                            cellEnteredInto.cellIndex = event.target.cellIndex;
                            cellEnteredInto.lastTarget = event.target;
                        } else if(event.target.cellIndex !== cellEnteredInto.cellIndex) {
                            cellEnteredInto.lastTarget.style.background = '';
                            event.target.style.background = 'rgba(2,100,100,0.15)';
                            cellEnteredInto.cellIndex = event.target.cellIndex;
                            cellEnteredInto.lastTarget = event.target;
                        }
                    }
                });

                /**
         * This function finds the cell-index of the element you're clicking on.
         * It's important to note that it yields a value based on the CURRENT layout
         * of the table because it inspects the HTML.
         * @return {number} The cell-index
         */
                function getCurrentCellColumn() {
                    let onHoverElements = document.querySelectorAll(':hover');
                    let currentCell = onHoverElements[onHoverElements.length - 1];

                    if(currentCell.nodeName === 'TH') {
                        currentCell = currentCell.cellIndex;
                        //if clicking somewhere in the additional row created by adding a filter
                    } else if(currentCell.nodeName === 'DIV' && currentCell.parentElement.nodeName === 'TH') {
                        currentCell = currentCell.parentElement.cellIndex;
                        //if clicking somewhere in a header-column
                    } else if(currentCell.nodeName === 'SPAN' && currentCell.parentElement.parentElement.nodeName === 'TH') {
                        currentCell = currentCell.parentElement.parentElement.cellIndex;
                        //if clicking on the header-text
                    } else {
                        return '-1';
                    }
                    return currentCell;
                }

                /**
         * This function iterates through all columns and compiles a data-map
         * of columns that are currently shown to the user.
         */
                function updateShowedColumns() {
                    for(let i = 0, j = 0; i < scope.$columns.length; i++) {
                        if(scope.$columns[i].__proto__.show) {
                            showedColumns[i] = j;
                            j++;
                        } else {
                            showedColumns[i] = -1;
                        }
                    }
                }

                /**
         * This function receives a cellIndex value that only takes in consideration
         * visible columns. It must now consider which columns are visible and return the corresponding
         * index from the entire collection of columns (including ones not shown in HTML).
         * @param  {number} index
         * @return {number}
         */
                function fetchColTrueIdx(index) {
                    if(showedColumns === {}) {
                        updateShowedColumns();
                    }

                    for(let i = 0; i < Object.keys(showedColumns).length; i++) {
                        if(showedColumns[i] === index) {
                            return i;
                        }
                    }
                }

                /**
         * Gets the necessary data for each row
         * @param  {number} colTrueIndex
         * @return {array} result
         *
         * * NOTE: The getValue function is very important. If your table
         * has a function that handles its values, be sure to use the same
         * name [getValue].
         */
                function getDragImageData(colTrueIndex) {
                    if(colTrueIndex === undefined) {
                        console.error('Cannot identify the column to drag from');
                    }
                    let field = scope.$columns[colTrueIndex].field;
                    let result = [];

                    angular.forEach(scope.$data, dataEl => {
                        if(scope.$columns[colTrueIndex].getValue) {
                            let getValue = scope.$columns[colTrueIndex].getValue;
                            result.push(getValue(scope.$columns[colTrueIndex].context, scope.$columns[colTrueIndex], dataEl));
                        } else if(dataEl[field] !== undefined) {
                            result.push(dataEl[field]);
                        }
                    });

                    return result;
                }

                function constructDragTable(dragTable, colTrueIndex) {
                    //Fetches data for the dragTable
                    let dragImageData = getDragImageData(colTrueIndex);

                    dragTable.style.position = 'absolute';
                    dragTable.style.left = '5000px'; //to put the table out of sight
                    dragTable.style.top = '200px'; //to make sure the table isn't appended at the bottom - it causes vertical scroll.
                    dragTable.style.width = '150px'; //to prevent the table to stretch across the entire screen
                    dragTable.style.zIndex = 5; //to let the dragTable appear in front of other elements.
                    dragTable.id = 'dragTable'; //to be able to uniquely identify and alter the table

                    /**
           * Adds the header to the dragTable
           */
                    let columnName = scope.$columns[colTrueIndex].__proto__.title;
                    let header = dragTable.createTHead();
                    let row = header.insertRow(0);
                    let cell = row.insertCell(0);
                    cell.innerHTML = `<b>${columnName}<b>`;

                    /**
           * Adds the body and populates it
           */
                    let body = dragTable.createTBody();
                    for(var i = dragImageData.length - 1; i >= 0; i--) {
                        let row = body.insertRow(0);
                        let cell = row.insertCell(0);
                        cell.innerHTML = dragImageData[i];
                    }

                    /**
           * Classes to be added to the table
           */
                    dragTable.classList.add('table');
                    dragTable.classList.add('table-bordered');
                }

                /**
         * This function handles the physical rearrangement of $columns
         * @param  {number} oldIndex [description]
         * @param  {number} newIndex [description]
         */
                function moveColumns(oldIndex, newIndex) {
                    let tempColumn = scope.$columns[oldIndex];
                    let difference = newIndex - oldIndex;
                    let current = oldIndex;

                    if(difference > 0) {
                        while(difference > 0) {
                            scope.$columns[current] = scope.$columns[current + 1];
                            current++;
                            difference--;
                        }
                        scope.$columns[current] = tempColumn;
                    } else if(difference < 0) {
                        while(difference < 0) {
                            scope.$columns[current] = scope.$columns[current - 1];
                            current--;
                            difference++;
                        }
                        scope.$columns[current] = tempColumn;
                    }
                }
            }
        };
    });

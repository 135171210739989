'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider
        .state('main.alerts', {
            abstract: true,
            url: '/alerts',
            data: {
                title: 'Alerts'
            },
            authenticate: true
            //template: '<alerts></alerts>'
        })
        .state('main.alerts.active', {
            url: '/active',
            views: {
                'content@main': {
                    template: '<alertsactive></alertsactive>'
                }
            },
            data: {
                title: 'Active'
            },
            authenticate: true
        })
        .state('main.alerts.closed', {
            url: '/closed',
            views: {
                'content@main': {
                    template: '<alertsclosed></alertsclosed>'
                }
            },
            data: {
                title: 'Closed'
            },
            authenticate: true
        })
        .state('main.alerts.manage', {
            url: '/manage',
            views: {
                'content@main': {
                    template: '<alertssettings></alertssettings>'
                }
            },
            data: {
                title: 'Manage'
            },
            authenticate: true
        });
}

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './settings.routes';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';

// import NgTable from 'ng-table';

export default angular.module('insideInfoApp.settings', [uiRouter, 'ngTable', 'ngSanitize'])
    .config(routes)
    .name;

require('./settings.user.component');
require('./settings.user.changepassword.component');
require('./settings.account.component');
require('./directives/draggable.directive.js');

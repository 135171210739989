'use strict';
// @flow
import hasprivileges from 'hasprivileges';

class _User {
	_id: string = '';
	name: string = '';
	email: string = '';
	role: string = '';
	$promise = undefined;
}

class _Account {
	name: string = '';
	// accountID: string = '';
	accountName: string = '';
	privileges: Object = {};
	$promise = undefined;
}

export function AuthService($location, $http, $cookies, $q, appConfig, Util, User, $state, $stateParams, Idle, Keepalive, $uibModal, $rootScope, $localStorage) {
    'ngInject';

    let safeCb = Util.safeCb;
    let currentUser: _User = new _User();
    let currentAccount: _Account = new _Account();
    let fullCurrentAccount;
    let userRoles = appConfig.userRoles || [];

    let warning;
    let timedout;
    let started = false;

    ///////
    let idleDuration = 5 * 60;
    //  How long before a warning-dialog should show? NOTE: This is a default value incase none gets specified in the DB
    //////

    ///////
    let timeoutDuration = 30;
    //  How many seconds before logged off. NOTE: The value for timeoutDuration gets stored here and not in the DB.
    //////

    //////
    let keepaliveInterval = 15 * 60;
    //  How regular tokens are refreshed NOTE: This is a default value incase none gets specified in the DB
    //////


    /**
	 * Check if userRole is >= role
	 * @param {String} userRole - role of current user
	 * @param {String} role - role to check against
	 */
    var hasRole = function(userRole, role) {
        return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    };

    if($cookies.get('token') && $location.path() !== '/logout') {
        currentUser = User.get();
    }

    /** TODO: This method can possibly be structured more effectively.
	 * [Sets the user's current active account]
	 * @param {[type]} newCurrentAccount [description]
	 */
    function setCurrentAccountFn(newCurrentAccount) {
        if(newCurrentAccount) {
            currentAccount = findAccountById(currentUser, newCurrentAccount);
            $localStorage.currentAccount = currentAccount.ref;
        } else {
            let stateAccountID;
            if ($location && $location.$$url && $location.$$url.split('/')[1]) {
				stateAccountID = $location.$$url.split('/')[1];
			}
            if (stateAccountID && findAccountById(currentUser, stateAccountID)) {
				currentAccount = findAccountById(currentUser, stateAccountID);
				$localStorage.currentAccount = currentAccount.ref;
			} else if ($localStorage.currentAccount && findAccountById(currentUser, $localStorage.currentAccount)) {
				currentAccount = findAccountById(currentUser, $localStorage.currentAccount);
			} else if (currentUser.accounts && currentUser.accounts.length > 0) {
				currentAccount = currentUser.accounts[0];
				$localStorage.currentAccount = currentUser.accounts[0].ref;
			} else {
				console.warn("No accounts : ", currentUser.accounts);
			}
        }
        
        if(!$state.current.abstract && $state.$current.name !== 'login' && $state.$current.name !== '') {
            $state.go('.', {
                accountID: currentAccount.ref
            }, {
                reload: true
            })
                .catch(err => {
                    console.error('State.go(.) catch error : ', err);
                });
        } else {
            $stateParams.accountID = currentAccount.ref;
        }
        $http.get(`/api/accounts/${currentAccount.ref}`).then((response) => {
			fullCurrentAccount = response.data;
		})
        $rootScope.$emit('accountChanged');
    }

    /**
	 * Finds and returns a specified account object of a user
	 * @param  {Object} user  [The user from whom we retrieve the account]
	 * @param  {String} accId [The ID of the account to retrieve]
	 * @return {Object}       [The account-object after found]
	 */
    function findAccountById(user, accId) {
        if(user && user.accounts && user.accounts.length > 0) {
            let index = user.accounts.findIndex(account => account.ref == accId);
            return user.accounts[index];
        } else {

        }
    }

    /**
	 * Starts the Idle-service that watches for inactivity
	 * @param  {Number} userIdle User's idleDuration
	 * @param  {Number} userKeep User's Keepalive
	 */
    function startIdleFn(userIdle, userKeep) {
        if(userIdle) {
            Idle.setIdle(userIdle);
        } else {
            Idle.setIdle(idleDuration);
        }
        if(userKeep) {
            Keepalive.setInterval(userKeep);
        } else {
            Keepalive.setInterval(keepaliveInterval);
        }
        Idle.setTimeout(timeoutDuration);
        Auth.closeModals();
        Idle.watch(); //also starts Keepalive
        started = true;
    }

    function stopIdle() {
        Auth.closeModals();
        Idle.unwatch();
        started = false;
    }

    var Auth = {
        /**
		 * Authenticate user and save token
		 *
		 * @param  {Object}   user     - login info
		 * @param  {Function} callback - function(error, user)
		 * @return {Promise}
		 */
        login({
            email,
            password
        }, callback ? : Function) {
            return $http.post('/auth/local', {
                email,
                password
            })
                .then(res => {
                    $cookies.put('token', res.data.token);
                    currentUser = User.get();
                    return currentUser.$promise;
                })
                .then(user => {
                    // startIdleFn(user.idleDuration, user.tokenTTL);
                    setCurrentAccountFn();
                    safeCb(callback)(null, user);
                    return user;
                })
                .catch(err => {
                    console.error('login error at auth.service: ', err);
                    Auth.logout();
                    safeCb(callback)(err.data);
                    return $q.reject(err.data);
                });
        },

        /**
		 * Delete access token and user info
		 */
        logout() {
            stopIdle();
            $cookies.remove('token');
            currentUser = new _User();
            currentAccount = new _Account();

            if($state.current.name !== 'login') {
                $state.go('login')
                    .catch(err => {});
            }
        },

        /**
		 * Create a new user
		 *
		 * @param  {Object}   user     - user info
		 * @param  {Function} callback - function(error, user)
		 * @return {Promise}
		 */
        createUser(user, callback ? : Function) {
            return User.save(user, function(data) {
                $cookies.put('token', data.token);
                currentUser = User.get();
                currentAccount.$promise = currentUser.$promise; //note -> The Secuvue had 'currentAcc'
                return safeCb(callback)(null, user);
            }, function(err) {
                Auth.logout();
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
		 * Change password
		 *
		 * @param  {String}   oldPassword
		 * @param  {String}   newPassword
		 * @param  {Function} callback    - function(error, user)
		 * @return {Promise}
		 */
        changePassword(oldPassword, newPassword, callback ? : Function) {
            return User.changePassword({
                id: currentUser._id
            }, {
                oldPassword,
                newPassword
            }, function() {
                return safeCb(callback)(null);
            }, function(err) {
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
		 * Gets all available info on a user
		 *
		 * @param  {Function} [callback] - function(user)
		 * @return {Promise}
		 */
        getCurrentUser(callback ? : Function) {
            var value = _.get(currentUser, '$promise') ? currentUser.$promise : currentUser;

            return $q.when(value)
                .then(user => {
                    safeCb(callback)(user);
                    return user;
                }, () => {
                    safeCb(callback)({});
                    return {};
                });
        },

        /**
		 *
		 */
        getCurrentAccount(callback ? : Function) {
            var value = _.get(currentAccount, '$promise')
                ? currentAccount.$promise
                : currentAccount;

            return $q.when(value)
                .then(user => {
                    //TODO: use localstorage/url state param...
                    //TODO: Secure localstorage(store id, get privileges from mongo)..
                    setCurrentAccountFn();

                    safeCb(callback)(currentUser.accounts[0]);
                    return currentAccount;
                }, () => {
                    safeCb(callback)({});
                    return {};
                });
        },

        /**
		 * Gets all available info on a user
		 *
		 * @return {Object}
		 */
        getCurrentUserSync() {
            return currentUser;
        },

        /**
		 * Check if a user is logged in
		 *
		 * @param  {Function} [callback] - function(is)
		 * @return {Promise}
		 */
        isLoggedIn(callback ? : Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    let is = false;
                    if(user._id) {
                        is = true;
                        if(currentAccount.ref) {
                            safeCb(callback)(is);
                            return is;
                        } else {
                            return Auth.getCurrentAccount().then(currentAccount => {
                                safeCb(callback)(is);
                                return is;
                            });
                        }
                    } else {
                        safeCb(callback)(is);
                        return is;
                    }
                });
        },

        /**
		 * Check if a user is logged in
		 *
		 * @return {Bool}
		 */
        isLoggedInSync() {
            return !!_.get(currentUser, 'role');
        },

        /**
		 * Check if a user has a specified role or higher
		 *
		 * @param  {String}     role     - the role to check against
		 * @param  {Function} [callback] - function(has)
		 * @return {Promise}
		 */
        hasRole(role, callback ? : Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    let has = hasRole(_.get(user, 'role'), role);

                    safeCb(callback)(has);
                    return has;
                });
        },

        /**
		 * Check if a user has a specified privilege for a desired action
		 *
		 * @param  {String}     desiredPrivilege     - the privilege to check against
		 */
        hasPrivilege(path, test, callback ? : Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    if(currentAccount.accountId) {
                        let has = hasprivileges.hasPrivilegeFunc(path, currentAccount.privileges, test);
                        safeCb(callback)(has);
                        return has;
                    } else {
                        return Auth.getCurrentAccount().then(currentAccount => {
                            let has = hasprivileges.hasPrivilegeFunc(path, currentAccount.privileges, test);
                            safeCb(callback)(has);
                            return has;
                        });
                    }
                });
        },

        /**
		 * Check if a user has a specified role or higher
		 *
		 * @param  {String} role - the role to check against
		 * @return {Bool}
		 */
        hasRoleSync(role) {
            return hasRole(_.get(currentUser, 'role'), role);
        },

        /**
		 * Check if a user is an admin
		 *   (synchronous|asynchronous)
		 *
		 * @param  {Function|*} callback - optional, function(is)
		 * @return {Bool|Promise}
		 */
        isAdmin(...args) {
            return Auth.hasRole(...Reflect.apply([].concat, ['admin'], args));
        },


        /**
		 * Check if a user has been verified
		 */
        isVerified() {
            return currentUser.verified === true;
        },

        /**
		 * Check if a user is an admin
		 *
		 * @return {Bool}
		 */
        isAdminSync() {
            // eslint-disable-next-line no-sync
            return Auth.hasRoleSync('admin');
        },

        /**
		 * Get auth token
		 *
		 * @return {String} - a token string used for authenticating
		 */
        getToken() {
            return $cookies.get('token');
        },

        getCurrentAccountSync() {
            return currentAccount;
        },

        /**
		 * Refreshes the user's current token
		 */
        refreshToken() {
            return $http.post('/api/users/refreshToken', {
                _id: currentUser._id
            }, {
                headers: {
                    'X-JS-ACCOUNT': currentAccount.ref
                }
            })
                .then(res => {
                    $cookies.put('token', res.data.token);
                    Auth.getCurrentUser().then(user => {
                        Auth.stopIdle();
                        Auth.startIdle(user.idleDuration, user.tokenTTL / 2);
                    });
                })
                .catch(err => {
                    console.error('Error with refreshing token:', err.data);
                });
        },


        /**
		 * Check if a user has the necessary privilege to execute a desired action
		 * @param  {String}  desiredPrivilege [the necessary privilege to check for]
		 * @return {Boolean}
		 */
        hasPrivilegeSync(path, test) {
            let has = hasprivileges.hasPrivilegeFunc(path, currentAccount.privileges, test);

            return has;
        },

        closeModals() {
            if(warning) {
                warning.close();
                warning = null;
            }

            if(timedout) {
                timedout.close();
                timedout = null;
            }
        },
        startIdle: startIdleFn,
        setCurrentAccount: setCurrentAccountFn
    };

    $rootScope.$on('IdleStart', function() {
        Auth.closeModals();

        warning = $uibModal.open({
            template: require('./inactive/warning-dialog.html'),
            windowClass: 'modal-info',
            resolve: {
                data() {
                    return timeoutDuration;
                }
            },
            controller($uibModalInstance, data) {
                this.data = data;
                this.countdown = 0;
            },
            controllerAs: '$ctrl'
        });
    });

    $rootScope.$on('Keepalive', function() {
        Auth.refreshToken();
    });

    $rootScope.$on('IdleEnd', function() {
        Auth.closeModals();
    });

    $rootScope.$on('IdleTimeout', function() {
        Auth.closeModals();
        timedout = $uibModal.open({
            templateUrl: 'components/auth/inactive/timedout-dialog.html',
            windowClass: 'modal-info',
            backdrop: 'static',
            controller($uibModalInstance) {
                this.expanded = false;
                this.close = function() {
                    $uibModalInstance.close();
                };
                this.expand = function() {
                    this.expanded = !this.expanded;
                };
            },
            controllerAs: '$ctrl'

        });
        timedout.result.then(result => {
            this.$uibModalInstance.close(result);
        });
        Auth.logout();
    });

    // if ($cookies.get('token') && $location.path() !== '/logout') {
    // 	currentUser = User.get();
    // 	currentAccount.$promise = currentUser.$promise;
    // 	currentUser.$promise.then((user) => {
    // 		startIdleFn(currentUser.idleDuration, (currentUser.tokenTTL / 2));
    // 		Auth.refreshToken();
    // 		Auth.setCurrentAccount();
    // 	})
    // }

    return Auth;
}

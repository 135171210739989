export class ContactModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	availableGroups;
	contact;
	Contacts;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular) {
	    this.$stateParams = $stateParams;
	    this.Restangular = Restangular;
	}

	$onInit() {
	    let self = this;
	    this.settings = this.resolve.settings;
	    this.$uibModalInstance = this.modalInstance;
	    // Add alert data to controller
	    if(this.settings.item) {
	        let item = this.settings.item;
	        this.contact = this.Restangular.copy(item);
	    } else {
	        this.contact = {
	            account: this.settings.account,
	            groups: []
	        };
	    }
	    this.Contacts = this.Restangular.all('contacts');
	    this.Groups = this.Contacts.all('groups');
	    this.availableGroups = [];
	}


	refreshGroups() {
	    let self = this;
	    if(this.$stateParams.accountID) {
	        this.Groups.getList({
	            accountID: this.$stateParams.accountID
	        }).then(function(groups) {
	            self.availableGroups = groups;
	        });
	    }
	}
	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
	    let result = this.contact;
	    let label = ' (create new group)';
	    let tempGroups = [];
	    result.groups.forEach(group => {
	        if(group.slice(-label.length) === label) {
	            group = group.slice(0, -label.length);
	        }
	        tempGroups.push(group);
	    });
	    result.groups = tempGroups;
	    this.$uibModalInstance.close(result);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
	    this.$uibModalInstance.dismiss('Cancel pressed');
	}
}

export default angular.module('insideInfoApp.contacts')
    .component('contactmodal', {
        template: require('./contacts.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        },
        controller: ContactModalController,
        controllerAs: '$ctrl'
    })
    .name;
// .controller(ContactModalController).name;

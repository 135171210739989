'use strict';

import * as _ from 'lodash-es';
import angular from 'angular';
import io from 'socket.io-client';

function Socket(socketFactory, Auth, $log) {
    'ngInject';
    // socket.io now auto-configures its connection when we ommit a connection url


    var ioSocket = io('', {
        // Send auth token on connection, you will need to DI the Auth service above
        // 'query': 'token=' + Auth.getToken()
        query: `token=${Auth.getToken()}`,
        path: '/socket.io-client',
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000
    });


    var socket = socketFactory({
        ioSocket
    });
    var rooms = {};

    socket.ioSocket = ioSocket;

    socket.on('error', function(error) {
        if(error.type === 'UnauthorizedError' || error.code == 'invalid_token') {
            // redirect user to login page perhaps?
            $log.error('User\'s token has expired');
        }
    });
    socket.on('reconnect', attempt => {
        for(var roomName in rooms) {
            socket.emit('join', {room: roomName});
        }
    });

    var listenerMap = new Map();

    return {
        socket,
        rooms,

        /**
		 * Register listeners to sync an array with updates on a model
		 *
		 * Takes the array we want to sync, the model name that socket updates are sent from,
		 * and an optional callback function after new items are updated.
		 *
		 * @param {String} modelName
		 * @param {Array} array
		 * @param {Function} cb
		 * @param {Function} context this is for removing listeners properly
		 * @param {Function} merge this i for making the merge optionally
		 * @param {Function} append this is for appending instead of prepending
		 */
        syncUpdates(modelName, array, cb, context, merge, append) {
            cb = cb || angular.noop;
            append = append !== false;

            /**
			* Syncs item creation/updates on 'model:save'
			*/
            let saveFunc = function(item) {
                var oldItem = _.find(array, {
                    _id: item._id
                });
                var index = array.indexOf(oldItem);
                var event = 'created';

                if(merge === true && index >= 0) {
                    _.mergeWith(item, oldItem, (objValue, srcValue) => {
  					if(_.isArray(objValue)) {
    					return _.unionBy(objValue, srcValue, 'accountId');
  					}
                    });
                }

                // replace oldItem if it exists
                // otherwise just add item to the collection
                if(oldItem) {
                    array.splice(index, 1, item);
                    event = 'updated';
                } else if(append) {
                    array.push(item);
                } else {
                    array.unshift(item);
                }

                cb(event, item, array);
            };
            socket.on(`${modelName}:save`, saveFunc);

            let removeFunc = function(item) {
                var oldItem = _.find(array, {
                    _id: item._id
                });
                var index = array.indexOf(oldItem);
                var event = 'deleted';
                if(merge == true && index >= 0) {
                    _.mergeWith(item, oldItem, (objValue, srcValue) => {
  					if(_.isArray(objValue)) {
                            let tempIn = _.findIndex(srcValue, o => o.accountId == objValue[0].accountId);
                            srcValue.splice(tempIn, 1);
							 return srcValue;
  					}
                    });
                    if(item.accounts && item.accounts.length > 0) {
                        event = 'updated';
                        if(oldItem) {
                            array.splice(index, 1, item);
                        }
                    } else {
                        _.remove(array, {
                            _id: item._id
                        });
                    }
                } else {
                    _.remove(array, {
                        _id: item._id
                    });
                }
                cb(event, item, array);
            };
            /**
			* Syncs removed items on 'model:remove'
			*/
            socket.on(`${modelName}:remove`, removeFunc);

            if(typeof context !== 'undefined') {
                let listeners = listenerMap.get(context);
                if(listeners) {
                    socket.removeListener(`${modelName}:save`, listeners.save);
                    socket.removeListener(`${modelName}:remove`, listeners.remove);
                }
                listenerMap.set(context, {save: saveFunc, remove: removeFunc});
            }
        },

        /**
		* Removes listeners for a models updates on the socket
		*
		* @param modelName
		*/
        unsyncUpdates(modelName, context) {
            if(typeof context !== 'undefined') {
                let listeners = listenerMap.get(context);
                if(listeners) {
                    socket.removeListener(`${modelName}:save`, listeners.save);
                    socket.removeListener(`${modelName}:remove`, listeners.remove);
                    return;
                }
            }
            socket.removeAllListeners(`${modelName}:save`);
            socket.removeAllListeners(`${modelName}:remove`);
        },

        reAuth() {
            socket.ioSocket.query = `token=${Auth.getToken()}`;
            socket.ioSocket.io.opts.query = `token=${Auth.getToken()}`;
            let onclose = () => {
                socket.ioSocket.io.connect();
                socket.ioSocket.io.removeListener('close', onclose);
            };
            socket.ioSocket.io.on('close', onclose);
            socket.ioSocket.io.disconnect();
        },
        /**
		 * Joins a room
		 *
		 * @param roomName
		 */
        joinRoom(roomName) {
            rooms[roomName] = rooms[roomName] ? rooms[roomName] + 1 : 1;
            socket.emit('join', {room: roomName});
        },

        /**
		 * Leaves a room
		 *
		 * @param roomName
		 */
        leaveRoom(roomName) {
            rooms[roomName] = rooms[roomName] ? rooms[roomName] - 1 : 0;
            if(!rooms[roomName]) {
                socket.emit('leave', {room: roomName});
                delete rooms[roomName];
            }
        }
    };
}

export default angular.module('insideInfoApp.socket', [])
    .factory('socket', Socket)
    .name;

'use strict';

export function authInterceptor($rootScope, $q, $cookies, $injector, Util) {
    'ngInject';

    var state;
    return {
    // Add authorization token to headers
        request(config) {
            let Auth = $injector.get('Auth');
            let $stateParams = $injector.get('$stateParams');

            config.headers = config.headers || {};
            if($cookies.get('token') && Util.isSameOrigin(config.url)) {
                config.headers.Authorization = `Bearer ${$cookies.get('token')}`;
            }

            // TODO: The following code might be improved slightly. Consider if doing so
            // would justify the benefit
            if($stateParams.accountID) {
                config.headers['X-JS-ACCOUNT'] = $stateParams.accountID;
            } else if(!config.headers['X-JS-ACCOUNT']) {
                if(Auth.getCurrentAccountSync()) {
                    config.headers['X-JS-ACCOUNT'] = Auth.getCurrentAccountSync().ref;
                }
            }

            return config;
        },

        response(response) {
            let Auth = $injector.get('Auth');
            if(response.headers('X-JS-REFRESH-TOKEN') === 'true') {
                _.throttle(function() {
                    Auth.refreshToken();
                    Auth.refreshUser().then(user => {
                        Auth.setCurrentAccount();
                    });
                }, 1000);
            }
            return response;
        },

        /**
     * Intercept 401s and redirect you to login
     */
        responseError(response) {
            let Auth = $injector.get("Auth");
            if(response.status === 401) {
                (state || (state = $injector.get('$state')))
                    .go('login');
                // remove any stale tokens
                $cookies.remove('token');
            } else if (response.status === 403) {
                if (response.data === "Email not verified") {
                  let user = Auth.getCurrentUserSync();
                    (state || (state = $injector.get("$state"))).go("verify", {id:user._id, token:null});
                }
            }
            return $q.reject(response);
        }
    };
}

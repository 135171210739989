
export class ErrorWarningTable {
    /*@ngInject*/
    constructor(NgTableParams, Restangular, toastr, $ngConfirm, $scope, $uibModal) {
        this.NgTableParams = NgTableParams;
        this.Restangular = Restangular;
        this.toastr = toastr;
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        this.data = this.data || [];
        this.simFilterData = [{
            id: 1,
            title: 'Sim 1'
        },
        {
            id: 2,
            title: 'Sim 2'
        }];
        this.infoType = this.infoType || 'warnings';

        this.unWatch = this.$scope.$watch(() => this.data, () => {
            this.resetTable();
        });
    }

    $onDestroy() {
        this.unWatch();
    }

    resetTable() {
        const settings = {
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc' // initial sorting
            },
            ...{
                page: this.tableParams?.page(),
                sorting: this.tableParams?.sorting(),
                filter: this.tableParams?.filter(),
            }
        };
        this.formattedData = this.data.reduce((acc, data) => {
            if(data[this.infoType]) {
                for(const issue of data[this.infoType]) {
                    acc.push({ ...issue, ...data });
                }
            }
            return acc;
        }, []);
        this.tableParams = new this.NgTableParams(settings, {
            dataset: this.formattedData,
        });
    }

    swapSim(unit) {
        this.$ngConfirm({
            title: 'Sim Swap',
            content: `
				Are you sure you want to swap to SIM ${unit.lastHB?.status?.sim ? 1 : 2}.
			  	The unit could take some time to reconnect.
			`,
            buttons: {
                ok: {
                    text: 'Confirm',
                    btnClass: 'btn-primary',
                    action: async() => {
                        //Export
                        try {
                            await this.Restangular.one('units', unit._id).customPOST({}, 'swapSim');
                            this.toastr.info('Sim swap requested.');
                        } catch(error) {
                            console.error(error);
                            this.toastr.error('Sim swap request failed.');
                        }
                    },
                },
                cancel: {
                    text: 'Cancel',
                    btnClass: 'btn-warning',
                    action() { },
                },
            },
        });
    }

    numberCheckRequest(unit) {
        let ussdRequest = {
            numberCheck: true,
        };
        this.Restangular.one('units', unit._id).all('ussd')
            .post(ussdRequest)
            .then(() => {
                this.toastr.info('USSD Code sent');
            })
            .catch(err => {
                console.error(err);
                this.toastr.error(err.data || err, 'USSD Request failed');
            });
    }

    getStatus(unit) {
        this.Restangular.one('units', unit._id).customPOST({}, 'status')
            .then(() => {
                this.toastr.info('Status request sent.', 'Successful');
            })
            .catch(err => {
                this.toastr.error(`Status request failed : ${err?.message}`, 'Error');
            });
    }

    async openUSSDModal(unitId) {
        const unit = await this.Restangular.one('unit', unitId);
        this.modalInstance = this.$uibModal.open({
            component: 'ussdModal',
            bindToController: true,
            size: 'lg',
            backdrop: 'static',
            resolve: {
                unit
            }
        });

        this.modalInstance.result.catch(err => {
            console.error(err);
        });
    }

    toggleSelectAll() {
        this.tableParams.data.forEach(item => {
            const key = `${item._id}_${item.sim}`;
            if(this.allChecked) {
                this.checkedList[key] = true;
            } else {
                this.checkedList[key] = false;
            }
            this.updateCheckedList(key);
        });
    }

    toggleSelect(key) {
        this.updateAllChecked();
        this.updateCheckedList(key);
    }

    updateAllChecked() {
        this.allChecked = _.every(this.tableParams.data, item => {
            const key = `${item._id}_${item.sim}`;
            return this.checkedList[key];
        });
    }
}
export default angular.module('insideInfoApp.units')
    .component('errorWarningTable', {
        template: require('./error-warning-table.html'),
        controller: ErrorWarningTable,
        controllerAs: '$ctrl',
        bindings: {
            data: '=',
            infoType: '<',
            checkedList: '=',
            updateCheckedList: '&'
        }
    })
    .name;

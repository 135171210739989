export class EventListComponent {
	$scope;
	$stateParams;
	Restangular;
	tableParams;
	NgTableParams;
	socket;
	$log;
	filter;
	columns = [];
	selectedColumns = [];
	isColumnsCollapsed;
	columnsVisible = {};
	Units;
	units;
	total;
	throttleRefresh;
	moment;
	$uibModal;

	//FUNCTIONS:
	shouldShow;
	onColumnSelected;
	onColumnRemoved;
	stringtoimei;
	imeitobytes;
	getIMEI;
	applyFilter;
	isOnline;
	lastHeartbeat;
	countOnlineClients;
	reloader;
	Auth


	/*@ngInject*/
	constructor($scope, $stateParams, $sce, Restangular, NgTableParams, socket, $uibModal, $log, Auth, moment, $state, toastr) {
	    // this.message = 'Hello';
	    this.$scope = $scope;
	    this.$stateParams = $stateParams;
	    this.$sce = $sce;
	    this.Restangular = Restangular;
	    this.NgTableParams = NgTableParams;
	    this.socket = socket;
	    this.$log = $log;
	    this.Auth = Auth;
	    this.moment = moment;
	    this.filter = '';
	    this.isColumnsCollapsed = false;
	    this.$state = $state;
	    this.$uibModal = $uibModal;
	    this.toastr = toastr;
	    this.self = this;
	}

	$onInit() {
	    let self = this;
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;

	    this.selectedEvents = {};
	    this.selectedColumns = [
	        'Unit Name',
	        'Event Type',
	        'Created At',
	        'Classification',
	        'Classification Description',
	        'Classification Time',
	        'Classify',
	        'Closed',
	        'Resolved',
	        'Correct',
	    ];

	    this.Events = this.Restangular.all('events');

	    this.activeFilter = true;
	    this.cols = [{
	        title: 'Unit Name',
	        field: 'unitname',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Event Type',
	        field: 'eventType',
	        show: true,
	        sortable: 'eventType',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Created At',
	        field: 'createdAt',
	        show: true,
	        sortable: 'createdAt',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Classification',
	        field: 'metaData.class',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Classification Description',
	        field: 'metaData.description',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Classification Time',
	        field: 'metaData.ts',
	        show: true,
	        sortable: 'lastHB.0.battery',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Classify',
	        field: 'classify',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Closed',
	        field: 'active',
	        show: true,
	        sortable: 'active',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Resolved',
	        field: 'value',
	        show: true,
	        sortable: 'value',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Correct',
	        field: 'metaData.oldClass',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Select',
	        field: 'selected',
	        show: true,
	        getValue: this.handleDisplay,
	        context: this
	    }];


	    this.tableParams = new this.NgTableParams({
	        page: 1, // start with first page
	        count: 10, // count per page
	        sorting: {
	            createdAt: 'desc' // initial sorting
	        }
	    }, {
	        total: 0,
	        getData(params) {
	            let order;
	            if(params && params.sorting) {
	                order = params.sorting();
	                let queryData = {
	                    filter: self.filter.length ? self.filter : undefined,
	                    skip: (params.page() - 1) * params.count(),
	                    accountID: self.$stateParams.accountID,
	                    limit: params.count(),
	                    by: Object.keys(order)[0],
	                    order: order[Object.keys(order)[0]]
	                };
	                if(typeof self.activeFilter == 'boolean') {
	                    queryData.field = 'active';
	                    queryData.query = self.activeFilter;
	                }
	                return self.Events.getList(queryData).then(function(events) {
	                    self.events = events;
	                    self.total = events.total;
	                    params.total(events.total);
	                    return self.events;
	                })
	                    .catch(err => {
	                        console.error('Error caught when getting data for events: ', err.data.err);
	                    });
	            }
	        }
	    });
	    this.tableParams.reload();

	    this.socket.joinRoom(`${self.$stateParams.accountID}:*:events`);

	    this.$scope.$on('$destroy', function() {
	        if(self.eventEventListener) {
	            self.socket.socket.removeListener('event:save', self.eventEventListener);
	        }
	        self.socket.leaveRoom(`${self.$stateParams.accountID}:*:events`);
	    });
	    self.eventEventListener = self.onReceiptOfEvent.bind(self);
	    self.socket.socket.on('event:save', self.eventEventListener);

	    this.throttleRefresh = _.throttle(self.reloader, 1000);

	    //onInit ends
	}

	onReceiptOfEvent(event) {
	    let self = this;
	    let index = _.findIndex(self.events, o => o._id == event._id);
	    if(index !== -1) {
	        self.events[index] = event;
	    }
	}

	handleDisplay(self, col, event) {
	    let html = '';
	    switch (col.field) {
	    case 'unitname': {
	        html = `<a ui-sref="main.units.detail.charts({unitID:event.unit})">${event.unitname}</a>`;
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'eventType': {
	        return event[col.field];
	    }
	        break;
	    case 'metaData.class': {
	        return event.metaData.class;
	    }
	        break;
	    case 'metaData.description': {
	        return event.metaData.description;
	    }
	        break;
	    case 'createdAt': {
	        html
					+= `<span uib-tooltip="${event.createdAt}" tooltip-append-to-body="true" am-time-ago="event.createdAt"></span>`;
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'metaData.ts': {
	        html
					+= `<a ui-sref="main.units.detail.charts({unitID:event.unit, sdate:${self.moment.utc(event.metaData.ts).subtract(12, 'hours')
	                .valueOf()},edate:${self.moment.utc(event.metaData.ts).add(30, 'minutes')
	                .valueOf()},gran:1})" uib-tooltip="${event.metaData.ts}" tooltip-append-to-body="true" am-time-ago="event.metaData.ts"></a>`;
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'active': {
	        if(event[col.field]) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        }
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'value': {
	        if(event[col.field]) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        }
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'metaData.oldClass': {
	        if(event.metaData.correct) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        }
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'classify': {
	        html
					+= `
				<button class="btn btn-default" ng-click="$ctrl.labelEventAsCorrect(event)">
				<span class="fa-lg">
				<i class="fa fa-check"></i>
				</span>
				</button>
				<button class="btn btn-default" ng-click="$ctrl.labelEventAsIncorrect(event)">
				<span class="fa-lg">
				<i class="fa fa-times"></i>
				</span>
				</button>
				`;
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    case 'selected': {
	        html
					+= `
				<div class="custom-control custom-checkbox">
					<input type="checkbox" ng-model=event.selected class="custom-control-input">
				</div>
				`;
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    default: {
	        //The default cases are those who adopt boolean values
	        //"internet || chreosis || ethernet || vpn || usb || wan"
	        if(event[col.field]) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        }
	        return self.$sce.trustAsHtml(html);
	    }
	        break;
	    }
	}


	onColumnSelected($item, $model) {
	    $item.show = true;
	}

	onColumnRemoved($item, $model) {
	    $item.show = false;
	}

	applyFilter() {
	    this.tableParams.page(1);
	    this.tableParams.reload();
	}

	reloader() {
	    this.tableParams.reload();
	}

	labelEventAsCorrect(event) {
	    let self = this;
	    event.metaData.correct = true;
	    event.active = false;
	    self.Restangular.one('events', event._id).patch(event)
	        .then(event => {
	        self.toastr.info('Event labled');
	    })
	        .catch(err => {
	        self.toastr.error('Event labeling failed');
	    });
	}

	labelEventAsIncorrect(event) {
	    let self = this;
	    let modalInstance = this.$uibModal.open({
	        component: 'classifyeventmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    item: event
	                };
	            }
	        }
	    });

	    modalInstance.result.then(function(result) {
	        result.metaData.correct = false;
	        result.active = false;
	        self.Restangular.one('events', result._id).patch(result)
	            .then(event => {
	            self.toastr.info('Event labled');
	        })
	            .catch(err => {
	            self.toastr.error('Event labeling failed');
	        });
	    });
	}

	labelMultipleEvents() {
	    let self = this;
	    let selectedEvents = _.filter(self.events, 'selected');
	    if(selectedEvents.length <= 0) {
	        self.toastr.warning('No events selected');
	        return null;
	    }

	    let modalInstance = this.$uibModal.open({
	        component: 'classifyeventmodal',
	        bindToController: true,
	        size: 'sm',
	        backdrop: 'static',
	        resolve: {
	            settings() {
	                return {
	                    item: _.cloneDeep(selectedEvents[0])
	                };
	            }
	        }
	    });

	    modalInstance.result.then(function(result) {
	        selectedEvents.forEach(event => {
	            event.metaData.correct = false;
	            event.active = false;
	            event.metaData.oldClass = event.metaData.class;
	            event.metaData.oldDescription = event.metaData.description;
	            event.metaData.description = result.metaData.description;
	            event.metaData.class = result.metaData.class;
	            self.Restangular.one('events', event._id).patch(event)
	                .then(nevent => {
	                self.toastr.info('Event labled');
	            })
	                .catch(err => {
	                self.toastr.error('Event labeling failed');
	            });
	        });
	    });
	}

	labelMultipleEventsAsCorrect() {
	    let self = this;
	    let selectedEvents = _.filter(self.events, 'selected');
	    if(selectedEvents.length <= 0) {
	        self.toastr.warning('No events selected');
	        return null;
	    }
	    selectedEvents.forEach(event => {
	        event.metaData.correct = true;
	        event.active = false;
	        self.Restangular.one('events', event._id).patch(event)
	            .then(event => {
	            self.toastr.info('Event labled');
	        })
	            .catch(err => {
	            self.toastr.error('Event labeling failed');
	        });
	    });
	}

	getFeatures() {
	    let self = this;
	    let queryData = {
	        filter: self.filter.length ? self.filter : undefined,
	        accountID: self.$stateParams.accountID,
	        skip: (self.tableParams.page() - 1) * self.tableParams.count(),
	        limit: self.tableParams.count(),
	    };
	    if(typeof self.activeFilter == 'boolean') {
	        queryData.field = 'active';
	        queryData.query = self.activeFilter;
	    }
	    return self.Events.customGET('features', queryData).then(function(events) {
	        try {
	            let csvContent = 'data:text/csv;charset=utf-8,';
	            let keys = _.reduce(events, (list, event) => {
	                if(event.features) {
	                    list = list.concat(Object.keys(event.features));
	                    list = _.uniq(list);
	                }
	                return list;
	            }, ['class', '_id', 'unit', 'createdAt']);
	            csvContent += keys.join(',');
	            csvContent += '\n';
	            events.forEach(event => {
	                if(event.features) {
	                    let row = '';
	                    keys.forEach(key => {
	                        if(key == 'class') {
	                            row += event.metaData.class;
	                        } else if(['_id', 'unit', 'createdAt'].includes(key)) {
	                            row += event[key];
	                        } else if(event.features[key] !== undefined) {
	                            row += event.features[key];
	                        } else {
	                            row += 'null';
	                        }
	                        row += ',';
	                    });

	                    row.slice(0, -1);
	                    csvContent += row;
	                    csvContent += '\n';
	                } else {
	                    console.error('Event has no features : ', event);
	                }
	            });
	            var encodedUri = encodeURI(csvContent);
	            var link = document.createElement('a');
	            link.setAttribute('href', encodedUri);
	            link.setAttribute('download', 'my_data.csv');
	            document.body.appendChild(link); // Required for FF

	            link.click();
	        } catch(e) {
	            /* handle error */
	            console.error(e);
	        }
	    })
	        .catch(err => {
	            console.error('Error caught when getting data for events: ', err.data.err);
	        });
	}
}

export default angular.module('insideInfoApp.events')
    .component('eventlist', {
        template: require('./events.list.html'),
        controller: EventListComponent,
        controllerAs: '$ctrl'
    })
    .name;

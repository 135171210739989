'use strict';

angular.module('SmartAdmin.Layout').directive('hrefVoid', function() {
    return {
        restrict: 'A',
        link(scope, element, attributes) {
            element.attr('href', '#');
            element.on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }
    };
});

// @flow

type User = {
  name: string;
  email: string;
  password: string;
};

export class LoginComponent {
  user: User = {
      name: '',
      email: '',
      password: ''
  };
  errors = {
      login: undefined
  };
  submitted = false;
  Auth;
  $state;
  $location;
  $window
  $log
  hostnameIcon;
  user;
  errors;
  submitted;

  //login : Function;


  /*@ngInject*/
  constructor(Auth, $state, $location, $window, $log, $http, socket) {
      this.$http = $http;
      this.Auth = Auth;
      this.$state = $state;
      this.$location = $location;
      this.$window = $window;
      this.socket = socket;
  }

  $onInit() {
      this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
      this.errors = {};
      this.Auth.logout();

      this.user = {rememberme: true};

      this.$http.get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
          .then(data => {
              this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
          })
          .catch(err => {
              //
              this.hostnameIcon = 'assets/images/domainicons/jerichosystems.png';
          });
  }


  clearSubmission() {
      this.submitted = false;
      this.errors.login = null;
  }


  login(form) {
      let self = this;
      this.submitted = true;

      if(form.$valid) {
          this.Auth.login({
              email: this.user.email,
              password: this.user.password
          })
              .then(() => {
                  self.socket.reAuth();
                  if(this.Auth.isVerified()) {
                      this.$state.go('main.dashboard', {accountID: this.Auth.getCurrentAccountSync().ref});
                  } else {
                      this.$state.go('login');
                  }
                  // Logged in, redirect to home
              })
              .catch(err => {
                  if(err.error.message == 'CSRF token missing') {
                      self.login(form);
                  } else {
                      this.errors.login = err.message;
                  }
              });
      }
  }

  loginOauth(provider) {
      this.$window.location.href = `/auth/${provider}`;
  }
}

export default angular.module('insideInfoApp.login')
    .component('login', {
        template: require('./login.html'),
        controller: LoginComponent,
        controllerAs: '$ctrl'
    })
    .name;

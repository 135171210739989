export class AlertsSettingsComponent {
  Restangular;
  $uibModal;
  $stateParams;
  alertConfigs = [];
  message = '';
  Auth;

  /*@ngInject*/
  constructor(Restangular, $uibModal, $stateParams, Auth) {
      this.Restangular = Restangular;
      this.$uibModal = $uibModal;
      this.$stateParams = $stateParams;
      this.Auth = Auth;
  }

  $onInit() {
      let self = this;
      this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref; //temporarily define an account
      this.baseAlerts = this.Restangular.one('accounts', this.$stateParams.accountID).all('alerts')
          .all('configs');
      this.baseAlerts.getList().then(alerts => {
          self.alertConfigs = alerts;
      });
      this.message = 'Hello';
  }

  // TODO add child views for modals, for allowing href links to alert settings
  openCreateModal() {
      let self = this;
      this.modalInstance = this.$uibModal.open({
          //template: "<alertsmodals></alertsmodals>",
          component: 'alertsmodals',
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          resolve: {
              settings() {
                  return {
                      edit: false,
                      create: true
                  };
              }
          }
      });

      this.modalInstance.result.then(result =>

          //   self.Restangular.one('accounts', this.$stateParams.accountID).all('alerts').customPOST(result,'configs').then(function(){
          self.baseAlerts.post(result).then(function(value) {
              return self.baseAlerts.getList().then(function(alerts) {
                  self.alertConfigs = alerts;
                  return null;
              });
          })
              .catch(err => {
                  console.error(err);
              })
      ).catch(err => {
          console.error(err);
      });
  }

  onEditAlert(config) {
      let self = this;
      var modalInstance = this.$uibModal.open({
          // template: require ('./alerts.modal.html'),
          component: 'alertsmodals',
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          resolve: {
              settings() {
                  return {
                      edit: true,
                      create: false,
                      item: config
                  };
              }
          }
      });

      modalInstance.result.then(function(result) {
          config.patch(result).then(function() {
              self.baseAlerts.getList().then(function(alerts) {
                  self.alertConfigs = alerts;
              });
          });
      });
  }
  onDuplicateAlert(config) {
      let self = this;
      var modalInstance = this.$uibModal.open({
          // template: require ('./alerts.modal.html'),
          component: 'alertsmodals',
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          resolve: {
              settings() {
                  return {
                      edit: false,
                      create: true,
                      item: config
                  };
              }
          }
      });

      modalInstance.result.then(function(result) {
          self.baseAlerts.post(result).then(function() {
              self.baseAlerts.getList().then(function(alerts) {
                  self.alertConfigs = alerts;
              });
          });
      });
  }
  onDeleteAlert(config) {
      let self = this;
      config.remove().then(function() {
          self.baseAlerts.getList().then(function(alerts) {
              self.alertConfigs = alerts;
          });
      });
  }

  onDisableAlert(config) {
      let self = this;
      config.one('disable').customPUT({disable: true})
          .then(function() {
              self.baseAlerts.getList().then(function(alerts) {
                  self.alertConfigs = alerts;
              });
          });
  }
  onEnableAlert(config) {
      let self = this;
      config.one('enable').customPUT({enable: true})
          .then(function() {
              self.baseAlerts.getList().then(function(alerts) {
                  self.alertConfigs = alerts;
              });
          });
  }
}

export default angular.module('insideInfoApp.alerts')
    .component('alertssettings', {
        template: require('./alerts.settings.html'),
        controller: AlertsSettingsComponent,
        controllerAs: '$ctrl'
    })
    .name;

export class UserAccountComponent {
  accounts;
  Auth;
  currentAccountName;

  /*@ngInject*/
  constructor(Auth, NgTableParams, $sce, $element, $timeout, $scope) {
      this.Auth = Auth;
      this.NgTableParams = NgTableParams;
      this.$sce = $sce;
      // this.$element = $element;
      // this.$timeout = $timeout;
      this.$scope = $scope;
      this.tableHeaders;
  }

  $onInit() {
      let self = this;
      this.accounts = this.Auth.getCurrentUserSync().accounts;
      this.currentAccount = this.Auth.getCurrentAccountSync();
      let data = this.accounts;

      this.cols = [
          { field: 'ref', title: 'ID', sortable: 'ref', show: true},
          { field: 'name', title: 'Name', sortable: 'name', show: true, filter: {name: 'text'}},
          { field: 'connid', title: 'Connection ID', show: true},
          { field: 'active', title: 'Active', show: true}
      ];
      self.tableParams = new self.NgTableParams({}, { dataset: data});
      // end onInit
  }

}

export default angular.module('insideInfoApp.settings')
    .component('accountsettings', {
        template: require('./settings.account.html'),
        controller: UserAccountComponent,
        controllerAs: '$ctrl'
    })
    .name;

'use strict';

export function UserResource($resource) {
    'ngInject';

    return $resource('/api/users/:id', {
        id: '@_id',
        link: '@link'
    }, {
        changePassword: {
            url: '/api/users/:id/password',
            method: 'PUT',
            params: {
                controller: 'password'
            }
        },
        get: {
            method: 'GET',
            params: {
                id: 'me'
            }
        },
        forgotPassword: {
            method: 'GET',
            params: {
                id: 'forgot'
            }
        },
        resetPasswordTest: {
            url: '/api/users/:id/forgot/:link',
            method: 'get',
        },
        resetPassword: {
            url: '/api/users/:id/forgot/:link',
            method: 'put',
        }
    });
}

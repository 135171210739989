export class UnitsListComponent {
	$scope;
	$stateParams;
	Restangular;
	tableParams;
	NgTableParams;
	socket;
	$log;
	filter;
	columns = [];
	selectedColumns = [];
	isColumnsCollapsed;
	columnsVisible = {};
	Units;
	units;
	total;
	throttleRefresh;
	moment;

	//FUNCTIONS:
	shouldShow;
	onColumnSelected;
	onColumnRemoved;
	stringtoimei;
	imeitobytes;
	getIMEI;
	applyFilter;
	isOnline;
	lastHeartbeat;
	countOnlineClients;
	reloader;
	Auth


	/*@ngInject*/
	constructor($scope, $stateParams, $sce, Restangular, NgTableParams, socket, $log, Auth, moment, $state, toastr, $uibModal, $ngConfirm) {
	    // this.message = 'Hello';
	    this.$scope = $scope;
	    this.$stateParams = $stateParams;
	    this.$sce = $sce;
	    this.Restangular = Restangular;
	    this.NgTableParams = NgTableParams;
	    this.socket = socket;
	    this.$log = $log;
	    this.Auth = Auth;
	    this.moment = moment;
	    this.filter = '';
	    this.isColumnsCollapsed = false;
	    this.$state = $state;
	    this.toastr = toastr;
	    this.$uibModal = $uibModal;
	    this.$ngConfirm = $ngConfirm;
	    this.self = this;
	}

	$onInit() {
	    let self = this;
	    this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
	    this.user = this.Auth.getCurrentUserSync();

	    this.selectedColumns = [
	        'Name',
	        'Type',
	        'Last Heartbeat',
	        'Mains',
	        'Battery',
	        'Chreosis',
	        'VPN',
	        'Power',
	        'WAN',
	        'Devices',
	        'Groups'
	    ];

	    this.Units = this.Restangular.all('units');

	    this.cols = [{
	        title: 'Name',
	        field: 'name',
	        show: true,
	        sortable: 'name',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default', 'airtime']
	    }, {
	        title: 'Type',
	        field: 'type',
	        show: true,
	        sortable: 'type',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default', 'airtime']
	    }, {
	        title: 'IMEI',
	        field: 'imei',
	        show: false,
	        sortable: 'imei',
	        getValue: this.handleDisplay,
	        context: this,
	    }, {
	        title: 'Last Heartbeat',
	        field: 'lasthb',
	        show: true,
	        sortable: 'lastHB.0.ts',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default', 'airtime']
	    }, {
	        title: 'Mains',
	        field: 'mains',
	        show: true,
	        sortable: 'lastHB.0.main',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'Battery',
	        field: 'battery',
	        show: true,
	        sortable: 'lastHB.0.battery',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'RSSI',
	        field: 'rssi',
	        show: false,
	        sortable: 'lastHB.0.rssi',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'SIM',
	        field: 'sim',
	        show: false,
	        sortable: 'lastHB.0.status.sim',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'Internet',
	        field: 'internet',
	        show: false,
	        sortable: 'lastHB.0.status.inet',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Chreosis',
	        field: 'chreosis',
	        show: true,
	        sortable: 'lastHB.0.status.chreosis',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'Ethernet',
	        field: 'ethernet',
	        show: false,
	        sortable: 'lastHB.0.status.ethernet',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'VPN',
	        field: 'vpn',
	        show: true,
	        sortable: 'lastHB.0.status.vpn',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'VMP',
	        field: 'vmp',
	        show: false,
	        sortable: 'lastHB.0.status.vmp',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'Power',
	        field: 'power',
	        show: true,
	        sortable: 'lastHB.0.status.power',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'USB',
	        field: 'usb',
	        show: false,
	        sortable: 'lastHB.0.status.usb',
	        getValue: this.handleDisplay,
	        context: this
	    }, {
	        title: 'WAN',
	        field: 'wan',
	        show: true,
	        sortable: 'lastHB.0.status.wan',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'Devices',
	        field: 'devices',
	        show: true,
	        sortable: 'lastHB.0.clients',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'Groups',
	        field: 'groups',
	        show: true,
	        sortable: 'lastHB.0.groups',
	        getValue: this.handleDisplay,
	        context: this,
	        groups: ['default']
	    }, {
	        title: 'SIM 1 Network',
	        field: 'status.networkNameSIM1',
	        sortable: 'status.networkNameSIM1',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Airtime',
	        field: 'simInfo.sim0.remainingAirtime',
	        sortable: 'simInfo.sim0.remainingAirtime',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Airtime Updated',
	        field: 'simInfo.sim0.remainingAirtimeUpdated',
	        sortable: 'simInfo.sim0.remainingAirtimeUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Data',
	        field: 'simInfo.sim0.remainingData',
	        sortable: 'simInfo.sim0.remainingData',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Data Updated',
	        field: 'simInfo.sim0.remainingDataUpdated',
	        sortable: 'simInfo.sim0.remainingDataUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Number',
	        field: 'simInfo.sim0.number',
	        sortable: 'simInfo.sim0.number',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 1 Number Updated',
	        field: 'simInfo.sim0.numberUpdated',
	        sortable: 'simInfo.sim0.numberUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Network',
	        field: 'status.networkNameSIM2',
	        sortable: 'status.networkNameSIM2',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Airtime',
	        field: 'simInfo.sim1.remainingAirtime',
	        sortable: 'simInfo.sim1.remainingAirtime',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Airtime Updated',
	        field: 'simInfo.sim1.remainingAirtimeUpdated',
	        sortable: 'simInfo.sim1.remainingAirtimeUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Data',
	        field: 'simInfo.sim1.remainingData',
	        sortable: 'simInfo.sim1.remainingData',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Data Updated',
	        field: 'simInfo.sim1.remainingDataUpdated',
	        sortable: 'simInfo.sim1.remainingDataUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Number',
	        field: 'simInfo.sim1.number',
	        sortable: 'simInfo.sim1.number',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'SIM 2 Number Updated',
	        field: 'simInfo.sim1.numberUpdated',
	        sortable: 'simInfo.sim1.numberUpdated',
	        getValue: this.handleDisplay,
	        show: false,
	        context: this,
	        groups: ['airtime']
	    }, {
	        title: 'Firmware Version',
	        field: 'status.firmwareVersion',
	        getValue: this.handleDisplay,
	        sortable: 'status.firmwareVersion',
	        show: false,
	        context: this
	    }];


	    this.tableParams = new this.NgTableParams({
	        page: 1, // start with first page
	        count: 10, // count per page
	        sorting: {
	            name: 'asc' // initial sorting
	        }
	    }, {
	        total: 0,
	        getData(params) {
	            let order;
	            if(params && params.sorting) {
	                order = params.sorting();
	                return self.Units.getList({
	                    filter: self.filter.length ? self.filter : undefined,
	                    skip: (params.page() - 1) * params.count(),
	                    accountID: self.$stateParams.accountID,
	                    limit: params.count(),
	                    by: Object.keys(order)[0],
	                    order: order[Object.keys(order)[0]]
	                }).then(function(units) {
	                    self.units = units;
	                    self.total = units.total;
	                    params.total(units.total);
	                    self.formatedUnits = [];
	                    self.units.forEach(unit => {
	                        self.formatedUnits.push(self.formatData(unit));
	                    });
	                    return self.formatedUnits;
	                })
	                    .catch(err => {
	                        console.error('Error caught when getting data for units: ', err.data.err);
	                    });
	            }
	        }
	    });
	    this.tableParams.reload();

	    this.socket.joinRoom(`${self.$stateParams.accountID}:*:*:heartbeats`);
	    this.socket.joinRoom(`${self.$stateParams.accountID}:*:units`);

	    this.$scope.$on('$destroy', function() {
	        if(self.heartbeatEventListener) {
	            self.socket.socket.removeListener('heartbeat:save', self.heartbeatEventListener);
	        }
	        if(self.unitEventListener) {
	            self.socket.socket.removeListener('unit:save', self.unitEventListener);
	        }
	        self.socket.leaveRoom(`${self.$stateParams.accountID}:*:units`);
	        self.socket.leaveRoom(`${self.$stateParams.accountID}:*:*:heartbeats`);
	    });
	    self.heartbeatEventListener = self.onReceiptOfHeartbeat.bind(self);
	    self.socket.socket.on('heartbeat:save', self.heartbeatEventListener);
	    self.unitEventListener = self.onReceiptOfUnit.bind(self);
	    self.socket.socket.on('unit:save', self.unitEventListener);


	    this.throttleRefresh = _.throttle(self.reloader, 1000);

	    //onInit ends
	}

	onReceiptOfHeartbeat(hb) {
	    let self = this;
	    let index = _.findIndex(self.units, o => o._id == hb.unit);
	    let formatIndex = _.findIndex(self.formatedUnits, o => o._id == hb.unit);
	    if(index !== -1 && formatIndex !== -1) {
	        self.units[index].lastHB.unshift(hb);
	        self.formatedUnits[formatIndex] = self.formatData(self.units[index]);
	    }
	}

	onReceiptOfUnit(unit) {
	    let self = this;
	    let index = _.findIndex(self.units, o => o._id == unit._id);
	    let formatIndex = _.findIndex(self.formatedUnits, o => o._id == unit._id);
	    if(index !== -1 && formatIndex !== -1) {
	        _.mergeWith(self.units[index], unit, (objValue, srcValue) => {
	            if(_.isArray(objValue) && srcValue) {
	                return srcValue;
	            }
	        });
	        self.formatedUnits[formatIndex] = self.formatData(self.units[index]);
	    }
	}

	handleDisplay(self, col, unit) {
	    let html = '';
	    switch (col.field) {
	    case 'name':
	        html = `<a ui-sref="main.units.detail.charts({unitID:unit._id})">${unit.name}</a>`;
	        html += '<span class="pull-right">';
	        html
					+= '<i class="fa fa-refresh" style="margin:0.25em;cursor : pointer;" uib-tooltip="Update unit status." ng-click="$ctrl.getStatus(unit)"> </i>';
	        html
					+= '<i class="fa fa-envelope" style="margin:0.25em;cursor : pointer;" uib-tooltip="Open USSD Modal" ng-click="$ctrl.openUSSDModal(unit._id)"> </i>';
	        html += '</span>';
	        return self.$sce.trustAsHtml(html);
	        break;

	    case 'type':
	        return unit[col.field];
	        break;

	    case 'imei':
	        return unit[col.field];
	        break;
	    case 'groups':
	        html = `<span class="label label-default" style="margin-right: 0.5em;" ng-repeat="group in unit.groups">
												{{group}}   <br>
											</span>`;

	        return self.$sce.trustAsHtml(html);
	        break;

	        /*      case "lasthb":
					  html =
					  `<span ng-show="unit.online" class="fa-lg">` +
						`<i class="fa fa-check-circle text-success"></i> ` +
					  `</span>` +
					  `<span ng-hide="unit.online" class="fa-lg">` +
						`<i class="fa fa-ban text-danger"></i> ` +
					  `</span>` +
					  `<span uib-tooltip="{{unit.lastHB.ts}}" tooltip-append-to-body="true" >{{unit.lasthb}}</span>`
					  return self.$sce.trustAsHtml(html);
					  break;*/

	    case 'lasthb':
	        if(unit.online) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i> '
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i> '
						+ '</span>';
	        }

	        if(unit.lastHB && unit.lastHB.ts) {
	            html += `<span uib-tooltip="${unit.lastHB.ts}" tooltip-append-to-body="true" am-time-ago="unit.lastHB.ts"></span>`;
	        } else if(unit.lastActivity) {
	            html += `<span uib-tooltip="${unit.lastActivity}" tooltip-append-to-body="true" am-time-ago="unit.lastActivity"></span>`;
	        }
	        return self.$sce.trustAsHtml(html);
	        break;

	        /*      case "mains":
					  html =
					  `{{unit.mains | number: 3}} V`;
					  return self.$sce.trustAsHtml(html);
					  break;*/

	    case 'mains':
	        html = `${unit.mains.toFixed(3)} V`;
	        return self.$sce.trustAsHtml(html);
	        break;

	        /*    case "battery":
					  html =
					  `{{unit.battery | number: 3}} V`;
					  return self.$sce.trustAsHtml(html);
					  break;*/

	    case 'battery':
	        html = `${unit.battery.toFixed(3)} V`;
	        return self.$sce.trustAsHtml(html);
	        break;

	        /*      case "rssi":
					  html =
					  `{{unit.rssi | number: 3}} dBm`;
					  return self.$sce.trustAsHtml(html);
					  break;*/

	    case 'rssi':
	        html = `${unit.rssi.toFixed(3)} dBm`;
	        return self.$sce.trustAsHtml(html);
	        break;

	    case 'sim':
	        html += '<span>SIM {{unit.lastHB.status.sim + 1}}</span>';
	        if(self.user.role === 'admin') {
	            html += '<span class="pull-right">';
	            html += '<i class="fa fa-refresh" style="margin:0.25em;cursor : pointer;" uib-tooltip="Swap to other sim" ng-click="$ctrl.swapSim(unit)"> </i>';
	            html += '</span>';
	        }
	        return self.$sce.trustAsHtml(html);
	        break;
	    case 'status.networkNameSIM1':
	        if(unit.status && unit.status.networkNameSIM1) {
	            return unit.status.networkNameSIM1;
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim0.remainingAirtime':
	        if(unit.simInfo?.sim0?.remainingAirtime != undefined) {
	            html
						= '<span uib-tooltip="Updated {{unit.simInfo.sim0.remainingAirtimeUpdated | date:\'medium\'}}" tooltip-append-to-body="true">R {{unit.simInfo.sim0.remainingAirtime}}</span>';
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim0.remainingAirtimeUpdated':
	        if(unit.simInfo?.sim0?.remainingAirtimeUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim0?.remainingAirtimeUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim0.remainingAirtimeUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim1.remainingAirtime':
	        if(unit.simInfo?.sim1?.remainingAirtime != undefined) {
	            html
						= '<span uib-tooltip="Updated {{unit.simInfo.sim1.remainingAirtimeUpdated | date:\'medium\'}}" tooltip-append-to-body="true">R {{unit.simInfo.sim1.remainingAirtime}}</span>';
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim1.remainingAirtimeUpdated':
	        if(unit.simInfo?.sim1?.remainingAirtimeUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim1?.remainingAirtimeUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim1.remainingAirtimeUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim0.remainingData':
	        if(unit.simInfo?.sim0?.remainingData != undefined) {
	            html
						= '<span uib-tooltip="Updated {{unit.simInfo.sim0.remainingDataUpdated | date:\'medium\'}}" tooltip-append-to-body="true">{{unit.simInfo.sim0.remainingData | metricsum:2:\'Data\'}}</span>';
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim0.remainingDataUpdated':
	        if(unit.simInfo?.sim0?.remainingDataUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim0?.remainingDataUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim0.remainingDataUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim1.remainingData':
	        if(unit.simInfo?.sim1?.remainingData != undefined) {
	            html
						= '<span uib-tooltip="Updated {{unit.simInfo.sim1.remainingDataUpdated | date:\'medium\'}}" tooltip-append-to-body="true">{{unit.simInfo.sim1.remainingData | metricsum:2:\'Data\'}}</span>';
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim1.remainingDataUpdated':
	        if(unit.simInfo?.sim1?.remainingDataUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim1?.remainingDataUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim1.remainingDataUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim0.number':
	        if(unit.simInfo?.sim0?.number != undefined) {
	            return unit.simInfo?.sim0?.number;
	        } else {
	            html = 'Unknown';
	            if(self.user.role === 'admin' && unit.lastHB?.status?.sim === 0) {
	                html += '<span class="pull-right">';
	                html += '<i class="fa fa-refresh" style="margin:0.25em;cursor : pointer;" uib-tooltip="Request number" ng-click="$ctrl.numberCheckRequest(unit)"> </i>';
	                html += '</span>';
	            }
	            return self.$sce.trustAsHtml(html);
	        }
	        break;
	    case 'simInfo.sim0.numberUpdated':
	        if(unit.simInfo?.sim0?.numberUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim0?.numberUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim0.numberUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'simInfo.sim1.number':
	        if(unit.simInfo?.sim1?.number != undefined) {
	            return unit.simInfo?.sim1?.number;
	        } else {
	            html = 'Unknown';
	            if(self.user.role === 'admin' && unit.lastHB?.status?.sim === 1) {
	                html += '<span class="pull-right">';
	                html += '<i class="fa fa-refresh" style="margin:0.25em;cursor : pointer;" uib-tooltip="Request number" ng-click="$ctrl.numberCheckRequest(unit)"> </i>';
	                html += '</span>';
	            }
	            return self.$sce.trustAsHtml(html);
	        }
	        break;
	    case 'simInfo.sim1.numberUpdated':
	        if(unit.simInfo?.sim1?.numberUpdated != undefined) {
	            html += `<span uib-tooltip="${unit.simInfo?.sim1?.numberUpdated}" tooltip-append-to-body="true" am-time-ago="unit.simInfo.sim1.numberUpdated"></span>`;
	            return self.$sce.trustAsHtml(html);
	        } else {
	            return 'Unknown';
	        }
	        break;
	    case 'status.networkNameSIM2':
	        if(unit.status && unit.status.networkNameSIM2) {
	            return unit.status.networkNameSIM2;
	        } else {
	            return 'Unknown';
	        }
	        break;

	    case 'devices':
	        {
	            if(unit.clients && unit.clients.length) {
	                let onlineClients = _.filter(unit.clients, [
	                    'connected',
	                    true,
	                ]);
	                return `${onlineClients.length}/${unit.clients.length}`;
	            } else {
	                return 'N/A';
	            }
	        }
	        break;
	    case 'status.firmwareVersion':
	        {
	            html = `
						<span ng-if="unit.lastStatusUpdate" uib-tooltip="Updated {{unit.lastStatusUpdate | date:'medium'}}" tooltip-append-to-body="true">{{unit.status.firmwareVersion}}</span>
						<span ng-if="!unit.lastStatusUpdate">{{unit.status.firmwareVersion}}</span>
						`;
	            return self.$sce.trustAsHtml(html);
	        }
	        break;

	        /*      default:
				//The default cases are those who adopt boolean values
				//"internet || chreosis || ethernet || vpn || usb || wan"
					  html =
					  `<span ng-show="unit.${col.field}" class="fa-lg">` +
						`<i class="fa fa-check-circle text-success"></i>` +
					  `</span>` +
					  `<span ng-hide="unit.${col.field}" class="fa-lg">` +
						`<i class="fa fa-ban text-danger"></i>` +
					  `</span>`;
					  return self.$sce.trustAsHtml(html);
					  break;*/
	    case 'power':
	        //The default cases are those who adopt boolean values
	        //"internet || chreosis || ethernet || vpn || usb || wan"
	        if(unit[col.field] == 'Mains') {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        }

	        return self.$sce.trustAsHtml(html);
	        break;

	    default:
	        //The default cases are those who adopt boolean values
	        //"internet || chreosis || ethernet || vpn || usb || wan"
	        if(unit[col.field]) {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-check-circle text-success"></i>'
						+ '</span>';
	        } else {
	            html
						+= '<span class="fa-lg">'
						+ '<i class="fa fa-ban text-danger"></i>'
						+ '</span>';
	        }

	        return self.$sce.trustAsHtml(html);
	        break;
	    }
	}

	formatData(unit) {
	    let finalUnit = {
	        name: unit.name,
	        type: unit.type,
	        imei: unit.imei,
	        clients: unit.clients,
	        _id: unit._id,
	        groups: unit.groups,
	        online: this.isOnline(unit),
	        lasthb: this.lastHeartbeat(unit),
	        status: unit.status,
	        ussd: unit.ussd,
	        simInfo: unit.simInfo,
	        lastStatusUpdate: unit.lastStatusUpdate,
	        lastActivity: unit.lastActivity
	    };
	    if(!unit.lastHB || !unit.lastHB[0]) {
	        unit.lastHB = [{
	            main: 0,
	            battery: 0,
	            rssi: 0,
	            status: {
	                sim: 0,
	                internet: 0,
	                chreosis: 0,
	                ethernet: 0,
	                vpn: 0,
	                vmp: 0,
	                power: 0,
	                usb: 0,
	                wan: 0
	            },
	            devices: [],
	            ts: 0
	        }];
	    }
	    _.merge(finalUnit, {
	        lastHB: unit.lastHB[0],
	        mains: unit.lastHB[0].main / 1000,
	        battery: unit.lastHB[0].battery / 1000,
	        rssi: unit.lastHB[0].rssi,
	        sim: unit.lastHB[0].status.sim,
	        internet: unit.lastHB[0].status.inet,
	        chreosis: unit.lastHB[0].status.chreosis,
	        ethernet: unit.lastHB[0].status.ethernet,
	        vpn: unit.lastHB[0].status.vpn,
	        vmp: unit.lastHB[0].status.vmp,
	        power: unit.lastHB[0].status.power,
	        usb: unit.lastHB[0].status.usb,
	        wan: unit.lastHB[0].status.wan,

	    });

	    return finalUnit;
	}

	onColumnSelected($item, $model) {
	    $item.show = true;
	}

	onColumnRemoved($item, $model) {
	    $item.show = false;
	}

	stringtoimei(str) {
	    if(str.length !== 8) {
	        return 0;
	    }

	    let numlsb0 = bigInt(str[0]);
	    let numlsb1 = bigInt(str[1]);
	    let numlsb2 = bigInt(str[2]);
	    let numlsb3 = bigInt(str[3]);
	    let numlsb4 = bigInt(str[4]);
	    let numlsb5 = bigInt(str[5]);
	    let numlsb6 = bigInt(str[6]);
	    let numlsb7 = bigInt(str[7]);
	    let imei = numlsb0;
	    imei = imei.add(numlsb1.times(bigInt('256')));
	    imei = imei.add(numlsb2.times(bigInt('65536')));
	    imei = imei.add(numlsb3.times(bigInt('16777216')));
	    imei = imei.add(numlsb4.times(bigInt('4294967296')));
	    imei = imei.add(numlsb5.times(bigInt('1099511627776')));
	    imei = imei.add(numlsb6.times(bigInt('281474976710656')));
	    imei = imei.add(numlsb7.times(bigInt('72057594037927936')));
	    return imei.toString();
	}

	imeitobytes(imei) {
	    let bytes = [];
	    let imeibig = bigInt(imei);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    imeibig = imeibig.shiftRight(8);
	    bytes.push(imeibig.and(0xFF).valueOf());
	    return bytes;
	}

	getIMEI(unit) {
	    return stringtoimei(unit.connID.data) || 'Wrong Format';
	}

	applyFilter() {
	    this.tableParams.page(1);
	    this.tableParams.reload();
	}

	isOnline(unit) {
	    if(unit.lastHB && unit.lastHB.length > 0) {
	        return this.moment.utc().diff(this.moment.utc(unit.lastHB[0].ts), 'minutes') < 10;
	    } else {
	        return unit.online;
	    }
	    return false;
	}

	lastHeartbeat(unit) {
	    if(unit.lastHB && unit.lastHB.length > 0) {
	        let minutes = Math.abs(this.moment.utc().diff(this.moment.utc(unit.lastHB[0].ts), 'minutes'));
	        if(minutes === 1) {
	            return '1 minute ago';
	        }
	        if(minutes > 1) {
	            return `${minutes.toString()} minutes ago`;
	        }
	        return 'less than a minute ago';
	    }
	    return 'no last heartbeat';
	}

	countOnlineClients(clients) {
	    return _.filter(clients, {
	        connected: true
	    }).length;
	}

	viewAccountCharts() {
	    let self = this;
	    self.$state.go('main.units.detail.charts', { unitID: self.$stateParams.accountID });
	}

	reloader() {
	    this.tableParams.reload();
	}

	getStatus(unit) {
	    let self = this;
	    self.Restangular.one('units', unit._id).customPOST({}, 'status')
	        .then(response => {
	            self.toastr.info('Status request sent.', 'Successful');
	        })
	        .catch(err => {
	            self.toastr.error('Status request failed', 'Error');
	        });
	}
	getStatusAll() {
	    let self = this;
	    self.Restangular.all('units').customPOST({}, 'statusAll')
	        .then(response => {
	            self.toastr.info(`Status request sent to ${response.sent} of ${response.total}`, 'Successful');
	        })
	        .catch(err => {
	            self.toastr.error('Status request failed', 'Error');
	        });
	}

	checkBalancesAll() {
	    let self = this;
	    self.Restangular.all('units').customPOST({}, 'balanceCheckAll')
	        .then(response => {
	            self.toastr.info('Balance check requested', 'Successful');
	        })
	        .catch(err => {
	            self.toastr.error('Balance check request failed', 'Error');
	        });
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openUSSDModal(unitId) {
	    let self = this;
	    let unit = _.find(self.units, ['_id', unitId]);
	    this.modalInstance = this.$uibModal.open({
	        //template: "<alertsmodals></alertsmodals>",
	        component: 'ussdModal',
	        bindToController: true,
	        size: 'lg',
	        backdrop: 'static',
	        resolve: {
	            unit,
	        }
	    });

	    this.modalInstance.result.catch(err => {
	        console.error(err);
	    });
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openDataManagementModal() {
	    let self = this;
	    let modalInstance = this.$uibModal.open({

	        component: 'airtimeManagementModal',
	        bindToController: true,
	        size: 'lg',
	        backdrop: 'static',
	        windowClass: 'max-size-modal',
	        resolve: {
	            settings() {
	                return {
	                    edit: false,
	                    create: true,
	                };
	            }
	        }
	    });

	    modalInstance.result.then(result => {
	    }).catch(err => {
	        console.error(err);
	    });
	}

	numberCheckRequest(unit) {
	    let ussdRequest = {
	        numberCheck: true,
	    };
	    this.Restangular.one('units', unit._id).all('ussd')
	        .post(ussdRequest)
	        .then(res => {
	            this.toastr.info('USSD Code sent');
	        })
	        .catch(err => {
	            console.error(err);
	            this.toastr.error(err.data || err, 'USSD Request failed');
	        });
	}

	swapSim(unit) {
	    const self = this;
	    self.$ngConfirm({
	        title: 'Sim Swap',
	        content: `
				Are you sure you want to swap to SIM ${unit.lastHB?.status?.sim ? 1 : 2}.
			  	The unit could take some time to reconnect.
			`,
	        buttons: {
	            ok: {
	                text: 'Confirm',
	                btnClass: 'btn-primary',
	                async action() {
	                    //Export
	                    try {
	                        await self.Restangular.one('units', unit._id).customPOST({}, 'swapSim');
	                        self.toastr.info('Sim swap requested.');
	                    } catch(error) {
	                        console.error(error);
	                        self.toastr.error('Sim swap request failed.');
	                    }
	                },
	            },
	            cancel: {
	                text: 'Cancel',
	                btnClass: 'btn-warning',
	                action() { },
	            },
	        },
	    });
	}

	setColumns(group) {
	    this.selectedColumns = [];
	    this.cols.forEach(column => {
	        if(column.groups?.includes(group)) {
	            column.show = true;
	            this.selectedColumns.push(column);
	        } else {
	            column.show = false;
	        }
	    });
	}
}

export default angular.module('insideInfoApp.units')
    .component('unitlist', {
        template: require('./units.list.html'),
        controller: UnitsListComponent,
        controllerAs: '$ctrl'
    })
    .name;

export const availableDataBundles = [
    {
        network: 'pd-cellc',
        value: 6,
        description: '250MB-R6',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-cellc',
        value: 19,
        description: '100MB-R19',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 49,
        description: '350MB-R49',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 85,
        description: '1+1GB-R85',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 99,
        description: '1+.5+1.5GB-R99',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 149,
        description: '2+2GB-R149',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 199,
        description: '3+3GB-R199',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 249,
        description: '4+4GB-R249',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 299,
        description: '6+6GB-R299',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 799,
        description: '20GB-R799',
        descriptionLong: '365-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 899,
        description: '30GB-R899',
        descriptionLong: '365-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 1099,
        description: '50GB-R1099',
        descriptionLong: '365-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 1599,
        description: '100GB-R1599',
        descriptionLong: '365-day Data Bundle'
    },
    {
        network: 'pd-cellc',
        value: 1999,
        description: '200GB-R1999',
        descriptionLong: '365-day Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 7,
        description: '35+35MB-R7-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 10,
        description: '100MB-R10',
        descriptionLong: 'Weekend Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 14,
        description: '75+75MB-R14-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 15.15,
        description: '20MB-R15.15',
        descriptionLong: 'FreeMe Boost Weekly'
    },
    {
        network: 'pd-telkom',
        value: 19,
        description: '200MB-R19',
        descriptionLong: 'Weekend Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 29,
        description: '150+150MB-R29-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 49,
        description: '300+300MB-R49-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 59.5,
        description: '250MB-R59.50',
        descriptionLong: 'FreeMe Boost Weekly'
    },
    {
        network: 'pd-telkom',
        value: 69,
        description: '500+500MB-R69-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 79,
        description: '1+1GB-R79-2mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 89,
        description: '1.5+1.5GB-R89-2mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 139,
        description: '2+2GB-R139-3mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 199,
        description: '3+3GB-R199-3mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 299,
        description: '5+5GB-R299-3mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 469,
        description: '10+10GB-R469-3mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 699,
        description: '20GB-R699-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 1499,
        description: '50GB-R1499-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-telkom',
        value: 2499,
        description: '100GB-R2499-7mth',
        descriptionLong: 'Once Off Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 4,
        description: '5MB-R4',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 10,
        description: '40MB-R10',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 17,
        description: '120MB-R17',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-mtn',
        value: 20,
        description: '100MB-R20',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 29,
        description: '200MB-R29',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 35,
        description: '500MB-R35',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-mtn',
        value: 40,
        description: '350MB-R40',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-mtn',
        value: 49,
        description: '350MB-R49',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 55,
        description: '500MB-R55',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-mtn',
        value: 59,
        description: '1GB-R59',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-mtn',
        value: 69,
        description: '500MB-R69',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 70,
        description: '1GB-R70',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-mtn',
        value: 85,
        description: '1GB-85',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 99,
        description: '1.5GB-R99',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 109,
        description: '2GB-R109',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-mtn',
        value: 139,
        description: '5GB-R139',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-mtn',
        value: 149,
        description: '2+2GB-R149',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 159,
        description: '300MB-R159',
        descriptionLong: '6mth Pay-once Data'
    },
    {
        network: 'pd-mtn',
        value: 199,
        description: '3+3GB-R199',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 209,
        description: '10GB-R209',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-mtn',
        value: 299,
        description: '5+5GB-R299',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 349,
        description: '6+6GB-R349',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 419,
        description: '1GB-R419',
        descriptionLong: '6mth Pay-once Data'
    },
    {
        network: 'pd-mtn',
        value: 469,
        description: '10+10GB-R469',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 599,
        description: '20+20GB-R599',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 699,
        description: '30+30GB-R699',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 799,
        description: '50+50GB-R799',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-mtn',
        value: 999,
        description: '100+100GB-R999',
        descriptionLong: 'Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 2,
        description: '10min-R2(on net)',
        descriptionLong: 'Power Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 4,
        description: '50MB-R4',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-vodacom',
        value: 5,
        description: '20MB-R5',
        descriptionLong: 'Same Day Data'
    },
    {
        network: 'pd-vodacom',
        value: 7,
        description: '100MB-R7',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-vodacom',
        value: 9,
        description: '60MB-R9',
        descriptionLong: 'Same Day Data'
    },
    {
        network: 'pd-vodacom',
        value: 12,
        description: '50MB-R12',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 14,
        description: '250MB-R14',
        descriptionLong: 'Night Data'
    },
    {
        network: 'pd-vodacom',
        value: 15,
        description: '100MB-R15',
        descriptionLong: 'Same Day Data'
    },
    {
        network: 'pd-vodacom',
        value: 17,
        description: '100MB-R17',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-vodacom',
        value: 29,
        description: '200MB-R29',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 49,
        description: '350MB-R49',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 69,
        description: '500MB-R69',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 85,
        description: '1GB-R85',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 99,
        description: '2GB-R99',
        descriptionLong: 'Weekly Data'
    },
    {
        network: 'pd-vodacom',
        value: 149,
        description: '2GB-R149',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 249,
        description: '4GB-R249',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 349,
        description: '6GB-R349',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 469,
        description: '10GB-R469',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 529,
        description: '15GB-R529',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 699,
        description: '30GB-R699',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 799,
        description: '50GB-R799',
        descriptionLong: '30-day Data Bundle'
    },
    {
        network: 'pd-vodacom',
        value: 999,
        description: '100GB-R999',
        descriptionLong: '30-day Data Bundle'
    }
];

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import routes from './events.routes';
import Restangular from 'restangular';
import socket from '../../components/socket/socket.service';
import uiSelect from 'ui-select';
import uiGrid from 'angular-ui-grid';
import ngSanitize from 'angular-sanitize';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
// import ngTable from 'ng-table';

export default angular.module('insideInfoApp.events', [uiRouter, uiBootstrap, Restangular, 'btford.socket-io',
    uiSelect, 'ngSanitize', 'ngTable', 'ui.grid'])
    .config(routes)
    .name;

require('./events.list.component');
require('./classify-event.modal.component');
